import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth.module';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    projectType: process.env.PROJECT_TYPE || 'web',
    packageVersion: process.env.PACKAGE_VERSION || '0',
    carregandoControleWinfas: null,
    tipoPeriodo: 1,
    data: null,
    turno: null,
    turnos: null,
    ano: null,
    mes: null,
    semana: null,
    semanas: null,
    anos: null,
    is_expanded: false,
    fechamento: 1,
    lang: "pt-BR",
    visualizacao: 1
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion
    },
    projectType: (state) => {
      return state.projectType
    }
  },
  mutations: {
    setSemana(state, semana) {
      state.semana = semana;
    },
    setAno(state, ano) {
      state.ano = ano;
    },
    setSemanas(state, semanas) {
      state.semanas = semanas;
    },
    setExpanded(state, is_expanded) {
      state.is_expanded = is_expanded;
    },
    setFechamento(state, fechamento) {
      state.fechamento = fechamento;
    },
    setLang(state, lang) {
      state.lang = lang;
    },
    setCarregandoControleWinfas(state, carregandoControleWinfas) {
      state.carregandoControleWinfas = carregandoControleWinfas;
    },
    setData(state, data) {
      state.data = data;
    },
    setTurno(state, turno) {
      state.turno = turno;
    },
    setTurnos(state, turnos) {
      state.turnos = turnos;
    },
    setMes(state, mes) {
      state.mes = mes;
    },
    setAnos(state, anos) {
      state.anos = anos;
    },
    setTipoPeriodo(state, tipoPeriodo) {
      state.tipoPeriodo = tipoPeriodo;
    },
    setVisualizacao(state, visualizacao) {
      state.visualizacao = visualizacao;
    },
  },
  actions: {

  },
  modules: {
    auth
  }
})
