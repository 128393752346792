<template>
  <b-card v-if="fechamento" no-body>
    <table width="100%" class="comBordaComplexa" height="100%">
      <tr>
        <td>TOTAL</td>
        <td>{{fechamento.cg_amarelo + fechamento.cg_verde + fechamento.cg_vermelho | digitos(3)}}</td>
        <td>PONTOS</td>        
      </tr>
      <tr>
        <td style="background-color: #cce7ff">CG</td>
        <td style="background-color: #cce7ff">
          {{
            (fechamento.cg_verde + fechamento.cg_amarelo) | digitos(3)
          }}
        </td>
        <td style="background-color: #cce7ff">
          {{
            (fechamento.pc_cg_verde + fechamento.pc_cg_amarelo)
              | duasCasas
          }}%
        </td>
       
      </tr>
      <tr>
        <td style="background-color: #00FF05">CG &le; 75%</td>
        <td style="background-color: #00FF05">
          {{ fechamento.cg_verde | digitos(3) }}
        </td>
        <td style="background-color: #00FF05">
          {{ fechamento.pc_cg_verde | duasCasas }}%
        </td>
        
      </tr>
      <tr>
        <td style="background-color: yellow">75% &lt; CG &le; 100%</td>
        <td style="background-color: yellow">
          {{ fechamento.cg_amarelo | digitos(3) }}
        </td>
        <td style="background-color: yellow">
          {{ fechamento.pc_cg_amarelo | duasCasas }}%
        </td>
        
      </tr>
      <tr>
        <td style="background-color: red; color: white">CG > 100%</td>
        <td style="background-color: red; color: white">
          {{ fechamento.cg_vermelho | digitos(3) }}
        </td>
        <td style="background-color: red; color: white">
          {{ fechamento.pc_cg_vermelho | duasCasas }}%
        </td>
       
      </tr>
    </table>
  </b-card>
</template>

<script>
export default {
  name: "BoxIndicadores",
  props: {
    fechamento: null,
    descricao:'',
    desenho:''
  },
};
</script>

<style scoped>
table.comBordaComplexa {
  text-align: center;
  font-weight: bold;
  border-collapse: collapse; /* CSS2 */
  background: #fffff0;
  font-size: 12px;
  padding: 0px;;
  height: 120px;
}

table.comBordaComplexa td {
  border: 2px solid white;
}

table.comBordaComplexa th {
  border: 1px solid blue;
  background: #f0fff0;
}

.subtitle {
  font-weight: 500;
  color: gray;
}
</style>