<template>
  <b-modal id="modal-controle" size="xl" :title=" $t('title.automarb-controles') ">
    <template #modal-header>
      <!-- Emulate built in modal header close button action -->
      <h5>{{ $t('title.automarb-controles') }}</h5>
      <p class="h4 btn">
        <b-icon icon="x" @click="close()"></b-icon>
      </p>
    </template>
    <b-form v-if="show">
      <b-row>
        <b-col>
          <b-card
            bg-variant="light"
            :header=" $t('header.controles') "
            id="controles-da-semana"
          >
            <b-row align-h="between">
              <b-row class="ml-3">
                <b-button size="sm" @click="selectAllRows" class="mr-1"
                  >{{ $t('botoes.selecionar-todos') }}</b-button
                >
                <b-button size="sm" @click="clearSelected"
                  >{{ $t('botoes.limpar-selecao') }}</b-button
                >
              </b-row>

              <b-button
                size="sm"
                @click="adicinarControles"
                :disabled="selected.length == 0"
                class="mr-3"
                >{{ $t('botoes.adicionar-selecionados') }} <b-icon icon="chevron-right"></b-icon>
              </b-button>
            </b-row>
            <b-table
              :items="availableControles"
              :fields="fields"
              select-mode="multi"
              responsive="sm"
              ref="selectableTable"
              selectable
              bordered
              hover
              small
              center
              :current-page="currentPage"
              :per-page="perPage"
              class="table-controles text-center mt-2"
              head-variant="light"
              @row-selected="onRowSelected"
            >
              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>

              <template v-slot:cell(excluir)="row">
                <b-icon
                  icon="trash-fill"
                  variant="danger"
                  @click="excluir(row.item.cod)"
                ></b-icon>
              </template>
            </b-table>

            <b-row>
              <b-col sm="5" md="6" class="my-1">
                <b-form-group>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col sm="7" md="6" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col>
          <b-card
            bg-variant="light"
            :header="$t('header.controles-selecionados-para-semana') +' ' + $store.state.semana"
            id="controles-da-semana"
          >
            <b-row align-h="between">
              <b-button
                size="sm"
                @click="removerControles"
                :disabled="selected_selecionados.length == 0"
                class="ml-3"
              >
                <b-icon icon="chevron-left"></b-icon> {{ $t('botoes.remover-selecionados') }}
              </b-button>
              <b-row class="mr-3">
                <b-button
                  size="sm"
                  @click="selectAllRowsSelecionados"
                  class="mr-1"
                  >{{ $t('botoes.selecionar-todos') }}</b-button
                >
                <b-button size="sm" @click="clearSelectedSelecionados"
                  >{{ $t('botoes.limpar-selecao') }}</b-button
                >
              </b-row>
            </b-row>
            <b-table
              :items="controles_selecionados"
              :fields="fields_selecionados"
              select-mode="multi"
              responsive="sm"
              ref="selectableTableSelecionados"
              selectable
              bordered
              hover
              small
              center
              :current-page="currentPageSelecionados"
              :per-page="perPage"
              class="table-controles text-center mt-2"
              head-variant="light"
              @row-selected="onRowSelectedSelecionados"
            >
              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>

              <template v-slot:cell(ativo)="row">
                <b-row align-h="center">
                  <b-icon
                    icon="check-circle-fill"
                    variant="primary"
                    v-if="row.item.ativo"
                    @click="mudarStatus(row.item.cod, row.item.ativo)"
                  ></b-icon>
                  <b-icon
                    icon="x-circle"
                    variant="default"
                    v-else
                    @click="mudarStatus(row.item.cod, row.item.ativo)"
                  >
                  </b-icon>
                </b-row>
              </template>
            </b-table>

            <b-row>
              <b-col sm="5" md="6" class="my-1">
                <b-form-group>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col sm="7" md="6" class="my-1">
                <b-pagination
                  v-model="currentPageSelecionados"
                  :total-rows="controles_selecionados.length"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>

    <b-alert show variant="danger" v-if="menssagem" class="mt-2">
      {{ menssagem }}
    </b-alert>

    <template #modal-footer>
      <div class="w-100">
        <b-row align-v="center">
          <b-col cols="12" md="8">
            <b-button
              type="button"
              variant="success"
              class="float-left"
              @click="showModalConverterControle()"
            >
              {{ $t('botoes.carregar-controles') }} </b-button>
          </b-col>
          <b-col cols="6" md="4">
            <b-button
              variant="primary"
              class="float-right"
              v-if="carregando"
              disabled
            >
              <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
              {{ $t('botoes.carregando') }} </b-button>

            <b-button
              v-else
              type="submit"
              variant="primary"
              class="float-right"
              @click="salvar()"
            >
              {{ $t('botoes.salvar') }} </b-button>

            <b-button
              type="submit"
              variant="secondary"
              class="float-right mr-2"
              @click="close()"
            >
              {{ $t('botoes.cancelar') }} </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
    <modal-converter-controle
      :relatorio="relatorio"
      :conversor_relatorio="conversor_relatorio"
      @listaMudou="carregarControles()"
    />
  </b-modal>
</template>

<script>
import ModalConverterControle from "@/components/modal/ConverterControle.vue";
export default {
  components: { ModalConverterControle },
  props: {
    relatorio: null,
    conversor_relatorio: null
  },
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      currentPageSelecionados: 1,
      perPage: 15,
      pageOptions: [
        { value: 5, text: this.$t('paginacao.5-itens-por-pagina') },
        { value: 10, text: this.$t('paginacao.10-itens-por-pagina') },
        { value: 15, text: this.$t('paginacao.15-itens-por-pagina') },
        { value: 100, text: this.$t('paginacao.100-itens-por-pagina') },
      ],
      show: true,
      menssagem: null,
      carregando: null,
      form: {
        controle: null,
        conversor: 1,
      },
      optionsElemento: [],
      elemento_selecionado: null,
      controles: [],
      controles_selecionados: [],
      output: null,
      fields: ["selected", "cod", "data", "excluir"],
      fields_selecionados: ["selected", "cod", "data", "ativo"],
      selected: [],
      selected_selecionados: [],
      optionsConversores: [
        { text: "PC-Dmis", value: 1 },
        { text: "PolyWorks", value: 5, disabled: false },
        { text: "Metrolog", value: 2 },
        { text: "GeoPak", value: 3, disabled: true },
        { text: "CTR", value: 4, disabled: true },        
      ],
    };
  },
  computed: {
    filter() {
      return (
        "?tipoPeriodo=" +
        this.$store.state.tipoPeriodo +
        "&ano=" +
        this.$store.state.ano +
        "&mes=" +
        this.$store.state.mes +
        "&semana=" +
        this.$store.state.semana +
        "&data=" +
        this.$store.state.data +
        "&turno=" +
        this.$store.state.turno 
      );
    },
    availableControles() {
      return this.controles.filter((controle) => {
        return !this.controles_selecionados.some((controle_selecionado) => {
          return controle.cod === controle_selecionado.cod;
        });
      });
    },
    ano: {
      get() {
        return this.$store.state.ano;
      }
    },
    semana: {
      get() {
        return this.$store.state.semana;
      }
    },
  },
  methods: {
    adicinarControles() {
      this.selected.forEach((controle) => {
        this.controles_selecionados.push(controle);
      });
    },
    removerControles() {
      this.selected_selecionados.forEach((controle) => {
        this.controles_selecionados = this.controles_selecionados.filter(
          (controle_selecionado) => controle_selecionado.cod != controle.cod
        );
      });
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    showModalConverterControle() {
      this.$bvModal.show("modal-converter");
    },

    onRowSelectedSelecionados(items) {
      this.selected_selecionados = items;
    },
    selectAllRowsSelecionados() {
      this.$refs.selectableTableSelecionados.selectAllRows();
    },
    clearSelectedSelecionados() {
      this.$refs.selectableTableSelecionados.clearSelected();
    },

    excluir(item) {
      let confirmacao = confirm(
        this.$t('confirmacao.tem-certeza-que-deseja-remover-esse-familia-com-todos-seus-componentes')
      );

      if (!confirmacao) {
        return false;
      }

      let formData = new FormData();
      formData.append("_method", "delete");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post("api/controle/" + item, formData, config)
        .then((response) => {
          this.makeToast("success");
          this.carregarControles();
          this.carregarControlesSelecionados();
          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    mudarStatus(item, status) {
      var novo_status = !status;

      this.menssagem = null;
      this.carregando = true;

      let formData = new FormData();
      formData.append("ativo", novo_status ? 1 : 0);
      formData.append("_method", "patch");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post("api/controle/" + item, formData, config)
        .then((response) => {
          this.makeToast("success");
          this.carregarControles();
          this.carregarControlesSelecionados();
          this.carregando = false;
          this.$emit("controlesMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
        title: this.$t('toast.sucesso'),
        variant: variant,
        solid: true,
      });
    },
    makeToastErro(variant = null) {
      this.$bvToast.toast(this.$t('toast.nenhum-controle-selecionado'), {
        title: this.$t('title.erro'),
        variant: variant,
        solid: true,
      });
    },
    close() {
      this.$bvModal.hide("modal-controle");
    },
    salvar() {
      this.menssagem = null;
      this.carregando = true;

      let formData = new FormData();

      if (this.controles_selecionados) {
        this.controles_selecionados.forEach((element) => {
          formData.append("controle[]", element.cod);
        });

        let config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        };

        this.$http
          .post(
            "api/relatorio/controles-semana-relatorio/" +
              this.relatorio +
              "/" +
              this.$store.state.ano +
              "/" +
              this.$store.state.semana,
            formData,
            config
          )
          .then((response) => {
            this.makeToast("success");
            this.carregarControles();
            this.carregarControlesSelecionados();
            this.carregando = false;
            this.$emit("controlesMudou");
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.erro;
            this.carregando = false;
          });
      } else {
        this.makeToastErro("danger");
        this.carregando = false;
      }
    },
    deletar() {},
    carregarControles() {
      this.carregando = true;
      this.menssagem = null;
      this.controles = [];

      this.$http
        .get(
          "api/relatorio/controles/" +
          this.relatorio + this.filter
        )
        .then((response) => {
          this.controles_bd = response.data;

          this.controles = [];

          this.controles_bd.forEach((controle) => {
            const d = new Date(controle.data).toLocaleDateString("pt-br");
            const h = new Date(controle.data).toLocaleTimeString("pt-br");
            this.controles.push({
              ativo: controle.ativo ? true : false,
              cod: controle.id,
              data: d + " " + h,
            });
          });

          this.totalRows = this.controles.length;

          this.carregando = false;
          //this.$emit("controlesMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response;
          this.carregando = false;
        });
    },
    carregarControlesSelecionados() {
      this.carregando = true;
      this.menssagem = null;
      this.controles_selecionados = [];

      this.$http
        .get(
          "api/relatorio/controles-semana-relatorio/" +
            this.relatorio +
            "/" +
            this.$store.state.ano +
            "/" +
            this.$store.state.semana
        )
        .then((response) => {
          this.controles_bd = response.data;

          this.controles_selecionados = [];

          this.controles_bd.forEach((controle) => {
            const d = new Date(controle.data).toLocaleDateString("pt-br");
            const h = new Date(controle.data).toLocaleTimeString("pt-br");
            this.controles_selecionados.push({
              ativo: controle.ativo ? true : false,
              cod: controle.id,
              data: d + " " + h,
            });
          });
          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response;
          this.carregando = false;
        });
    },
  },
  mounted() {
    this.carregarControles();
    this.carregarControlesSelecionados();
  },
  watch: {
    relatorio: function () {
      this.carregarControles();
      this.carregarControlesSelecionados();
    },
    ano: function () {
      this.carregarControles();
      this.carregarControlesSelecionados();
    },
    semana: function () {
      this.carregarControles();
      this.carregarControlesSelecionados();
    },
  },
};
</script>

<style scoped>
.table-controles {
  background-color: white;
}

.table-controles tr td {
  padding: 0 !important;
  margin: 0 !important;
}

.form-control {
  border-radius: 0px !important;
}

.controles-da-semana {
  height: 100vh;
}
</style>