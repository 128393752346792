import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin, ModalPlugin } from 'bootstrap-vue'
import './plugins/axios'
import Multiselect from 'vue-multiselect'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import VueKonva from 'vue-konva';
import VueApexCharts from 'vue-apexcharts'
import i18n from './i18n'

var ip_axios = process.env.VUE_APP_ROOT_API
var baseUrl = window.location.origin + process.env.VUE_APP_ROOT

Vue.use(Multiselect)
Vue.use(VueApexCharts)
Vue.use(VueKonva);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ModalPlugin)

Vue.component('apexchart', VueApexCharts)
Vue.component('vue-multiselect', Multiselect)

Vue.filter(
  'dataCompleta', function (value) {
    if (!value) return "";
    var data = new Date(value).toLocaleDateString("pt-br");    
    return data;
  }
)

Vue.filter(
  'data', function (value) {
    if (!value) return "";
    var data = new Date(value).toLocaleDateString("pt-br");
    var dm = data.slice(0, -5);
    return dm;
  }
)

Vue.filter(
  'newImagem', function (value) {

    const auth = store.state.auth.user;
    
    if (!value) return "";
    value =ip_axios+"/organization/" + auth.user.tenant_id + "/api/storage/" + value.toString();
    var image =  new Image()
    image.src = value    
    return image.src;
  }
)

Vue.filter(
  'imgBase', function (value) {
    if (!value) return ""; 
    value = baseUrl+"assets/" + value.toString();    
    return value;
  }
)


Vue.filter(
  'urlImagem', function (value) {

    const auth = store.state.auth.user;    

    if (!value) return "";
    value = ip_axios+"/organization/" + auth.user.tenant_id + "/api/storage/" + value.toString();
    return value;
  }
)

Vue.filter(
  'duasCasas', function (value) {
    if (!value) return "0.00";

    if (!isNaN(value)) {
      value = value.toFixed(2);
    }
    return value;
  }
)

Vue.filter(
  'umaCasa', function (value) {
    if (!value) return "0.0";

    if (!isNaN(value)) {
      value = value.toFixed(1);
    }
    return value;
  }
)

Vue.filter(
  'casas', function (value, casas) {
    if (!value) return "0.0";

    if (!isNaN(value)) {
      value = value.toFixed(casas);
    }
    return value;
  }
)

Vue.filter(
  'digitos', function (value, padding) {
    if (!value) return "000";
    var zeroes = new Array(padding + 1).join("0");
    return (zeroes + value).slice(-padding);
  }
)

Vue.filter(
  'calculo', function (value) {
    var estilo ="";
    if (!value) return "";    

    if (value == 1) {
      estilo = "background-color:black; color:white";
    }
    return estilo;
  }
)

Vue.filter(
  'moeda', function (value) {    
    if (!value) return "";    
    return 'R$' + value / 100;
  }
)

import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted() {
    AOS.init()
  },
}).$mount('#app')
