var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relatorio-boleado"},[(_vm.menssagem)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('span',[_c('b-icon',{attrs:{"icon":"exclamation-octagon","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.menssagem))],1)]):_c('div',_vm._l((_vm.resultado.paginas),function(pagina,index){return _c('div',{directives:[{name:"b-scrollspy",rawName:"v-b-scrollspy"}],key:pagina.id,ref:'pagina_boleado' + index,refInFor:true,staticClass:"mb-3 pagina",on:{"&scroll":function($event){return _vm.scrollPagina.apply(null, arguments)}}},[(index == 0)?_c('b-row',{staticClass:"relatorio-cabecalho mb-2"},[_c('b-col',{attrs:{"cols":"2"}},[(_vm.$store.state.auth.user.user.organizacao.imagem)?_c('b-row',{staticClass:"mt-3 ml-1",attrs:{"align-v":"center","align-h":"center"}},[_c('img',{attrs:{"src":_vm._f("urlImagem")(_vm.$store.state.auth.user.user.organizacao.imagem),"width":"150"}})]):_vm._e(),_c('b-row',{staticClass:"mt-3 ml-1",attrs:{"align-v":"center","align-h":"center"}},[_c('img',{attrs:{"src":'../assets/logo.png',"width":"150"}})])],1),_c('b-col',{attrs:{"cols":"6"}},[(_vm.componente)?_c('box-indicadores',{attrs:{"fechamento":_vm.resultado.tabela.fechamento,"descricao":_vm.componente.descricao,"desenho":_vm.componente.desenho,"indicador":_vm.indicador}}):_vm._e()],1),_c('b-col',{staticClass:"p-0",attrs:{"cols":"2"}},[(_vm.resultado.plano)?_c('root-cause-bar',{ref:"rootcausebar",refInFor:true,attrs:{"analysis":_vm.resultado.plano.fechamento_analysis}}):_vm._e()],1),_c('b-col',{staticClass:"mt-0",attrs:{"cols":"2"}},[_c('b-img',{attrs:{"width":"120","src":_vm._f("urlImagem")(_vm.componente.familia.imagem)}})],1)],1):_vm._e(),_c('b-overlay',{attrs:{"show":_vm.carregando,"rounded":"sm","opacity":"1"}},[_c('div',{ref:'pagina' + pagina.id,refInFor:true,class:index == 0 ? 'pagina1 border' : 'pagina border',style:({
            'background-image':
              'url(' + _vm.url + '/organization/' + _vm.user.tenant_id + '/api/storage/' + pagina.imagem + ')',
          }),attrs:{"id":'paginaboleado' + pagina.id}},[_c('v-stage',{ref:'stage' + pagina.id,refInFor:true,staticClass:"kanva",attrs:{"config":index == 0 ? _vm.stage1Size : _vm.stageSize}},[_c('v-layer',_vm._l((_vm.avaliableLinhas(pagina.id)),function(line){return _c('v-line',{key:'boleado'+line.id,attrs:{"config":{
                stroke: 'black',
                strokeWidth: 1,
                points: line.points,
              }}})}),1)],1),_vm._l((pagina.elementos),function(elemento){return _c('elemento-componente',{key:'caixa' + elemento.id,style:('left:' +
              elemento.posicaocaixal +
              'px; top:' +
              elemento.posicaocaixat +
              'px;'),attrs:{"elemento":elemento,"plano":_vm.resultado.plano.plano,"tipo":"boleado"}})}),_vm._l((pagina.elementos),function(ponto){return _c('ponto-componente',{key:'ponto' + ponto.id,style:('left:' +
              ponto.posicaopontol +
              'px; top:' +
              ponto.posicaopontot +
              'px;'),attrs:{"ponto":ponto,"tipo":"boleado"}})})],2)]),_c('b-row',{staticClass:"align-self-end paginacao",attrs:{"align-v":"end"}},[_c('b-col',{staticClass:"text-center"},[_vm._v(_vm._s(index + 1)+"/"+_vm._s(_vm.resultado.paginas.length))])],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }