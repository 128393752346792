<template>
  <div
    class="elemento"
    ref="draggableContainer"
    :id="'caixa' + tipo + elemento.id"
  >
    <div class="draggable" :ref="'draggable' + elemento.id">
      <div
        @mousedown="dragMouseDown"
        @dblclick="duploClique"
        @click.ctrl="ctrlClick"
      >
        <criacao v-if="tipo == 'criacao'" :elemento="elemento" :indicador="indicador" />
        <estatistica v-if="tipo == 'cpk'" :elemento="elemento" :indicador="indicador" />
        <individual v-if="tipo == 'individual'" :elemento="elemento" />
        <boleado v-if="tipo == 'boleado'" :elemento="elemento" :plano="plano" />
      </div>
    </div>
  </div>
</template>

<script>
import Estatistica from "@/components/Estatistica.vue";
import Individual from "@/components/Individual.vue";
import Boleado from "@/components/Boleado.vue";
import Criacao from "@/components/Criacao.vue";
export default {
  components: {
    Estatistica,
    Individual,
    Boleado,
    Criacao,
  },
  props: {
    elemento: {
      type: Object,
    },
    tipo: "",
    plano: null,
    indicador: null
  },
  name: "DraggableDiv",
  data: function () {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
    };
  },
  methods: {
    duploClique() {
      this.$emit("dubloclique", this.elemento.id);
    },
    ctrlClick() {
      this.$emit("ctrlClick", {
        elemento: this.elemento.id,
        connection: this.elemento.connection,
        pagina: this.elemento.pagina_id,
      });
    },
    dragMouseDown: function (event) {
      event.preventDefault();
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function (event) {
      event.preventDefault();
      this.$emit("moveu", this.elemento.id);
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
      this.$emit("paroudemover", {
        ponto: this.elemento.id,
        pagina: this.elemento.pagina_id,
      });
    },
  },
};
</script>

<style scoped>
.elemento {
  position: absolute;
  border-color: blueviolet;
  float: left;
}

.draggable table {
  background-color: white;
}

#draggable-container {
  z-index: 9;
}
#draggable-header {
  z-index: 10;  
}
</style>