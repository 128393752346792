<template>
  <b-modal
    id="modal-converter"
    size="lg"
    :title="$t('title.automarb-controles')"
  >
    <template #modal-header>
      <h5>{{ $t("title.automarb-controles") }}</h5>
      <p class="h4 btn">
        <b-icon icon="x" @click="close()"></b-icon>
      </p>
    </template>
    <b-form v-if="show">
      <b-card
        bg-variant="light"
        :header="$t('header.carregar-controle-dimensional')"
      >
        <b-form-group
          v-if="
            (form.conversor != 6 && form.conversor != 8) ||
            form.importacaoWinfas == 2
          "
          id="input-group-2"
          :label="$t('label.controle-dimensional')"
          label-for="input-2"
        >
          <b-form-file
            v-model="form.controle"
            multiple
            :placeholder="$t('placehoader.escolha-o-controle-ou-solte-o-aqui')"
            :drop-placeholder="$t('drop.placehoader.solte-o-controle-aqui')"
          ></b-form-file>
        </b-form-group>

        <b-form-group
          :label="$t('label.tipo-de-arquivo')"
          v-show="!conversor_relatorio"
        >
          <b-form-radio-group
            size="sm"
            v-model="form.conversor"
            :options="optionsConversores"
            name="radio-options-conversor"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group
          :label="$t('label.tipo-de-importacao')"
          v-if="form.conversor == 5"
        >
          <b-form-radio-group
            size="sm"
            v-model="form.importacao"
            :options="optionsImportacao"
            name="radio-options-importacao"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group
          :label="$t('label.tipo-de-importacao')"
          v-if="form.conversor == 6 || form.conversor == 8"
        >
          <b-form-radio-group
            size="sm"
            v-model="form.importacaoWinfas"
            :options="optionsImportacaoWinfas"
            name="radio-options-importacao"
          ></b-form-radio-group>
        </b-form-group>

        <b-button
          variant="primary"
          class="float-left"
          v-if="carregando"
          disabled
        >
          <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
          {{ $t("botoes.carregando") }}
        </b-button>

        <b-button
          v-else
          type="button"
          variant="success"
          class="float-left"
          @click="salvar()"
        >
          {{
            form.conversor == 6 || form.conversor == 8
              ? $t("botoes.atualizar-controles")
              : $t("botoes.carregar-controles")
          }}
        </b-button>
      </b-card>
    </b-form>

    <b-alert show variant="danger" v-if="menssagem" class="mt-2">
      {{ menssagem }}
    </b-alert>

    <template #modal-footer>
      <b-button
        type="button"
        variant="secondary"
        class="float-right mr-2"
        @click="close()"
      >
        {{ $t("botoes.fechar") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "ConverterControle",
  props: {
    relatorio: null,
    conversor_relatorio: null,
  },
  data() {
    return {
      show: true,
      menssagem: null,
      carregando: null,
      form: {
        controle: null,
        conversor: this.conversor_relatorio,
        importacao: 1,
        importacaoWinfas: 1,
      },
      optionsConversores: [
        { text: "Winfas (Access)", value: 6 },
        { text: "Winfas (SQL Server)", value: 8 },
        { text: "Marposs", value: 7 },
        { text: "PC-Dmis", value: 1 },
        { text: "PolyWorks", value: 5, disabled: false },
        { text: "Metrolog", value: 2 },
      ],
      optionsImportacao: [
        { text: this.$t("opcoes.importacao-unico"), value: 1 },
        { text: this.$t("opcoes.impotacao-mesclar"), value: 2 },
      ],
      optionsImportacaoWinfas: [
        { text: this.$t("opcoes.importacao-automatica"), value: 1 },
        { text: this.$t("opcoes.impotacao-enviar-arquivo"), value: 2 },
      ],
    };
  },
  methods: {
    close() {
      this.$bvModal.hide("modal-converter");
    },
    makeToast(
      variant = null,
      message = this.$t("toast.operacao-realizada-com-sucesso")
    ) {
      this.$bvToast.toast(message, {
        title: this.$t("toast.sucesso"),
        variant: variant,
        solid: true,
      });
    },
    makeToastErro(variant = null) {
      this.$bvToast.toast(this.$t("toast.nenhum-controle-selecionado"), {
        title: this.$t("title.erro"),
        variant: variant,
        solid: true,
      });
    },
    salvar() {
      this.menssagem = null;
      this.carregando = true;

      let formData = new FormData();
      formData.append("relatorio_id", this.relatorio);
      formData.append("conversor", this.form.conversor);
      formData.append("importacao", this.form.importacao);

      if (
        !this.form.controle &&
        this.form.conversor != 6 &&
        this.form.conversor != 8
      ) {
        this.makeToastErro("danger");
        this.carregando = false;
      }

      if (this.form.controle) {
        this.form.controle.forEach((element) => {
          formData.append("controle[]", element);
        });
      }

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post("api/controle", formData, config)
        .then((response) => {
          this.makeToast("success", response.data.msg);
          this.$bvModal.hide("modal-converter");
          this.carregando = false;
          this.form.controle = null;
          this.$emit("listaMudou");
          this.$store.state.carregandoControleWinfas = response.data.progress;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.erro;
          this.carregando = false;
        });
    },
  },
};
</script>

<style></style>
