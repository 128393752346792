var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home pt-3"},[_c('b-breadcrumb',[_c('b-breadcrumb-item',{staticClass:"align-middle",attrs:{"active":""}},[_c('b-icon',{attrs:{"icon":"house-fill","scale":"1.25","shift-v":"1.25","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.$t("breadcrumb.familias"))+" ")],1),(_vm.user.permissao == 'acesso_total' || _vm.user.permissao == 'relatorio')?_c('b-button',{staticClass:"ml-auto",attrs:{"variant":"secondary","size":"sm"},on:{"click":function($event){return _vm.showModal()}}},[_c('b-icon-plus'),_vm._v(_vm._s(_vm.$t("botoes.novaFamilia"))+" ")],1):_vm._e()],1),_c('SelecaoPeriodo',{attrs:{"mostrarTurno":true,"mostrarVisualizacao":false}}),_c('b-container',{staticClass:"mt-3",attrs:{"fluid":""}},[(!_vm.carregando)?_c('b-row',{attrs:{"align-h":"start"}},_vm._l((_vm.familias),function(familia){return _c('div',{key:familia.id,staticClass:"p-3"},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":'/familia/' + familia.id}},[_c('b-card',{staticClass:"text-center px-3 pb-3",attrs:{"no-body":""},on:{"mouseover":function($event){_vm.isHovering = true},"mouseout":function($event){_vm.isHovering = false}}},[_c('b-row',{staticClass:"pv-0",attrs:{"align-h":"end"}},[(
                  _vm.user.permissao == 'acesso_total' ||
                  _vm.user.permissao == 'relatorio'
                )?_c('b-dropdown',{staticClass:"pv-0",attrs:{"size":"sm","variant":"link","no-caret":"","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon',{attrs:{"icon":"three-dots"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.showModalEditarFamilia(familia)}}},[_vm._v(_vm._s(_vm.$t("botoes.editar")))])],1):_vm._e()],1),_c('b-card-text',{staticClass:"title-card mt-2"},[_vm._v(" "+_vm._s(familia.descricao)+" ")]),_c('div',{staticClass:"lab__card",style:('background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)),url(' +
                _vm.url +
                '/' +
                familia.imagem +
                ') no-repeat center center /100%')}),(familia.id)?_c('container-analytics',{attrs:{"familia_id":familia.id}}):_vm._e()],1)],1)],1)}),0):_vm._e()],1),_c('modal-familia',{attrs:{"familia":_vm.familia},on:{"listaMudou":function($event){return _vm.carregarLista()}}}),_c('modal-carregando-geral')],1)}
var staticRenderFns = []

export { render, staticRenderFns }