<template>
  <div class="apresentacao-familia pt-3">
    <div class="mt-3">
      <b-card no-body>

        <div>
          <div class="pagina-padrao">
            <Conformidade v-if="resultado" ref="fechamentoConformidade" calculo="CG" height="600"
              :semanas="resultado.geral.cg_semanas" :verde="resultado.geral.f_geral_cg_verde"
              :amarelo="resultado.geral.f_geral_cg_amarelo" :vermelho="resultado.geral.f_geral_cg_vermelho" />            
          </div>
        </div>
        <div>
          <div class="pagina-padrao" ref="fechamentoConformidadePorPeca">
            <Conformidade v-if="resultado" calculo="CG" height="470" :semanas="resultado.peca_reverse.f_geral_item_cg"
              :verde="resultado.peca_reverse.f_geral_cg_verde" :amarelo="resultado.peca_reverse.f_geral_cg_amarelo"
              :vermelho="resultado.peca_reverse.f_geral_cg_vermelho" />
            <top-five v-if="resultado" :desenho="resultado.peca.f_geral_item_desenho_cg" :imagem="resultado.peca.f_geral_imagem_cg"
              :verde="resultado.peca.f_geral_cg_verde" :amarelo="resultado.peca.f_geral_cg_amarelo"
              :vermelho="resultado.peca.f_geral_cg_vermelho" />
          </div>
        </div>
        <div>
          <div class="pagina-padrao">
            <Conformidade v-if="resultado" ref="fechamentoCp" :semanas="resultado.geral.cp_semanas"
              :verde="resultado.geral.f_geral_cp_verde" :amarelo="resultado.geral.f_geral_cp_amarelo"
              :vermelho="resultado.geral.f_geral_cp_vermelho" :calculo="indicadorCp" height="600" />            
          </div>
        </div>
        <div>
          <div class="pagina-padrao" ref="fechamentoCpPorPeca">
            <Conformidade v-if="resultado" :semanas="resultado.peca_reverse.f_geral_item_cp"
              :verde="resultado.peca_reverse.f_geral_cp_verde" :amarelo="resultado.peca_reverse.f_geral_cp_amarelo"
              :vermelho="resultado.peca_reverse.f_geral_cp_vermelho" :calculo="indicadorCp" height="470" />
            <top-five v-if="resultado" :desenho="resultado.peca.f_geral_item_desenho_cp" :imagem="resultado.peca.f_geral_imagem_cp"
              :verde="resultado.peca.f_geral_cp_verde" :amarelo="resultado.peca.f_geral_cp_amarelo"
              :vermelho="resultado.peca.f_geral_cp_vermelho" />
          </div>
        </div>
        <div>
          <div class="pagina-padrao">
            <Conformidade v-if="resultado" ref="fechamentoCpk" :semanas="resultado.geral.cpk_semanas"
              :verde="resultado.geral.f_geral_cpk_verde" :amarelo="resultado.geral.f_geral_cpk_amarelo"
              :vermelho="resultado.geral.f_geral_cpk_vermelho" :calculo="indicadorCp + 'K'" height="600" />           
          </div>
        </div>
        <div>
          <div class="pagina-padrao" ref="fechamentoCpkPorPeca">
            <Conformidade v-if="resultado" :semanas="resultado.peca_reverse.f_geral_item_cpk"
              :verde="resultado.peca_reverse.f_geral_cpk_verde" :amarelo="resultado.peca_reverse.f_geral_cpk_amarelo"
              :vermelho="resultado.peca_reverse.f_geral_cpk_vermelho" :calculo="indicadorCp + 'K'" height="470" />
            <top-five v-if="resultado" :desenho="resultado.peca.f_geral_item_desenho_cpk" :imagem="resultado.peca.f_geral_imagem_cpk"
              :verde="resultado.peca.f_geral_cpk_verde" :amarelo="resultado.peca.f_geral_cpk_amarelo"
              :vermelho="resultado.peca.f_geral_cpk_vermelho" />
          </div>
        </div>


      </b-card>
    </div>
  </div>
</template>

<style scoped>
.pagina-padrao {
  width: 1035px;
  height: 652px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

table.comBordaComplexa {
  text-align: center;
  font-weight: bold;
  border-collapse: collapse;
  /* CSS2 */
  background: #fffff0;
}

table.comBordaComplexa td {
  border: 2px solid white;
}

table.comBordaComplexa th {
  border: 1px solid blue;
  background: #f0fff0;
}

.card-imagem {
  height: 150px;
}

.subtitle {
  font-weight: 500;
  color: gray;
}
</style>
<script>
import Componente from "@/components/Componentes.vue";
import Conformidade from "@/components/Conformidade.vue";
import TopFive from "@/components/TopFive.vue";

import * as htmlToImage from "html-to-image";

export default {
  components: {
    Componente,
    Conformidade,
    TopFive,
  },
  props: {
    ano: null,
    semana: null,
    fechamento: null,
  },
  computed: {
    indicadorCp() {
      return this.indicador == "cp" ? "CP" : "PP";
    },
  },
  data() {
    return {
      indicador: "cp",
      url: null,
      resultado: null,
      fechamento_geral: null,
      carregando: false,
      tabs: [
        { tab: 1, ref: "fechamentoConformidade" },
        { tab: 2, ref: "fechamentoConformidadePorPeca" },
        { tab: 3, ref: "fechamentoCp" },
        { tab: 4, ref: "fechamentoCpPorPeca" },
        { tab: 5, ref: "fechamentoCpk" },
        { tab: 6, ref: "fechamentoCpkPorPeca" },
      ],
      screenshots: {
        fechamentoConformidade: [],
        fechamentoConformidadePorPeca: [],
        fechamentoCp: [],
        fechamentoCpPorPeca: [],
        fechamentoCpk: [],
        fechamentoCpkPorPeca: [],
      },
      count: 0,
      quantidade_paginas_imprimir: 0,
    };
  },
  methods: {
    enviarDadosApresentacao() {
      this.capturing = false;
      this.count = 0;

      var dados = {
        item_id: this.fechamento_geral.familia_id,
        tipo: "fechamento-geral",
        imagens: this.screenshots,
      };

      this.$emit("gerou", dados);
    },
    gerarApresentacao() {
      this.onCapture();
    },
    capiturando() {
      if (this.count == this.quantidade_paginas_imprimir) {
        setTimeout(() => {
          this.enviarDadosApresentacao();
        }, 300);
      }
    },
    gerandoImagem() {
      this.$emit("gerouImage");
    },
    onCapture() {
      this.capturing = true;

      this.quantidade_paginas_imprimir = 0;
      this.count = 0;

      this.tabs.forEach((tab, i) => {
        if (tab.tab == 1) {
          if (this.fechamento_geral.imprimir.cg_geral == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 2) {
          if (this.fechamento_geral.imprimir.cg_pecas == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 3) {
          if (this.fechamento_geral.imprimir.cp_geral == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 4) {
          if (this.fechamento_geral.imprimir.cp_pecas == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 5) {
          if (this.fechamento_geral.imprimir.cpk_geral == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }

        if (tab.tab == 6) {
          if (this.fechamento_geral.imprimir.cpk_pecas == true) {
            this.quantidade_paginas_imprimir += 1;
          }
        }
      });

      this.screenshots = {
        fechamentoConformidade: [],
        fechamentoConformidadePorPeca: [],
        fechamentoCp: [],
        fechamentoCpPorPeca: [],
        fechamentoCpk: [],
        fechamentoCpkPorPeca: [],
      };

      setTimeout(() => {
        this.tabs.forEach((tab, i) => {
          if (tab.tab == 1) {
            if (this.fechamento_geral.imprimir.cg_geral == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$el;
                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.fechamentoConformidade.push(img);
                    this.count++;
                    this.capiturando();
                    this.gerandoImagem();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 600);
            } else {
              this.gerandoImagem();
            }
          }

          if (tab.tab == 2) {
            if (this.fechamento_geral.imprimir.cg_pecas == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref];
                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.fechamentoConformidadePorPeca.push(img);
                    this.count++;
                    this.capiturando();
                    this.gerandoImagem();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 600);
            } else {
              this.gerandoImagem();
            }
          }

          if (tab.tab == 3) {
            if (this.fechamento_geral.imprimir.cp_geral == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$el;
                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.fechamentoCp.push(img);
                    this.count++;
                    this.capiturando();
                    this.gerandoImagem();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 600);
            } else {
              this.gerandoImagem();
            }
          }

          if (tab.tab == 4) {
            if (this.fechamento_geral.imprimir.cp_pecas == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref];
                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.fechamentoCpPorPeca.push(img);
                    this.count++;
                    this.capiturando();
                    this.gerandoImagem();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 600);
            } else {
              this.gerandoImagem();
            }
          }

          if (tab.tab == 5) {
            if (this.fechamento_geral.imprimir.cpk_geral == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref].$el;
                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.fechamentoCpk.push(img);
                    this.count++;
                    this.capiturando();
                    this.gerandoImagem();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 600);
            } else {
              this.gerandoImagem();
            }
          }

          if (tab.tab == 6) {
            if (this.fechamento_geral.imprimir.cp_pecas == true) {
              setTimeout(() => {
                const capture = this.$refs[tab.ref];
                htmlToImage
                  .toPng(capture)
                  .then((dataUrl) => {
                    var img = new Image();
                    img.src = dataUrl;
                    this.screenshots.fechamentoCpkPorPeca.push(img);
                    this.count++;
                    this.capiturando();
                    this.gerandoImagem();
                  })
                  .catch((error) => {
                    console.error("oops, something went wrong!", error);
                  });
              }, 600);
            } else {
              this.gerandoImagem();
            }
          }
        });
      }, 600);
    },
    carregarFechamentoGeral(ano, semana, fechamento) {
      if (fechamento != null) {
        this.carregando = true;
        this.menssagem = null;
        this.resultado = this.limpar;
        this.$http
          .get(
            "api/fechamento-geral/resultado/" +
            fechamento +
            "/" +
            ano +
            "/" +
            semana
          )
          .then((response) => {
            this.resultado = response.data;
            this.carregando = false;
            this.gerarApresentacao();
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.erro;
            this.carregando = false;
          });
      }
      if (fechamento != null) {
        this.carregando = true;
        this.menssagem = null;
        this.fechamento_geral = this.limpar;
        this.$http
          .get("api/fechamento-geral/" + fechamento)
          .then((response) => {
            this.fechamento_geral = response.data;
            this.carregando = false;
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.erro;
            this.carregando = false;
          });
      }
    },
  },
  mounted() {
    //this.carregarFechamentoGeral()
    this.url = process.env.VUE_APP_ROOT_API;
  },
  watch: {
    resultado: function () {
      if (this.resultado) {
        var els = document.getElementsByClassName("tab-pane");
        Array.prototype.forEach.call(els, function (el) {
          el.style.display = "block";
        });
      }
    },
  },
};
</script>
