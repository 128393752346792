<template>
    <div class="pagina">
        <b-row align-v="stretch">
            <b-col cols="5">
                <b-card class="card">
                    <b-row>
                        <b-col cols="8">
                            <risk-bar v-if="risks.quantidade" :risks="risks.quantidade" :cores="risks.cor"
                                :categorias="risks.label" :height="160" />
                        </b-col>
                        <b-col cols="4">
                            <risk-legenda-2 />
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="card mt-3 mb-3">
                    <root-cause-bar v-if="analysis" :analysis="analysis" :risk="true" :height="170" />
                </b-card>
                <b-card class="card"><chart-bar-characteristics v-if="analysis_type"
                        :analysis_type="analysis_type" /></b-card>
            </b-col>
            <b-col cols="7">
                <b-card class="card text-center bold">
                    <h1>Risk</h1>
                    <h1>Assessment</h1>
                    <h4>{{ componente.descricao }} - {{ componente.desenho }}</h4>
                </b-card>
                <b-card class="card-img mt-3">
                    <div class="lab__card" :style="'background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)),url(' +
                        url + '/organization/' + user.tenant_id + '/api/storage/' +
                        '/' +
                        componente.imagem +
                        ') no-repeat center center /100%'
                        "></div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import RiskLegenda2 from "@/components/RiskLegenda2.vue";
import RiskBar from "@/components/RiskBar.vue";
import RootCauseBar from "@/components/RootCauseBar.vue";
import ChartBarCharacteristics from "@/components/ChartBarCharacteristics.vue";

export default {
    props: {
        risks: null,
        analysis: [],
        cores: [],
        categorias: [],
        componente: null,
        analysis_type: null
    },
    data() {
        return {
            url: null,
        }
    },
    components: {
        RiskLegenda2,
        RiskBar,
        RootCauseBar,
        ChartBarCharacteristics
    },
    mounted() {
        this.url = process.env.VUE_APP_ROOT_API;
    },
    computed: {
        user() {
            return this.$store.state.auth.user.user;
        },
    },
}
</script>

<style scoped>
.pagina {
    border-radius: 1rem;
    width: 1035px;
    height: 652px;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 1rem;
    border: 1px solid gray;
}

.card {
    height: 195px;
}

.card-img {
    height: 405px;
}

.bold {
    font-weight: bold;
}

.lab__card {
    height: 400px;
    width: 500px;
    padding: 0px;
    margin: 0px;
    object-fit: contain;
    transition: transform 0.45s;
    color: white;
}
</style>