import { render, staticRenderFns } from "./Tabela.vue?vue&type=template&id=537ddb3b&scoped=true&"
import script from "./Tabela.vue?vue&type=script&lang=js&"
export * from "./Tabela.vue?vue&type=script&lang=js&"
import style0 from "./Tabela.vue?vue&type=style&index=0&id=537ddb3b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "537ddb3b",
  null
  
)

export default component.exports