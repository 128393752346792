<template>
    <b-toast id="my-toast" variant="info" solid :no-auto-hide="true" :no-close-button="no_close_active"
        toaster="b-toaster-bottom-right" :visible="true">
        <template #toast-title>
            <div class="d-flex flex-grow-1 align-items-baseline">
                <b-icon icon="circle-fill" animation="throb"></b-icon>
                <strong class="mr-auto ml-2">Carregando Controles</strong>
                <!-- <small class="text-muted mr-2">42 seconds ago</small> -->
            </div>
        </template>
        <b-progress :value="value" :max="max" show-progress animated></b-progress>
    </b-toast>
</template>

<script>
export default {
    data() {
        return {
            interval:null,
            value: 1,
            max: 100,
            no_close_active: true
        }
    },
    mounted() {
        this.interval = setInterval(() => {
            this.atualizarProgresso();
        }, 10000);
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
    methods: {
        atualizarProgresso() {
            this.$http
                .get("api/process/" + this.$store.state.carregandoControleWinfas)
                .then((response) => {
                    console.log(response)
                    this.value = response.data.progresso

                    if (response.data.progresso == 100) {
                        clearInterval(this.interval);
                        //this.no_close_active = false;
                        this.$store.state.carregandoControleWinfas = null;
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
    },
}
</script>

<style></style>