<template>
  <b-modal id="modal-trend-novo-grupo" size="lg" :title="$t('title.novo-grupo')" centered>
    <template #modal-header>
      <h5>{{ $t('title.novo-grupo') }}</h5>
      <p class="h4 btn m-0 p-0">
        <b-icon icon="x" @click="close()"></b-icon>
      </p>
    </template>

    <b-form v-if="show">

      <b-form-group id="input-group-1" :label="$t('label.familia')" label-for="input-1">
        <b-form-select :options="familiasOptions" v-model="familia"></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-1" :label="$t('label.componente')" label-for="input-1">
        <b-form-select :options="componentesOptions" v-model="componente" :disabled="!familia">
        </b-form-select>
      </b-form-group>

      <b-form-group id="input-group-1" :label="$t('label.relatorio')" label-for="input-1">
        <b-form-select :options="relatoriosOptions" v-model="form.relatorio" :disabled="!componente">
        </b-form-select>
      </b-form-group>

      <b-form-group id="input-group-1" :label="$t('label.description')" label-for="input-1">
        <b-form-input id="input-1" v-model="form.description" type="text"
          :placeholder="$t('placeholder.entre-com-a-descricao')" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-1" :label="$t('label.title-higher')" label-for="input-1">
        <b-form-input id="input-1" v-model="form.title" type="text" :placeholder="$t('placeholder.entre-com-o-titulo')"
          required></b-form-input>
      </b-form-group>

      <b-form-group :label="$t('label.formato-impressao')">
        <b-form-radio-group size="sm" v-model="form.format" :options="optionsFormatos" name="radio-options-conversor">
        </b-form-radio-group>
      </b-form-group>

    </b-form>

    <b-alert show variant="danger" v-if="menssagem" class="mt-2">
      {{ menssagem }}
    </b-alert>

    <template #modal-footer>
      <b-button type="submit" variant="secondary" class="float-right mr-2" @click="close()">
        {{ $t('botoes.fechar') }} </b-button>

      <b-button variant="primary" class="float-left" v-if="carregando" disabled>
        <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
        {{ $t('botoes.carregando') }}
      </b-button>

      <b-button v-else type="submit" variant="success" class="float-left" @click="salvar()">
        {{ $t('botoes.cadastrar') }} </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      menssagem: null,
      carregando: null,
      familiasOptions: [],
      familia: null,
      componentesOptions: [],
      componente: null,
      relatoriosOptions: [],
      form: {
        familia: null,
        componente: null,
        relatorio: null,
        title: null,
        description: null,
        format: 1,
      },
      optionsFormatos: [
        { text: "PDF", value: 1 },
        { text: "JPG", value: 2 }
      ],
    };
  },
  methods: {
    carregarFamilias() {
      this.$bvModal.show("modal-carregando-geral");
      this.$http
        .get("api/familia")
        .then((response) => {
          this.$bvModal.hide("modal-carregando-geral");
          this.familiasOptions = [];
          response.data.forEach((element) => {
            this.familiasOptions.push({
              value: element.id,
              text: element.descricao,
            });
          });
        })
        .catch((errors) => {
          console.log(errors);
          this.$bvModal.hide("modal-carregando-geral");
        });
    },
    carregarComponentes() {
      this.$bvModal.show("modal-carregando-geral");
      this.$http
        .get("api/familia/" + this.familia)
        .then((response) => {
          this.$bvModal.hide("modal-carregando-geral");
          this.componentesOptions = [];
          var componentes = response.data.componentes
          componentes.forEach((element) => {
            this.componentesOptions.push({
              value: element.id,
              text: element.desenho + ' - ' + element.descricao,
            });
          });
        })
        .catch((errors) => {
          console.log(errors);
          this.$bvModal.hide("modal-carregando-geral");
        });
    },
    carregarRelatorios() {
      this.$bvModal.show("modal-carregando-geral");
      this.$http
        .get("api/componente/" + this.componente)
        .then((response) => {
          this.$bvModal.hide("modal-carregando-geral");
          this.relatoriosOptions = [];
          response.data.relatorios.forEach((element) => {
            this.relatoriosOptions.push({
              value: element.id,
              text: element.descricao,
            });
          });
        })
        .catch((errors) => {
          console.log(errors);
          this.$bvModal.hide("modal-carregando-geral");
        });
    },
    close() {
      this.$bvModal.hide("modal-trend-novo-grupo");
    },
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
        title: this.$t('toast.sucesso'),
        variant: variant,
        solid: true,
      });
    },
    salvar() {
      this.menssagem = null;
      this.carregando = true;
      let formData = new FormData();
      
      formData.append("descricao", this.form.description);
      formData.append("titulo", this.form.title);
      formData.append("relatorio_id", this.form.relatorio);
      formData.append("formato", this.form.format);

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post('api/trend-grupo', formData, config)
        .then((response) => {
          this.makeToast("success");
          this.$bvModal.hide("modal-trend-novo-grupo");
          this.carregando = false;
          this.$emit("listaMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
  },
  computed: {},
  mounted() {
    this.carregarFamilias();
  },
  watch: {
    familia: function (familia) {
      if (familia)
        this.carregarComponentes();
    },
    componente: function (componente) {
      if (componente)
        this.carregarRelatorios();
    },
  },
};
</script>

<style>
</style>