<template>
    <b-modal id="modal-status-peca" size="md" :title=" $t('title.editar-status-peca') " centered header-text-center>

        <b-card :header="$t('title.status')">
            <b-form-radio-group v-model="peca.status" :options="options"></b-form-radio-group>
        </b-card>

        <b-card :header="$t('title.odm')" class="mt-3">
            <b-form-input id="input-odm" v-model="peca.odm" type="text" placeholder="XXXX-XXXX "></b-form-input>
        </b-card>

        <template #modal-footer>
            <div class="w-100">
                <b-row align-v="center">
                    <b-col>
                        <b-button variant="primary" class="float-right" v-if="carregando" disabled>
                            <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                            {{ $t('botoes.carregando') }}
                        </b-button>

                        <b-button v-if="!carregando" type="submit" variant="primary" class="float-right" @click="salvar()">
                            {{ $t('botoes.salvar') }} </b-button>

                        <b-button v-if="!carregando" type="submit" variant="secondary" class="float-right mr-2" @click="redefinir()">
                            {{ $t('botoes.redefinir-status') }} </b-button>
                    </b-col>
                </b-row>
            </div>
        </template>
    </b-modal>
</template>

<script>
export default {
    props: {
        peca: null
    },
    data() {
        return {
            carregando: null,
            menssagem:null,
            options: [
                { text: 'Aprovado', value: 'aprovado' },
                { text: 'Reprogramado', value: 'reprogramado' },
                { text: 'Reprovado', value: 'reprovado' },
            ]
        }
    },
    methods: {        
        redefinir(){
            this.peca.status = null
            this.salvar();
        },
        salvar() {
            this.menssagem = null;
            this.carregando = true;
            let formData = new FormData();
            formData.append("status", this.peca.status);
            formData.append("odm", this.peca.odm);                   

            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                },
            };

            this.$http
                .post('api/fechamento-geral/updatePeca/'+this.peca.id, formData, config)
                .then((response) => {      
                    this.$emit("salvou");              
                    this.$bvModal.hide("modal-status-peca");
                    this.carregando = false;                    
                })
                .catch((errors) => {
                    this.menssagem = errors.response.data.errors;
                    this.carregando = false;
                });
        }
    }
}
</script>

<style>

</style>