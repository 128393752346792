<template>
    <b-modal id="modal-usuario" size="lg" :title=" $t('title.usuario') ">
        <template #modal-header>
            <!-- Emulate built in modal header close button action -->
            <h5>{{ $t('title.usuario') }}</h5>
            <div class="btn">
                <b-icon icon="x" @click="close()"></b-icon>
            </div>
        </template>
        <b-form v-if="show">
            <b-form-group :label=" $t('labe.nome') ">
                <b-form-input v-model="form.nome" type="text" :placeholder=" $t('placeholder.entre-com-o-nome') " required>
                </b-form-input>
            </b-form-group>

            <b-form-group label="E-mail">
                <b-form-input v-model="form.email" type="email" :placeholder=" $t('placehoader.entre-com-o-e-mail') " required>
                </b-form-input>
            </b-form-group>

            <b-form-group :label=" $t('label.senha') ">
                <b-form-input v-model="form.senha" type="password" :placeholder=" $t('placeholder.entre-com-a-senha') " required>
                </b-form-input>
            </b-form-group>

            <b-form-group :label=" $t('label.confirme-a-senha') ">
                <b-form-input v-model="form.confirmacao_senha" type="password" :placeholder=" $t('label.confirme-a-senha') " required>
                </b-form-input>
            </b-form-group>

            <b-form-group :label=" $t('label.departamento') ">
                <b-form-select :options="departamentos" v-model="form.departamento" required></b-form-select>
            </b-form-group>
        </b-form>

        <b-alert show variant="danger" v-if="menssagem">
            <ul v-if="menssagem">
                <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
            </ul>
        </b-alert>

        <template #modal-footer>
            <div class="w-100">
                <b-row align-v="center">
                    <b-col cols="12" md="8">
                        <b-row align-v="center" align-h="center">
                            <b-col>
                                <p class="h1 ml-3">
                                    <b-icon icon="exclamation-octagon" variant="primary"></b-icon>
                                </p>
                            </b-col>
                            <b-col cols="12" md="10">
                                <p class="pt-2">
                                    <span class="text-primary"> {{ $t('modal.importante') }}</span><br />
                                    {{ $t('modal.preenchaTodosOsDadosCorretamente') }} </p>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="6" md="4">
                        <b-button variant="primary" class="float-right" v-if="carregando" disabled>
                            <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                            {{ $t('botoes.carregando') }} </b-button>

                        <b-button v-else type="submit" variant="primary" class="float-right" @click="salvar()">
                            {{ $t('botoes.salvar') }} </b-button>

                        <b-button v-if="usuario.id" type="submit" variant="danger" class="float-right mr-2" @click="
                            showMsgConfirmation(
                                'Tem certeza que deseja remover esse usuario?'
                            )
                        ">
                            {{ $t('botoes.deletar') }} </b-button>
                    </b-col>
                </b-row>
            </div>
        </template>
    </b-modal>
</template>

<script>
export default {
    props: {
        usuario: {
            type: Object,
            default: function () {
                return {
                    id: null,
                    familia: null,
                    desenho: null,
                    descricao: null,
                    indicador: null
                };
            },
        },
    },
    data() {
        return {
            form: {
                nome: null,
                email: null,
                senha: null,
                confirmacao_senha: null,
                departamento: null,
            },
            departamentos: [],
            show: true,
            menssagem: null,
            carregando: null,
            url: "api/user",
        };
    },
    methods: {
        makeToast(variant = null) {
            this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
                 title: this.$t('toast.sucesso'),
                variant: variant,
                solid: true,
            });
        },
        close() {
            this.$bvModal.hide("modal-usuario");
        },
        limpar() {
            this.form.nome = null;
            this.form.email = null;
            this.form.senha = null;
            this.form.confirmacao_senha = null;
            this.form.departamento = null;
            this.menssagem = null;
        },
        salvar() {
            this.menssagem = null;
            this.carregando = true;
            let formData = new FormData();
            formData.append("name", this.form.nome);
            formData.append("email", this.form.email);            
            formData.append("departamento_id", this.form.departamento);

            if (this.usuario.id) {
                formData.append("_method", "patch");

                if (this.form.senha) {
                    formData.append("password", this.form.senha);
                }
                if (this.form.senha) {
                    formData.append("password_confirmation", this.form.confirmacao_senha);
                }
            }else{
                formData.append("password", this.form.senha);
                formData.append("password_confirmation", this.form.confirmacao_senha);
            }

            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                },
            };

            this.$http
                .post(this.url, formData, config)
                .then((response) => {
                    this.makeToast("success");
                    this.$bvModal.hide("modal-usuario");
                    this.carregando = false;
                    this.$emit("listaMudou");
                })
                .catch((errors) => {
                    this.menssagem = errors.response.data.errors;
                    this.carregando = false;
                });
        },
        deletar() {
            let formData = new FormData();
            formData.append("_method", "delete");

            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                },
            };

            this.$http
                .post(this.url, formData, config)
                .then((response) => {
                    this.makeToast("success");
                    this.$bvModal.hide("modal-usuario");
                    this.carregando = false;
                    this.$emit("listaMudou");
                })
                .catch((errors) => {
                    this.menssagem = errors.response.data.errors;
                    this.carregando = false;
                });
        },
        showMsgConfirmation(msg) {
            this.$bvModal
                .msgBoxConfirm(msg, {
                    title: this.$t('title.confirme-por-favor'),
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: this.$t('confirmacao.sim'),
                    cancelTitle: this.$t('confirmacao.nao'),
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value == true) {
                        this.deletar();
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        carregarDepartamentos() {
            this.$bvModal.show("modal-carregando-geral");
            this.$http
                .get("api/departamento")
                .then((response) => {
                    this.departamentos = []
                    response.data.forEach((element) => {
                        this.departamentos.push({
                            value: element.id,
                            text: element.descricao,
                        });
                    });
                    this.$bvModal.hide("modal-carregando-geral");
                })
                .catch((errors) => {
                    console.log(errors);
                    this.$bvModal.hide("modal-carregando-geral");
                });
        },
    },
    mounted() {
        this.carregarDepartamentos();
    },
    watch: {
        usuario: function (usuario) {
            if (this.usuario.id) {
                this.form.nome = usuario.nome;
                this.form.email = usuario.email;
                this.form.departamento = usuario.departamento;
                this.url = "api/user/" + this.usuario.id;
            } else {
                this.url = "api/user";
                this.limpar();
            }
        },
        familia: function (familia) {
            this.form.familia = familia;
        },
    },
};
</script>

<style >
</style>