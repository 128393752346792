<template>
  <div class="card">
    <table class="text-center  table-bordered table-condensed">
      <tr>
        <th>
          <img :src="'../assets/simbol/' + elemento.tipo_elemento.imagem" width="24" />
        </th>
        <th colspan="3">{{ elemento.descricao }}</th>
        <th colspan="2">AUTOMARB</th>
      </tr>
      <tr>
        <th>Ax</th>
        <th>NOM</th>
        <th>MED</th>
        <th>DSV</th>
        <th colspan="2">TOLERANCE</th>
      </tr>

      <tr v-for="eixo in eixosElemento" :key="eixo" style="font-weight: bold">

        <td :style="elemento['calculo' + eixo] | calculo">{{ eixo.toUpperCase() }}</td>
        <td>{{ elemento.valor_padrao['nom' + eixo] | casas(3) }}</td>
        <td>{{ elemento.valor_padrao['med' + eixo] | casas(3) }}</td>
        <td :style="'background-color:' + elemento.valor_padrao[eixo + 'desvio_cor']  + (elemento.valor_padrao[eixo + 'desvio_cor'] == 'red' ?  '; color: white' : '')">
          {{
            desvio(elemento.valor_padrao['nom' + eixo], elemento.valor_padrao['med' + eixo])
            | casas(3)
          }}
        </td>

        <td>{{ elemento['li' + eixo + 'tol'] | casas(3) }}</td>
        <td>{{ elemento['ls' + eixo + 'tol'] | casas(3) }}</td>

      </tr>

    </table>
  </div>
</template>

<script>
export default {
  props: {
    elemento: {
      type: Object,
    },
    controle: null,
  },
  data() {
    return {
      carregando: false,
      dados: null,
      tipo: null,
      eixos: ['x', 'y', 'z', 't', 'd', 'l', 'w', 'p', 'a'],
    };
  },
  computed: {
    eixosElemento() {
      var eixosElemento = [];
      this.eixos.forEach(eixo => {
        if (this.elemento['exibir' + eixo]) {
          eixosElemento.push(eixo)
        }
      });
      return eixosElemento;
    },
  },
  methods: {
    desvio(nominal, medido){      
        return medido - nominal      
    }
  },
  mounted() { },
};
</script>

<style scoped>
.table-condensed {
  font-size: 12px;
  padding: 0;
}

tr td,
th {
  padding: 2px !important;

}

teste{
  font-weight: bold;
}
</style>
