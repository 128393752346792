<template>
  <div class="dot align-middle">
    <b-row class="align-middle" style="margin-top: 15%">
      <b-col>{{ elemento.descricao }}</b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="eixos">
          <span v-for="eixo in eixosElemento" :key="eixo" :style="'background-color:' + elemento[eixo + 'riskcor']">{{
            eixo.toUpperCase() }}</span>
        </div>
      </b-col>
    </b-row>

    <b-row class="align-middle">
      <b-col>
        <div class="planos">

          <template v-for="eixo in eixosElemento">

            <span :key="eixo + '_'" v-if="avaliablePlano(elemento.descricao_ref, eixo)[0]
              " :style="'background-color:' +
    avaliablePlano(elemento.descricao_ref, eixo)[0].analysis.cor
    ">
              {{ avaliablePlano(elemento.descricao_ref, eixo)[0].seq }}</span>

            <span :key="eixo" v-if="!avaliablePlano(elemento.descricao_ref, eixo)[0]
              ">
              -
            </span>

          </template>

        </div>
      </b-col>
    </b-row>

    <b-row class="align-middle">
      <b-col>Plan</b-col>
    </b-row>
    
  </div>
</template>

<script>
export default {
  props: {
    elemento: {
      type: Object,
    },
    plano: null,
  },
  data() {
    return { eixos: ['x', 'y', 'z', 't', 'd', 'l', 'w', 'p', 'a'], }
  },
  computed: {
    eixosElemento() {
      var eixosElemento = [];
      this.eixos.forEach(eixo => {
        if (this.elemento['exibir' + eixo]) {
          eixosElemento.push(eixo)
        }
      });
      return eixosElemento;
    },
  },
  methods: {
    avaliablePlano(elemento, ax) {
      return this.plano
        .filter((c) => c.caracteristica.elemento == elemento)
        .filter((c) => c.caracteristica.ax == ax);
    },
  },
};
</script>

<style scoped>
.table-condensed {
  font-size: 12px;
  padding: 0;
}

tr td,
th {
  padding: 2px !important;
}

.dot {
  height: 100px;
  width: 100px;
  background-color: #cdb19d;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 12px;
  font-weight: bold;
  -webkit-box-shadow: -9px 14px 13px 0px rgba(50, 50, 50, 0.31);
  -moz-box-shadow: -9px 14px 13px 0px rgba(50, 50, 50, 0.31);
  box-shadow: -9px 14px 13px 0px rgba(50, 50, 50, 0.31);
}

.eixos {
  font-size: 14px;
  padding: 0px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
  border-radius: 20px 20px 20px 20px;
}

.eixos span {
  padding: 2px 6px 2px 6px;
  border-radius: 10px 10px 10px 10px;
}

.planos {
  padding: 0px;
  margin-top: 3px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
}

.planos span {
  padding: 3px 6px 3px 6px;

}
</style>