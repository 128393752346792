<template>
  <b-modal
    id="modal-plano-de-acao"
    size="xl"
    :title="$t('title.automarb-plano-de-acao')"
  >
    <template #modal-header>
      <!-- Emulate built in modal header close button action -->
      <h5>{{ $t("title.automarb-plano-de-acao") }}</h5>
      <p class="btn m-0 p-0">
        <b-icon icon="x" @click="close()"></b-icon>
      </p>
    </template>

    <b-form v-if="show">
      <b-row>
        <b-col sm="4">
          <b-card
            bg-variant="light"
            header="Caracteristicas"
            class="container-caracteristicas"
          >
            <b-row class="m-0">
              <b-form-group id="input-group-1" :label="$t('label.indicador')">
                <b-form-select
                  v-model="filtro_indicador"
                  :options="optionsIndicadores"
                  size="sm"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="$t('label.filtro')"
                class="ml-2"
              >
                <b-form-select
                  v-model="filtro"
                  :options="optionsFiltros"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-row>

            <b-form-group id="input-group-1">
              <b-form-select
                v-model="caracteristica_selecionada"
                :options="availableOptions"
                :select-size="22"
              >
              </b-form-select>
            </b-form-group>

            <b-button size="sm" v-on:click="addCaracteristica()">
              <b-icon icon="plus" aria-hidden="true"></b-icon
              >{{ $t("botoes.adicionar") }}
            </b-button>
          </b-card>
        </b-col>
        <b-col sm="8" class="overflow-auto container-detalhes">
          <b-card
            bg-variant="light"
            header="Histórico"
            v-if="plano.id"
            class="mb-3"
          >
            <b-form-select
              v-model="acao_selecionada"
              :options="historico"
            ></b-form-select>
          </b-card>

          <b-card bg-variant="light" header="Pontos da Ação">
            <div class="table-wrapper">
              <table class="table table-bordered table-condensed table-sm">
                <tbody>
                  <tr
                    v-for="(item, index) in form.caracteristicas"
                    :key="item + index"
                  >
                    <td>{{ item.elemento }}</td>
                    <td>{{ item.ax }}</td>
                    <td>
                      <b-button
                        size="sm"
                        v-on:click="removeCaracteristica(item)"
                      >
                        <b-icon icon="trash" aria-hidden="true"></b-icon>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card>

          <b-card bg-variant="light" header="Detalhes da Ação" class="mt-3">
            <b-form-group v-slot="{ ariaDescribedby }" class="text-radio">
              <b-form-radio-group
                id="radio-group-1"
                v-model="form.ultima_acao.tipo_acao_id"
                :options="optionsTipoAcao"
                :aria-describedby="ariaDescribedby"
                name="radio-options"
              ></b-form-radio-group>
            </b-form-group>
            <b-form-group :label="$t('label.acao-de-execucao')">
              <b-form-textarea
                id="textarea"
                v-model="form.ultima_acao.descricao"
                :placeholder="$t('placehoader.descreva-a-acao-a-ser-realizada')"
                rows="3"
                max-rows="6"
              >
              </b-form-textarea>
            </b-form-group>

            <b-form-group :label="$t('label.status')">
              <b-form-select
                v-model="form.ultima_acao.status"
                :options="optionsTipoStatusAcao"
              ></b-form-select>
            </b-form-group>
            <b-row>
              <b-col sm="6">
                <b-form-group id="input-group-1" :label="$t('label.analysis')">
                  <b-form-select
                    v-model="form.ultima_acao.analysis_id"
                    :options="optionsAnalysis"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group id="input-group-1" :label="$t('label.prazo')">
                  <b-form-datepicker
                    v-model="form.ultima_acao.prazo"
                    locale="pt-BR"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group
              :label="$t('label.responsabilidade')"
              label-for="tags-with-dropdown"
            >
              <multiselect
                v-model="form.ultima_acao.responsaveis"
                :options="availableOptionsResponsaveis"
                :multiple="true"
                :placeholder="$t('placeholder.selecione-o-responsavel')"
                track-by="text"
                label="text"
              >
                <span slot="noResult">{{
                  $t("error.nao-existe-responsaveis-disponiveis")
                }}</span>
              </multiselect>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </b-form>

    <b-alert show variant="danger" v-if="menssagem" class="mt-3">
      <ul v-if="menssagem">
        <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
      </ul>
    </b-alert>

    <template #modal-footer>
      <div class="w-100">
        <b-row align-v="center">
          <b-col cols="12" md="8">
            <b-row align-v="center" align-h="center">
              <b-col>
                <p class="h1 ml-3">
                  <b-icon icon="exclamation-octagon" variant="primary"></b-icon>
                </p>
              </b-col>
              <b-col cols="12" md="10">
                <p class="pt-2">
                  <span class="text-primary"> {{ $t("modal.importante") }}</span
                  ><br />
                  {{ $t("modal.preenchaTodosOsDadosCorretamente") }}
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" md="4">
            <b-button
              variant="primary"
              class="float-right"
              v-if="carregando"
              disabled
            >
              <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
              {{ $t("botoes.carregando") }}
            </b-button>

            <b-button
              v-else
              type="submit"
              variant="primary"
              class="float-right"
              @click="salvarPlano()"
            >
              {{ $t("botoes.salvar") }}
            </b-button>

            <b-button
              v-if="plano.id"
              type="submit"
              variant="danger"
              class="float-right mr-2"
              @click="
                showMsgConfirmation(
                  'Tem certeza que deseja remover esse plano de ação?'
                )
              "
            >
              {{ $t("botoes.deletar") }}
            </b-button>

            <b-button
              v-if="plano.id"
              type="submit"
              variant="success"
              class="float-right mr-2"
              @click="novaAcao()"
            >
              {{ $t("botoes.nova-acao") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  props: {
    relatorio_id: null,
    caracteristicas: null,
    planos: null,
    indicador: null,
    plano: {
      type: Object,
      default: function () {
        return {
          id: null,
        };
      },
    },
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 2);
    maxDate.setDate(15);
    return {
      show: true,
      menssagem: null,
      carregando: null,
      url: "api/plano",
      historico: [],
      acoes: [],
      acao: null,
      acao_selecionada: null,
      form: {
        relatorio_id: null,
        novaAcao: false,
        ultima_acao: {
          tipo_acao_id: 1,
          analysis_id: null,
          descricao: null,
          status: null,
          prazo: null,
          responsaveis: [],
        },
        caracteristicas: [],
      },

      form_limpo: {
        relatorio_id: null,
        novaAcao: false,
        ultima_acao: {
          tipo_acao_id: 1,
          analysis_id: null,
          descricao: null,
          status: null,
          prazo: null,
          responsaveis: [],
        },
        caracteristicas: [],
      },

      optionsResponsaveis: [],
      responsaveis: [],
      search: "",
      optionsCaracteristicas: [],
      caracteristica_selecionada: null,

      optionsAnalysis: [
        { value: null, text: this.$t("opcoes.selecione") },
        { value: 1, text: this.$t("opcoes.parts") },
        { value: 2, text: this.$t("opcoes.process") },
        { value: 3, text: this.$t("opcoes.investigation") },
      ],

      filtro_indicador: null,
      optionsIndicadores: [
        { value: null, text: this.$t("opcoes.selecione") },
        {
          value: "conformidade",
          text: this.$t("opcoes.indicadores.conformidade"),
        },
        { value: "cpk", text: this.indicador == "cp" ? "CPK" : "PPK" },
      ],

      filtro: null,
      optionsFiltros: [
        { value: null, text: this.$t("opcoes.todos") },
        { value: "v", text: this.$t("opcoes.criterios.vermelho") },
        { value: "a", text: this.$t("opcoes.criterios.amarelo") },
        { value: "va", text: this.$t("opcoes.criterios.vermelho-e-amarelo") },
      ],

      optionsTipoAcao: [
        { text: this.$t("opcoes.x-acao-programada"), value: 1 },
        { text: this.$t("opcoes.r-acao-reprogramada"), value: 2 },
        { text: this.$t("opcoes.nok-acao-nao-efetiva"), value: 3 },
        { text: this.$t("opcoes.nao-definida"), value: 4 },
      ],

      optionsTipoStatusAcao: [
        { text: this.$t("opcoes.nao-iniciado"), value: 1 },
        { text: this.$t("opcoes.em-andamento"), value: 2 },
        { text: this.$t("opcoes.realizado"), value: 3 },
        { text: this.$t("opcoes.cancelado"), value: 4 },
      ],

      optionsAtivos: [],

      min: minDate,
      max: maxDate,
    };
  },
  methods: {
    novaAcao() {
      this.form.novaAcao = true;
      this.salvarPlano();
    },
    carregarUsuarios() {
      //this.$bvModal.show("modal-carregando-geral");
      this.$http
        .get("api/user")
        .then((response) => {
          this.optionsResponsaveis = [];
          response.data.forEach((element) => {
            let departamento = element.departamento_id
              ? "(" + element.departamento.descricao + ")"
              : "";
            this.optionsResponsaveis.push({
              value: element.id,
              text: element.name + departamento,
            });
          });
          this.$bvModal.hide("modal-carregando-geral");
        })
        .catch((errors) => {
          console.log(errors);
          this.$bvModal.hide("modal-carregando-geral");
        });
    },
    addCaracteristica() {
      if (this.caracteristica_selecionada != null) {
        var eixo = null;
        var elemento = null;

        if (this.caracteristica_selecionada.split(" - ").length > 2) {
          eixo = this.caracteristica_selecionada.split(" - ")[2];
          elemento =
            this.caracteristica_selecionada.split(" - ")[0] +
            " - " +
            this.caracteristica_selecionada.split(" - ")[1];
        } else {
          eixo = this.caracteristica_selecionada.split(" - ")[1];
          elemento = this.caracteristica_selecionada.split(" - ")[0];
        }

        var c = {
          elemento: elemento,
          ax: eixo,
        };
        this.optionsAtivos.push(c.elemento + " - " + c.ax);
        this.form.caracteristicas.push(c);
      }
    },
    removeCaracteristica(item) {
      if (item != null) {
        var index = this.form.caracteristicas.indexOf(item);
        if (index > -1) {
          this.form.caracteristicas.splice(index, 1);
        }

        var index = this.optionsAtivos.indexOf(item.elemento + " - " + item.ax);
        if (index > -1) {
          this.optionsAtivos.splice(index, 1);
        }
      }
    },
    carregarCaracteristicas() {
      this.menssagem = null;
      this.optionsCaracteristicas = [];
      this.form.relatorio_id = this.relatorio_id;
      this.caracteristicas.forEach((caract) => {
        if (this.filtro_indicador != null && this.filtro != null) {
          if (this.filtro_indicador == "conformidade") {
            if (this.filtro == "v") {
              if (caract.medcor == "red") {
                this.optionsCaracteristicas.push({
                  value: caract.ponto + " - " + caract.ax.toUpperCase(),
                  text: caract.ponto + " - " + caract.axtoUpperCase(),
                });
              }
            } else if (this.filtro == "a") {
              if (caract.medcor == "yellow") {
                this.optionsCaracteristicas.push({
                  value: caract.ponto + " - " + caract.ax.toUpperCase(),
                  text: caract.ponto + " - " + caract.ax.toUpperCase(),
                });
              }
            } else {
              if (caract.medcor == "yellow" || caract.medcor == "red") {
                this.optionsCaracteristicas.push({
                  value: caract.ponto + " - " + caract.ax.toUpperCase(),
                  text: caract.ponto + " - " + caract.ax.toUpperCase(),
                });
              }
            }
          } else {
            if (this.filtro == "v") {
              if (caract.cpkcor == "red") {
                this.optionsCaracteristicas.push({
                  value: caract.ponto + " - " + caract.ax.toUpperCase(),
                  text: caract.ponto + " - " + caract.ax.toUpperCase(),
                });
              }
            } else if (this.filtro == "a") {
              if (caract.cpkcor == "yellow") {
                this.optionsCaracteristicas.push({
                  value: caract.ponto + " - " + caract.ax.toUpperCase(),
                  text: caract.ponto + " - " + caract.ax.toUpperCase(),
                });
              }
            } else {
              if (caract.cpkcor == "yellow" || caract.cpkcor == "red") {
                this.optionsCaracteristicas.push({
                  value: caract.ponto + " - " + caract.ax.toUpperCase(),
                  text: caract.ponto + " - " + caract.ax.toUpperCase(),
                });
              }
            }
          }
        } else {
          this.optionsCaracteristicas.push({
            value: caract.ponto + " - " + caract.ax.toUpperCase(),
            text: caract.ponto + " - " + caract.ax.toUpperCase(),
          });
        }
      });
    },
    salvarPlano() {
      this.menssagem = null;
      this.carregando = true;

      let config = {
        headers: {
          Accept: "application/json",
        },
      };

      if (this.plano.id) {
        this.$http
          .put(this.url + "/" + this.plano.id, this.form, config)
          .then((response) => {
            this.makeToast("success");
            this.$bvModal.hide("modal-plano-de-acao");
            this.carregando = false;
            this.$emit("listaMudou");
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.errors;
            this.carregando = false;
          });
      } else {
        this.$http
          .post(this.url, this.form, config)
          .then((response) => {
            this.makeToast("success");
            this.$bvModal.hide("modal-plano-de-acao");
            this.carregando = false;
            this.$emit("listaMudou");
          })
          .catch((errors) => {
            this.menssagem = errors.response.data.errors;
            this.carregando = false;
          });
      }
    },
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t("toast.operacao-realizada-com-sucesso"), {
        title: this.$t("toast.sucesso"),
        variant: variant,
        solid: true,
      });
    },
    close() {
      this.$bvModal.hide("modal-plano-de-acao");
    },
    onOptionClick({ option, addTag }) {
      addTag(option);
      this.search = "";
    },
    showMsgConfirmation(msg) {
      this.$bvModal
        .msgBoxConfirm(msg, {
          title: this.$t("title.confirme-por-favor"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("confirmacao.sim"),
          cancelTitle: this.$t("confirmacao.nao"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.deletar();
          } else {
            return false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deletar() {
      this.carregando = true;
      let formData = new FormData();
      formData.append("_method", "delete");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.url = this.url + "/" + this.plano.id;

      this.$http
        .post(this.url, formData, config)
        .then((response) => {
          this.makeToast("success");
          this.$bvModal.hide("modal-plano-de-acao");
          this.carregando = false;
          this.$emit("listaMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    carregarHistorico() {
      if (this.plano) {
        this.$bvModal.show("modal-carregando-geral");
        this.$http
          .get("api/plano/historico/" + this.plano.id)
          .then((response) => {
            this.acoes = response.data;
            this.historico = [];

            response.data.forEach((acao) => {
              this.historico.push({
                value: acao.id,
                text:
                  new Date(acao.prazo).toLocaleDateString("pt-br") +
                  " - " +
                  acao.tipo_acao.descricao,
              });
            });

            this.$bvModal.hide("modal-carregando-geral");
          })
          .catch((errors) => {
            console.log(errors);
            this.$bvModal.hide("modal-carregando-geral");
          });
      }
    },
    carregarAnalysis() {
      if (this.plano) {
        this.$http
          .get("api/analysis")
          .then((response) => {
            this.optionsAnalysis = [];

            response.data.forEach((analysi) => {
              this.optionsAnalysis.push({
                value: analysi.id,
                text: analysi.descricao,
              });
            });
          })
          .catch((errors) => {
            console.log(errors);
          });
      }
    },
  },
  computed: {
    availableOptions() {
      return this.optionsCaracteristicas
        .filter((opt) => this.optionsAtivos.indexOf(opt.value) === -1)
        .filter((opt) => this.planos.indexOf(opt.value) === -1);
    },
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase();
    },
    availableOptionsResponsaveis() {
      const criteria = this.criteria;
      // Filter out already selected options
      const options = this.optionsResponsaveis.filter(
        (opt) => this.responsaveis.indexOf(opt) === -1
      );
      if (criteria) {
        // Show only options that match criteria
        return options.filter(
          (opt) => opt.toLowerCase().indexOf(criteria) > -1
        );
      }
      // Show all options available
      return options;
    },
    searchDesc() {
      if (this.criteria && this.availableOptionsResponsaveis.length === 0) {
        return "There are no tags matching your search criteria";
      }
      return "";
    },
  },
  mounted() {
    this.carregarCaracteristicas();
    this.carregarUsuarios();
    this.carregarAnalysis();
  },
  watch: {
    filtro_indicador: function () {
      this.carregarCaracteristicas();
    },
    filtro: function () {
      this.carregarCaracteristicas();
    },
    plano: function (plano) {
      if (plano.id != null) {
        this.carregarHistorico();
        this.form = plano;
        plano.ultima_acao.responsaveis.forEach((responsavel) => {
          responsavel.text = responsavel.user.name;
          responsavel.value = responsavel.user_id;
        });

        this.acao_selecionada = plano.ultima_acao.id;
      } else {
        this.form = this.form_limpo;
        this.form.relatorio_id = this.relatorio_id;
      }
    },
    acao_selecionada: function (acao_selecionada) {
      if (acao_selecionada) {
        this.acao = this.acoes.filter((acao) => acao.id == acao_selecionada)[0];

        if (this.acao) {
          this.acao.responsaveis.forEach((responsavel) => {
            responsavel.text =
              responsavel.user.name +
              "(" +
              responsavel.user.departamento.descricao +
              ")";
            responsavel.value = responsavel.user_id;
          });
          this.plano.ultima_acao = this.acao;
        }
      }
    },
  },
};
</script>

<style scoped>
.table-wrapper {
  max-height: 200px !important;
  overflow: auto;
}

.container-caracteristicas {
  height: 100%;
}

.text-radio {
  font-size: 14px;
}

.container-detalhes {
  max-height: 70vh !important;
  overflow: auto;
}
</style>
