<template>
  <b-modal id="modal-trend" size="xl" :title="$t('title.grafico-trend')" centered>
    <template #modal-header>
      <!-- Emulate built in modal header close button action -->
      <h5>{{ $t("title.grafico-trend") }}</h5>
      <p class="h4 btn m-0 p-0">
        <b-icon icon="x" @click="close()"></b-icon>
      </p>
    </template>

    <b-form v-if="grupo">
      <b-row align-v="stretch" class="mt-3">
        <b-col sm="3">
          <b-card bg-variant="light" :header="$t('header.selecionar-caracteristicas')" style="height:100%">
            <b-row align-h="between">
              <b-col>
                <b-button size="sm" @click="adicinarCaracteristicas" :disabled="selectedCaracteristicas.length == 0">
                  <b-icon icon="plus"></b-icon>
                  {{ $t("botoes.adicionar") }}
                </b-button>
              </b-col>
              <b-col cols="auto">
                <b-button-group size="sm">
                  <b-button :title="$t('botoes.selecionar-todos')">
                    <b-icon icon="check-square" aria-hidden="true" @click="selectAllRowsCaracteristicas"></b-icon>
                  </b-button>
                  <b-button :title="$t('botoes.limpar-selecao')">
                    <b-icon icon="square" aria-hidden="true" @click="clearSelectedCaracteristicas"></b-icon>
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
            <b-table v-if="caracteristicas.length > 0" :items="caracteristicas" :fields="fieldsCaracteristicas"
              select-mode="multi" responsive="sm" ref="selectableTableCaracteristicas" selectable bordered hover small
              center :current-page="currentPageCaracteristicas" :per-page="perPageCaracteristicas"
              class="table-controles text-center mt-2 table-wrapper" head-variant="light"
              @row-selected="onRowSelectedCaracteristicas">
            </b-table>

            <b-row>
              <b-col>
                <b-pagination v-model="currentPageCaracteristicas" :total-rows="caracteristicas.length"
                  :per-page="perPageCaracteristicas" align="fill" size="sm" class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card bg-variant="light" :header="grupo.descricao">
            <b-row>
              <b-col>
                <b-form-input v-model="grupo.titulo" type="text"></b-form-input>
              </b-col>
            </b-row>
          </b-card>

          <b-card no-body class="mt-3">
            <b-tabs card bg-variant="light">
              <b-tab v-for="(grafico, index) in grupo.graficos" :key="grafico.id" :title="grafico.descricao"
                @click="updateTabIndex(index)">
                <b-row align-h="between" class="mb-2" align-v="center">
                  <b-col>
                    <b-form-input v-model="grafico.titulo" type="text" size="sm"></b-form-input>
                  </b-col>
                  <b-col cols="auto">
                    <b-row align-v="center" align-h="end">
                      <b-col cols="1">
                        <b-form-checkbox value="1" v-model="grafico.status" name="check-button" switch>
                        </b-form-checkbox>
                      </b-col>
                      <b-col cols="auto">
                        <b-button variant="link">
                          <b-icon icon="gear-fill" class="button" @click="showModalConfigurarGrafico(index)" aria-hidden="true"></b-icon>
                        </b-button>
                      </b-col>

                    </b-row>
                  </b-col>
                </b-row>

                <b-row align-v="stretch">

                  <b-col sm="6">
                    <b-card bg-variant="light" :header="$t('header.caracteristicas')">
                      <b-button size="sm" @click="removerCaracteristicas"
                        :disabled="selected_selecionadosCaracteristicas.length == 0" class="mb-2">
                        <b-icon icon="trash"></b-icon>
                        {{ $t("botoes.remover") }}
                      </b-button>

                      <b-table v-if="grafico.caracteristicas.length > 0" :items="grafico.caracteristicas"
                        :fields="fieldsCaracteristicas" sticky-header select-mode="multi" responsive="sm"
                        ref="selectableTableSelecionadosCaracteristicas" selectable bordered hover small center
                        :current-page="currentPageSelecionadosCaracteristicas" :per-page="perPage"
                        class="table-controles text-center mt-2 table-wrapper" head-variant="light"
                        @row-selected="onRowSelectedSelecionadosCaracteristicas"></b-table>
                    </b-card>
                  </b-col>

                  <b-col sm="6">
                    <b-card bg-variant="light" :header="$t('header.controles')" style="height:100%">

                      <b-button size="sm" @click="removerControles" :disabled="selected_selecionados.length == 0"
                        class="mb-2">
                        <b-icon icon="trash"></b-icon>
                        {{ $t("botoes.remover") }}
                      </b-button>

                      <b-table v-if="grafico.controles.length > 0" :items="grafico.controles" :fields="fields"
                        sticky-header select-mode="multi" responsive="sm" ref="selectableTableSelecionados" selectable
                        bordered hover small center :current-page="currentPageSelecionados" :per-page="perPage"
                        class="table-controles text-center mt-2 table-wrapper" head-variant="light"
                        @row-selected="onRowSelectedSelecionados"></b-table>

                    </b-card>
                  </b-col>

                </b-row>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
        <b-col sm="3">
          <b-card bg-variant="light" :header="$t('header.selecionar-controles')" style="height:100%">
            <b-row align-h="between">
              <b-col>
                <b-button size="sm" @click="adicinarControles" :disabled="selected.length == 0">
                  <b-icon icon="plus"></b-icon>
                  {{ $t("botoes.adicionar") }}
                </b-button>
              </b-col>
              <b-col cols="auto">
                <b-button-group size="sm">
                  <b-button :title="$t('botoes.selecionar-todos')">
                    <b-icon icon="check-square" aria-hidden="true" @click="selectAllRows"></b-icon>
                  </b-button>
                  <b-button :title="$t('botoes.limpar-selecao')">
                    <b-icon icon="square" aria-hidden="true" @click="clearSelected"></b-icon>
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
            <b-table v-if="controles.length > 0" :items="controles" :fields="fields" select-mode="multi" responsive="sm"
              ref="selectableTable" selectable bordered hover small center :current-page="currentPage"
              :per-page="perPage" class="table-controles text-center mt-2 table-wrapper" head-variant="light"
              @row-selected="onRowSelected">
              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>

              <template v-slot:cell(excluir)="row">
                <b-icon icon="trash-fill" variant="danger" @click="excluir(row.item.cod)"></b-icon>
              </template>
            </b-table>

            <b-row>
              <b-col>
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>

    <b-alert show variant="danger" v-if="menssagem" class="mt-3">
      <ul v-if="menssagem">
        <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
      </ul>
    </b-alert>

    <template #modal-footer>
      <div class="w-100">
        <b-row align-v="center">
          
          <b-col cols="12" md="12">

            <b-button v-if="!carregando" type="submit" variant="danger" class="float-right" @click="deletar()">
              {{ $t("botoes.deletar") }}
            </b-button>

            <b-button v-if="!carregando" type="submit" variant="primary" class="float-right mr-3" @click="salvar()">
              {{ $t("botoes.salvar") }}
            </b-button>

            <b-button variant="primary" class="float-right" v-if="carregando" disabled>
              <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
              {{ $t("botoes.carregando") }}
            </b-button>

            <b-button v-if="!carregando" type="submit" variant="primary" class="float-right mr-3" @click="gerarGrafico()">
              {{ $t("botoes.gerar-graficos") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
    <!-- <modal-configurar-grupo :grupo="grupo" /> -->
    <trend-grafico-configuracao :grafico="grupo.graficos[indiceGraficoSelecionado]" v-if="grupo"/>
    <modal-carregando-geral />
  </b-modal>


</template>

<script>

import ModalConfigurarGrupo from "@/components/modal/TrendGrupo.vue";
import ModalCarregandoGeral from "@/components/modal/CarregandoGeral.vue";
import TrendGraficoConfiguracao from './TrendGraficoConfiguracao.vue';

export default {
  props: {
    grupo: null,
  },
  components: {
    ModalCarregandoGeral,
    ModalConfigurarGrupo,
    TrendGraficoConfiguracao
  },
  data() {
    return {
      optionsCaracteristicas: [],
      caracteristica_selecionada: null,
      optionsCaracteristicasAtivas: [],
      caracteristica_ativa_selecionada: null,
      tabIndex: 0,
      show: true,
      menssagem: null,
      carregando: null,

      controles: [],
      controles_selecionados: [],

      totalRows: 1,
      currentPage: 1,
      currentPageSelecionados: 1,
      perPage: 15,
      fields: [{key:"controle_id", label:"cod"}, "data"],
      selected: [],
      selected_selecionados: [],

      caracteristicas: [],
      caracteristicas_selecionados: [],

      totalRowsCaracteristicas: 1,
      currentPageCaracteristicas: 1,
      currentPageSelecionadosCaracteristicas: 1,
      perPageCaracteristicas: 15,
      fieldsCaracteristicas: ["elemento", "ax"],
      selectedCaracteristicas: [],
      selected_selecionadosCaracteristicas: [],
      
      indiceGraficoSelecionado:0
    };
  },
  methods: {
    showModalConfigurarGrafico(indice) { 
      this.indiceGraficoSelecionado = indice;     
      this.$bvModal.show("modal-trend-grafico-configuracao");
    },
    gerarGrafico(){
      this.$emit("gerarGrafico", this.grupo.id);
      this.close()
    },
    updateTabIndex(index) {
      this.tabIndex = index
    },
    carregarCaracteristicas() {
      this.$bvModal.show("modal-carregando-geral");
      this.$http
        .get("api/relatorio/caracteristicas/" + this.grupo.relatorio_id)
        .then((response) => {
          this.caracteristicas = [];
          response.data.forEach((caract) => {
            this.caracteristicas.push({
              elemento: caract.ponto.toUpperCase(),
              ax: caract.ax.toUpperCase(),
            });
          });
          this.$bvModal.hide("modal-carregando-geral");
        })
        .catch((errors) => {
          console.log(errors);
          this.$bvModal.hide("modal-carregando-geral");
        });
    },
    carregarControles() {
      this.carregando = true;
      this.menssagem = null;
      this.controles = [];

      this.$http
        .get("api/relatorio/controles/" + this.grupo.relatorio_id)
        .then((response) => {
          this.controles_bd = response.data;
          this.controles = [];
          this.controles_bd.forEach((controle) => {
            const d = new Date(controle.data).toLocaleDateString("pt-br");
            const h = new Date(controle.data).toLocaleTimeString("pt-br");
            this.controles.push({
              ativo: controle.ativo ? true : false,
              controle_id: controle.id,
              data: d + " " + h,
            });
          });

          this.totalRows = this.controles.length;
          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response;
          this.carregando = false;
        });
    },
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t("toast.operacao-realizada-com-sucesso"), {
        title: this.$t("toast.sucesso"),
        variant: variant,
        solid: true,
      });
    },
    close() {
      this.$bvModal.hide("modal-trend");
    },
    adicinarControles() {
      this.selected.forEach((controle) => {
        this.controles_selecionados.push(controle);
        this.grupo.graficos[this.tabIndex].controles.push(controle);
      });
      this.clearSelected();
    },
    removerControles() {
      this.selected_selecionados.forEach((controle) => {
        this.grupo.graficos[this.tabIndex].controles = this.grupo.graficos[this.tabIndex].controles.filter(
          (controle_selecionado) => controle_selecionado.controle_id != controle.controle_id
        );
      });
    },
    adicinarCaracteristicas() {
      this.selectedCaracteristicas.forEach((caracteristica) => {
        this.caracteristicas_selecionados.push(caracteristica);
        this.grupo.graficos[this.tabIndex].caracteristicas.push(caracteristica);
      });
      this.clearSelectedCaracteristicas();
    },
    removerCaracteristicas() {
      this.selected_selecionadosCaracteristicas.forEach((caracteristica) => {
        this.grupo.graficos[this.tabIndex].caracteristicas = this.grupo.graficos[this.tabIndex].caracteristicas.filter(
          caracteristica_selecionado => {
            return caracteristica_selecionado.elemento != caracteristica.elemento || caracteristica_selecionado.ax != caracteristica.ax
          }
        )
      });
    },

    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    onRowSelectedSelecionados(items) {
      this.selected_selecionados = items;
    },

    onRowSelectedCaracteristicas(items) {
      this.selectedCaracteristicas = items;
    },
    selectAllRowsCaracteristicas() {
      this.$refs.selectableTableCaracteristicas.selectAllRows();
    },
    clearSelectedCaracteristicas() {
      this.$refs.selectableTableCaracteristicas.clearSelected();
    },
    onRowSelectedSelecionadosCaracteristicas(items) {
      this.selected_selecionadosCaracteristicas = items;
    },
    salvar() {
      this.menssagem = null;
      this.carregando = true;

      let config = {
        headers: {
          Accept: "application/json",
        },
      };

      this.$http
        .patch('api/trend-grupo/' + this.grupo.id, this.grupo, config)
        .then((response) => {
          this.makeToast("success");
          this.carregando = false;
          this.$emit("listaMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    deletar(){
    let confirmacao = confirm(
        this.$t('confirmacao.tem-certeza-que-deseja-remover-esse-apresentacao')
      );

      if (!confirmacao) {
        return false;
      }

      let formData = new FormData();
      formData.append("_method", "delete");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post(
          'api/trend-grupo/' + this.grupo.id,
          formData,
          config
        )
        .then((response) => {
          this.makeToast("success");
          this.$bvModal.hide("modal-trend");
          this.carregando = false;
          this.$emit("listaMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
  },
  },
  
  computed: {
    ano: {
      get() {
        return this.$store.state.ano;
      },
    },
    semana: {
      get() {
        return this.$store.state.semana;
      },
    },
  },
  mounted() {
    if (this.grupo) {
      this.carregarCaracteristicas();
      this.carregarControles();
    }
  },
  watch: {
    grupo: function (grupo) {
      if (grupo) {
        this.carregarCaracteristicas();
        this.carregarControles();
      }
    },
  },
};
</script>

<style scoped>
.table-wrapper {
  font-size: 12px !important;
}

.container-caracteristicas {
  height: 100%;
}

.text-radio {
  font-size: 14px;
}
</style>