<template>
  <div class="card">
    <table class="text-center table-bordered table-condensed">
      <tr>
        <th>
          <img :src="'../assets/simbol/' + elemento.tipo_elemento.imagem" width="24" />
        </th>
        <th colspan="3">{{ elemento.descricao }}</th>
        <th colspan="2">AUTOMARB</th>
      </tr>
      <tr>
        <th>Ax</th>
        <th>{{ indicadorCp }}</th>
        <th>{{ indicadorCp }}K</th>
        <th>XMED</th>
        <th>RANGE</th>
        <th>TOLERANCE</th>
      </tr>

      <tr v-for="eixo in eixosElemento" :key="eixo" style="font-weight: bold">
        <td :style="elemento['calculo' + eixo] | calculo">{{ eixo.toUpperCase() }}</td>
        <td :style="'background-color:' + elemento[eixo + 'cpcor'] + (elemento[eixo + 'cpcor'] == 'red' ?  '; color: white' : '')">{{ elemento[eixo + 'cp'] | casas(3) }}</td>
        <td :style="'background-color:' + elemento[eixo + 'cpkcor'] + (elemento[eixo + 'cpkcor'] == 'red' ?  '; color: white' : '')">{{ elemento[eixo + 'cpk'] | casas(3) }}</td>
        <td :style="'background-color:' + elemento[eixo + 'medcor'] + (elemento[eixo + 'medcor'] == 'red' ?  '; color: white' : '')">{{ elemento[eixo + 'med'] | casas(3) }}</td>
        <td>{{ elemento[eixo + 'range'] | casas(3) }}</td>
        <td>
          {{ elemento['li' + eixo + 'tol'] | casas(3) }} | {{ elemento['ls' + eixo + 'tol'] | casas(3) }}
        </td>
      </tr>

    </table>
  </div>
</template>

<script>
export default {
  props: {
    elemento: {
      type: Object,
    },
    indicador: null
  },
  data() {
    return { eixos: ['x', 'y', 'z', 't', 'd', 'l', 'w', 'p', 'a'], }
  },
  computed: {
    eixosElemento() {
      var eixosElemento = [];
      this.eixos.forEach(eixo => {
        if (this.elemento['exibir' + eixo]) {
          eixosElemento.push(eixo)
        }
      });
      return eixosElemento;
    },
    indicadorCp() {
      return this.indicador == "cp" ? "CP" : "PP";
    },
  },
};
</script>

<style scoped>
.table-condensed {
  font-size: 12px;
  padding: 0;
}

tr td,
th {
  padding: 2px !important;
}
</style>