<template>
    <div v-if="paginas">
        <div v-for="(pagina, index) in paginas" :key="index" :ref="'pagina_trend' + index" class="pagina-padrao">
            <b-row class="text-center">
                <b-col class=" border-bottom m-0 p-0">
                    <h3 class="m-0 p-0">Control Chart</h3>
                    <b>{{ pagina.relatorio.componente.descricao }} - {{ pagina.relatorio.componente.desenho }}</b><br>
                    <b>{{ pagina.titulo }}</b>
                </b-col>
            </b-row>
            <b-row align-h="center" class="my-1">
                <img src="@/assets/trend-legend.png" alt="legend">
            </b-row>
            <template v-if="pagina.graficos.length == 1">
                <div v-for="(grafico, indexGrafico) in pagina.graficos" :key="indexGrafico" class="mt-1">
                    <b-row class="bold" v-if="grafico.trend">
                        <b-col>
                            <grafico-trend-component :trend="grafico.trend" :title="grafico.titulo" height="310"
                                graficos="1" :param="{
                                    eixo_vertical_automatico: grafico.eixo_vertical_automatico,
                                    eixo_vertical_maximo: grafico.eixo_vertical_maximo,
                                    eixo_vertical_minimo: grafico.eixo_vertical_minimo,
                                    eixo_vertical_divisoes: grafico.eixo_vertical_divisoes,
                                    eixo_vertical_divisoes: grafico.eixo_vertical_divisoes,
                                }" :indicador="grafico.indicador" />
                        </b-col>
                    </b-row>
                </div>
            </template>

            <template v-if="pagina.graficos.length == 2">
                <div v-for="(grafico, indexGrafico) in pagina.graficos" :key="indexGrafico" class="mt-1">
                    <b-row class="bold" v-if="grafico.trend">
                        <b-col>
                            <grafico-trend-component :trend="grafico.trend" :title="grafico.titulo" height="180"
                                graficos="2" :param="{
                                    eixo_vertical_automatico: grafico.eixo_vertical_automatico,
                                    eixo_vertical_maximo: grafico.eixo_vertical_maximo,
                                    eixo_vertical_minimo: grafico.eixo_vertical_minimo,
                                    eixo_vertical_divisoes: grafico.eixo_vertical_divisoes,
                                    eixo_vertical_divisoes: grafico.eixo_vertical_divisoes,
                                }" :indicador="grafico.indicador" />
                        </b-col>
                    </b-row>
                </div>
            </template>

            <template v-if="pagina.graficos.length == 3">
                <div v-for="(grafico, indexGrafico) in pagina.graficos" :key="indexGrafico" class="mt-1">
                    <b-row class="bold" v-if="grafico.trend">
                        <b-col>
                            <grafico-trend-component :trend="grafico.trend" :title="grafico.titulo" height="110"
                                graficos="3" :param="{
                                    eixo_vertical_automatico: grafico.eixo_vertical_automatico,
                                    eixo_vertical_maximo: grafico.eixo_vertical_maximo,
                                    eixo_vertical_minimo: grafico.eixo_vertical_minimo,
                                    eixo_vertical_divisoes: grafico.eixo_vertical_divisoes,
                                    eixo_vertical_divisoes: grafico.eixo_vertical_divisoes,
                                }" :indicador="grafico.indicador" />
                        </b-col>
                    </b-row>
                </div>
            </template>

        </div>
    </div>
</template>

<script>
import * as htmlToImage from "html-to-image";
import GraficoTrendComponent from "@/components/GraficoTrendComponent.vue";
export default {
    data() {
        return {
            paginas: null,
        }
    },
    components: {
        GraficoTrendComponent
    },
    methods: {
        carregarGrupo(grupo) {
            this.paginas = null;
            this.$http
                .get("api/trend-grupo/" + grupo)
                .then((response) => {
                    this.paginas = response.data;
                    this.$emit('carregouGrupos', this.paginas.length)
                    setTimeout(() => {
                        this.savePng();
                    }, 1200)
                })
                .catch((errors) => {
                    this.$emit('error', errors)
                    console.log(errors);
                });
        },
        savePng() {
            this.paginas.forEach((pagina, i) => {
                setTimeout(() => {
                    const capture = this.$refs['pagina_trend' + i][0];
                    htmlToImage
                        .toPng(capture)
                        .then((dataUrl) => {
                            var img = new Image();
                            img.src = dataUrl;
                            this.download(img.src);
                            this.$emit('gerou')
                        })
                        .catch((error) => {
                            console.error("oops, something went wrong!", error);
                        });
                }, 1200);
            });
        },
        download(file) {
            var a = document.createElement("a");
            a.href = file;
            a.download = this.paginas[0].relatorio.componente.desenho+ "_trend.png";
            a.click();
        },
    },
    mounted() {
        this.url = process.env.VUE_APP_ROOT_API;
    },

}
</script>


<style scoped>
.pagina-padrao {
    border-radius: 1rem;
    width: 1035px;
    height: 652px;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 1rem;
    border: 2px solid gray;
}

.grafic-area {
    height: 100px;
}

.red {
    color: red;
}

.blue {
    color: blue;
}

.bold {
    font-weight: bold;
    font-size: small;
}
</style>