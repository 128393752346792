<template>
  <div id="app">
    <router-view />
    <ToastCarregandoControles v-if="$store.state.carregandoControleWinfas"/>
  </div>
</template>

<script>
import ToastCarregandoControles from './components/ToastCarregandoControles.vue';
export default {
  components: {
    ToastCarregandoControles,
  },  
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  position: relative;
  background-color: #f1f1f1;
  min-height: 100vh;
}

.pdf-preview {
  background-color: #FFFFFF;
  position: fixed !important;
  padding-bottom: 0px !important;
}

.pdf-preview button {
  top: 15px !important;
  left: 15px !important;
  background: #2d3e77 !important;
}
</style>
