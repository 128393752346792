<template>
  <div class="relatorio-boleado">
    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
      :paginate-elements-by-height="700" :filename="'Controle Dimensional Boleado ' +
        componente.descricao +
        '_' +
        componente.desenho
        " :pdf-quality="2" :manual-pagination="true" pdf-format="a4" pdf-orientation="landscape" pdf-content-width="100%"
      ref="html2Pdf" @startPagination="hasStartedGeneration()" @beforeDownload="hasGenerated($event)"
      :html-to-pdf-options="htmlToPdfOptions">
      <section slot="pdf-content">
        <div class="pagina-css">

          <div v-on:scroll.passive="scrollPagina" v-b-scrollspy v-for="(pagina, index) in resultado.paginas"
            :key="pagina.id" :ref="'pagina_boleado_imp' + index" class="mb-3 pagina">
            <section class="pdf-item">
              <b-row class="relatorio-cabecalho mb-2" v-if="index == 0">
                <b-col cols="2" class="">
                  <b-row align-v="center" align-h="center" class="mt-3 ml-1"
                    v-if="$store.state.auth.user.user.organizacao.imagem"><img
                      :src="$store.state.auth.user.user.organizacao.imagem | urlImagem" width="150" /></b-row>
                  <b-row align-v="center" align-h="center" class="mt-3 ml-1"><img :src="'../assets/logo.png'"
                      width="150" /></b-row>
                </b-col>
                <b-col cols="6">
                  <box-indicadores :fechamento="resultado.tabela.fechamento" :descricao="componente.descricao"
                    :desenho="componente.desenho" v-if="componente" :indicador="indicador" />
                </b-col>
                <b-col cols="2" class="p-0">
                  <root-cause-bar ref="rootcausebar" v-if="resultado.plano"
                    :analysis="resultado.plano.fechamento_analysis" /></b-col>
                <b-col cols="2" class="mt-0">
                  <b-img width="120" :src="componente.familia.imagem | urlImagem" />
                </b-col>
              </b-row>

              <b-overlay :show="carregando" rounded="sm" opacity="1">
                <div :class="index == 0 ? 'pagina1 border' : 'pagina border'" :id="'paginaboleado' + pagina.id"
                  :ref="'pagina' + pagina.id" :style="{
                    'background-image':
                      'url(' + url + '/organization/' + user.tenant_id + '/api/storage/' + pagina.imagem + ')',
                  }">
                  <v-stage :config="index == 0 ? stage1Size : stageSize" class="kanva" :ref="'stage' + pagina.id">
                    <v-layer>
                      <v-line v-for="line in avaliableLinhas(pagina.id)" :key="line.id" :config="{
                        stroke: 'black',
                        strokeWidth: 1,
                        points: line.points,
                      }" />
                    </v-layer>
                  </v-stage>
                  <elemento-componente v-for="elemento in pagina.elementos" :key="'caixa' + elemento.id"
                    :elemento="elemento" :plano="resultado.plano.plano" tipo="boleado" :style="'left:' +
                      elemento.posicaocaixal +
                      'px; top:' +
                      elemento.posicaocaixat +
                      'px;'
                      ">
                  </elemento-componente>

                  <ponto-componente v-for="ponto in pagina.elementos" :key="'ponto' + ponto.id" :ponto="ponto"
                    tipo="boleado" :style="'left:' +
                      ponto.posicaopontol +
                      'px; top:' +
                      ponto.posicaopontot +
                      'px;'
                      ">
                  </ponto-componente>
                </div>
              </b-overlay>
              <b-row align-v="end" class="align-self-end paginacao">
                <b-col class="text-center">{{ index + 1 }}/{{ resultado.paginas.length }}</b-col>
              </b-row>
            </section>
            <div class="html2pdf__page-break" />
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import ElementoComponente from "@/components/Elemento.vue";
import PontoComponente from "@/components/Ponto.vue";
import BoxIndicadores from "@/components/BoxIndicadores.vue";
import LeaderLine from "leader-line-vue";
import RiskLegenda from "@/components/RiskLegenda.vue";
import RiskBar from "@/components/RiskBar.vue";
import RootCauseBar from "@/components/RootCauseBar.vue";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "RelatorioBoleado",
  props: {
    componente: null,
    resultado: null,
    paginas: null,
    indicador: null
  },
  components: {
    ElementoComponente,
    PontoComponente,
    BoxIndicadores,
    RiskLegenda,
    RiskBar,
    RootCauseBar,
    VueHtml2pdf,
  },
  data() {
    return {
      printGenerateProgress: false,
      htmlToPdfOptions: {
        filename:
          "Controle Dimensional Boleado " +
          this.componente.descricao +
          "_" +
          this.componente.desenho,
        image: {
          type: "jpeg",
          quality: 1,
        },
        pagebreak: {
          mode: ['avoid-all', "css", "legacy"],
          afterElement: '.html2pdf__page-break'
        },
        enableLinks: false,
        html2canvas: {
          scale: 1,
          useCORS: true,
        },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "landscape",
        },
      },
      url: null,
      windowTop: 0,
      carregando: false,
      menssagem: null,
      connections: [],
      stageSize: {
        width: 1035,
        height: 652,
      },
      stage1Size: {
        width: 1035,
        height: 507,
      },
    };
  },
  methods: {
    hasStartedGeneration() {
      this.printGenerateProgress = true;
      this.$emit("alterStatusPdfGenerate", true);
    },
    hasGenerated() {
      this.printGenerateProgress = false;
      this.$emit("alterStatusPdfGenerate", false);
    },
    avaliableLinhas(p_id) {
      return this.connections.filter((c) => c.pagina == p_id);
    },
    carregarRelatorio() {
      this.carregando = true;
      this.menssagem = null;

      this.connections = [];

      if (this.$refs.riskbar) {
        this.$refs.riskbar[0].updateGrafico();
      }

      if (this.$refs.rootcausebar) {
        this.$refs.rootcausebar[0].updateGrafico();
      }

      setTimeout(() => {
        this.carregar();
      }, 300);
    },
    carregar() {
      this.carregando = false;
      this.connections = [];

      for (const keyPagina in this.resultado.paginas) {
        var elementos = this.resultado.paginas[keyPagina].elementos;

        for (const key in elementos) {
          var pontoRec = document
            .getElementById("pontoboleado" + elementos[key].id)
            .getBoundingClientRect();
          var caixaRec = document
            .getElementById("caixaboleado" + elementos[key].id)
            .getBoundingClientRect();

          var pontox = parseFloat(
            document
              .getElementById("pontoboleado" + elementos[key].id)
              .style.left.slice(0, -2)
          );
          var pontoy = parseFloat(
            document
              .getElementById("pontoboleado" + elementos[key].id)
              .style.top.slice(0, -2)
          );
          var caixax = parseFloat(
            document
              .getElementById("caixaboleado" + elementos[key].id)
              .style.left.slice(0, -2)
          );
          var caixay = parseFloat(
            document
              .getElementById("caixaboleado" + elementos[key].id)
              .style.top.slice(0, -2)
          );

          this.connections.push({
            id: "boleadolinha" + elementos[key].id,
            pagina: this.resultado.paginas[keyPagina].id,
            points: [
              pontox + pontoRec.width / 2,
              pontoy + pontoRec.height / 2,
              caixax + caixaRec.width / 2,
              caixay + caixaRec.height / 2,
            ],
          });
        }
      }
    },
    onScroll(e) {
      this.windowTop = window.top.scrollY;
    },
  },
  mounted() {
    this.carregarRelatorio();
    window.addEventListener("scroll", this.onScroll);
    this.url = process.env.VUE_APP_ROOT_API;
  },
  watch: {
    resultado: {
      handler() {
        this.carregarRelatorio();
      },
      deep: true,
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
  },

};
</script>

<style scoped>
.pagina-css {
  margin-top: -120px;
  margin-left: 20px;
}

.container-pagina {
  /* overflow-y: auto; */
}

.pagina {
  width: 1035px;
  height: 652px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.pagina1 {
  width: 1035px;
  height: 507px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-size: cover;
}

.container-indicadores {
  height: 140px;
}

.container-risk {
  height: 120px;
}

.kanva {
  position: absolute;
  padding: 0px;
  margin: 0px;
}

.relatorio-cabecalho {
  width: 1050px;
}

.paginacao {
  /* margin-top: -30px; */
  font-size: 10px;
}
</style>