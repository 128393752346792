<template>
    <header id="header" class="fixed-top" :class="this.scrollPosition > 100 ? 'header-scrolled' : ''">
        <div class="container d-flex align-items-center justify-content-between">
            <h1 class="logo me-auto"><router-link to="#hero">AUTOMARB</router-link></h1>
            <!-- Uncomment below if you prefer to use an image logo -->
            <!-- <a href="index.html" class="logo me-auto"><img src="@/assets/img/logo.png" alt="" class="img-fluid"></a> -->

            <nav id="navbar" :class="this.showMobileMenu ? 'navbar-mobile' : 'navbar'">
                <ul>
                    <li @click="hideMenu" >
                        <router-link class="nav-link active" to="#hero">{{ $t('site-menu.home') }}</router-link>
                    </li>
                    <li @click="hideMenu">
                        <router-link  class="nav-link" to="#about">{{ $t('site-menu.about') }}</router-link>
                    </li>
                    <li @click="hideMenu">
                        <router-link  class="nav-link" to="#services">{{ $t('site-menu.services') }}</router-link>
                    </li>                    
                    <li @click="hideMenu">
                        <router-link  class="nav-link" to="#pricing">{{ $t('site-menu.pricing') }}</router-link>
                    </li>                    
                    <li @click="hideMenu">
                        <router-link class="nav-link" to="#contact">{{ $t('site-menu.contact') }}</router-link>
                    </li>
                    <li @click="hideMenu">
                        <router-link  class="getstarted" to="/login">{{ $t('site-menu.login') }}</router-link>
                    </li>
                    <!-- <li @click="hideMenu">
                        <router-link  class="getstarted" to="/register">{{ $t('site-menu.get-started') }}</router-link>
                    </li> -->
                    

                    <language-input :showMobileMenu="showMobileMenu" />
                    
                </ul>

                <i class="bi bi-list mobile-nav-toggle" @click="showMenu">
                    <b-icon icon="list" v-if="!showMobileMenu"></b-icon>
                    <b-icon icon="x-lg" v-else></b-icon>
                </i>
            </nav>
            <!-- .navbar -->
        </div>
    </header>
</template>

<script>
import LanguageInput from "@/components/site/LanguageInput.vue";
export default {
    components: { LanguageInput },
    name: "Header",
    data() {
        return {
            showMobileMenu: false,
            scrollPosition: 0,
        };
    },
    methods: {
        showMenu() {
            this.showMobileMenu = !this.showMobileMenu;
        },
        hideMenu() {
            this.showMobileMenu = false;
        },
        handleScroll(event) {
            this.scrollPosition = window.scrollY;
        },
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>

<style>

</style>