<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center">

    <div class="container">
      <div class="row">
        <div class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
          data-aos="fade-up" data-aos-delay="200">
          <h1>{{ $t('welcome-title') }}</h1>
          <h2>{{ $t('welcome-subtitle') }}</h2>
          <div class="d-flex justify-content-center justify-content-lg-start">
            <a href="/register" class="btn-get-started scrollto">{{ $t('site-menu.get-started') }}</a>
            <a href="#" @click="showModalVideo()" class="glightbox btn-watch-video">
              <i><b-icon icon="play-circle"></b-icon></i>
              <span>{{ $t('hero.watch-video') }}</span></a>
          </div>
          <!-- <h5>{{ $t('register.subtitle') }}</h5> -->
        </div>
        <div class="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
          <img src="@/assets/img/hero-img.png" class="img-fluid animated" alt="">
        </div>
      </div>
    </div>

    <modal-video />

  </section><!-- End Hero -->
</template>

<script>
import ModalVideo from './ModalVideo.vue';
export default {
  components: { ModalVideo },
  methods: {
    showModalVideo() {
      this.$bvModal.show("modal-video");
    },
  }

}
</script>

<style>

</style>