<template>
  <b-modal
    id="modal-apresentacao"
    size="lg"
    :title="$t('title.automarb-apresentacao')"
    centered
  >
    <template #modal-header>
      <!-- Emulate built in modal header close button action -->
      <h5>{{ $t("title.automarb-apresentacao") }}</h5>
      <p class="h4 btn"><b-icon icon="x" @click="close()"></b-icon></p>
    </template>
    <b-form v-if="show">
      <b-row>
        <b-col sm="6">
          <b-card bg-variant="light" header="Imprimir">
            <b-form-checkbox v-model="imprimir.print_capa">{{
              $t("modal.capa")
            }}</b-form-checkbox>
            <b-form-checkbox v-model="imprimir.print_workflow"
              >WorkFlow</b-form-checkbox
            >
            <b-form-checkbox v-model="imprimir.print_cpk"
              >{{ $t("modal.fechamento") }} Cp&Cpk / Pp&Ppk</b-form-checkbox
            >
            <b-form-checkbox v-model="imprimir.print_conformidade">{{
              $t("modal.fechamentoConformidade")
            }}</b-form-checkbox>
            <b-form-checkbox v-model="imprimir.print_boleado">{{
              $t("modal.relatorioBoleado")
            }}</b-form-checkbox>
            <b-form-checkbox v-model="imprimir.print_relatorio">{{
              $t("modal.relatorioConvecional")
            }}</b-form-checkbox>
            <b-form-checkbox v-model="imprimir.print_individual">{{
              $t("modal.relatorioIndividual")
            }}</b-form-checkbox>
            <b-form-checkbox v-model="imprimir.print_plano">{{
              $t("modal.planoDeAcao")
            }}</b-form-checkbox>
            <b-form-checkbox v-model="imprimir.print_risk">{{
              $t("modal.risk")
            }}</b-form-checkbox>
            <b-form-checkbox v-model="imprimir.print_image">{{
              $t("modal.image")
            }}</b-form-checkbox>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-form-group
            id="input-group-2"
            :label="$t('label.imagens')"
            label-for="input-2"
            v-if="imprimir.print_image"
          >
            <b-form-file
              accept="image/*"
              v-model="imprimir.print_anexo"
              multiple
              :placeholder="
                $t('placeholder.escolha-as-imagens-ou-solte-o-aqui')
              "
              :drop-placeholder="$t('drop-placeholder.solte-a-imagem-aqui')"
            ></b-form-file>
          </b-form-group>
          <b-button
            variant="primary"
            class="float-right"
            v-if="carregando"
            disabled
          >
            <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
            {{ $t("botoes.gerandoApresentacao") }}
          </b-button>

          <b-button
            v-else
            block
            variant="primary"
            class="float-right"
            @click="gerarApresentacao()"
            ><b-icon icon="file-earmark-slides"></b-icon>
            {{ $t("botoes.gerarApresentacaoDoRelatorio") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <b-alert show variant="danger" v-if="menssagem">
      <ul v-if="menssagem">
        <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
      </ul>
    </b-alert>

    <template #modal-footer>
      <div class="w-100">
        <b-row align-v="center">
          <b-col cols="12" md="8">
            <b-row align-v="center" align-h="center">
              <b-col
                ><p class="h1 ml-3">
                  <b-icon
                    icon="exclamation-octagon"
                    variant="primary"
                  ></b-icon></p
              ></b-col>
              <b-col cols="12" md="10">
                <p class="pt-2">
                  <span class="text-primary"> {{ $t("modal.importante") }}</span
                  ><br />
                  {{ $t("modal.preenchaTodosOsDadosCorretamente") }}
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" md="4">
            <b-button
              variant="primary"
              class="float-right"
              v-if="carregando"
              disabled
            >
              <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
              {{ $t("botoes.carregando") }}
            </b-button>

            <b-button
              v-else
              type="submit"
              variant="primary"
              class="float-right"
              @click="close()"
            >
              {{ $t("botoes.fechar") }}
            </b-button>

            <b-button
              v-if="apresentacao.id"
              type="submit"
              variant="danger"
              class="float-right mr-2"
              @click="deletar()"
            >
              {{ $t("botoes.deletar") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    apresentacao: {
      type: Object,
      default: function () {
        return {
          id: null,
          descricao: null,
        };
      },
    },
  },
  data() {
    return {
      imprimir: {
        print_capa: true,
        print_cpk: true,
        print_workflow: true,
        print_conformidade: true,
        print_boleado: true,
        print_relatorio: true,
        print_plano: true,
        print_individual: true,
        print_risk: true,
        print_image: false,
        print_anexo: []
      },
      show: true,
      menssagem: null,
      carregando: null,
      url: "api/apresentacao",
    };
  },
  methods: {
    close() {
      this.$bvModal.hide("modal-apresentacao");
    },
    gerarApresentacao() {
      this.$emit("gerarApresentacao", this.imprimir);
      this.close();
    },
  },
  watch: {},
};
</script>

<style></style>
