var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.paginas)?_c('div',_vm._l((_vm.paginas),function(pagina,index){return _c('div',{key:index,ref:'pagina_trend' + index,refInFor:true,staticClass:"pagina-padrao"},[_c('b-row',{staticClass:"text-center"},[_c('b-col',{staticClass:" border-bottom m-0 p-0"},[_c('h3',{staticClass:"m-0 p-0"},[_vm._v("Control Chart")]),_c('b',[_vm._v(_vm._s(pagina.relatorio.componente.descricao)+" - "+_vm._s(pagina.relatorio.componente.desenho))]),_c('br'),_c('b',[_vm._v(_vm._s(pagina.titulo))])])],1),_c('b-row',{staticClass:"my-1",attrs:{"align-h":"center"}},[_c('img',{attrs:{"src":require("@/assets/trend-legend.png"),"alt":"legend"}})]),(pagina.graficos.length == 1)?_vm._l((pagina.graficos),function(grafico,indexGrafico){return _c('div',{key:indexGrafico,staticClass:"mt-1"},[(grafico.trend)?_c('b-row',{staticClass:"bold"},[_c('b-col',[_c('grafico-trend-component',{attrs:{"trend":grafico.trend,"title":grafico.titulo,"height":"310","graficos":"1","param":{
                                eixo_vertical_automatico: grafico.eixo_vertical_automatico,
                                eixo_vertical_maximo: grafico.eixo_vertical_maximo,
                                eixo_vertical_minimo: grafico.eixo_vertical_minimo,
                                eixo_vertical_divisoes: grafico.eixo_vertical_divisoes,
                                eixo_vertical_divisoes: grafico.eixo_vertical_divisoes,
                            },"indicador":grafico.indicador}})],1)],1):_vm._e()],1)}):_vm._e(),(pagina.graficos.length == 2)?_vm._l((pagina.graficos),function(grafico,indexGrafico){return _c('div',{key:indexGrafico,staticClass:"mt-1"},[(grafico.trend)?_c('b-row',{staticClass:"bold"},[_c('b-col',[_c('grafico-trend-component',{attrs:{"trend":grafico.trend,"title":grafico.titulo,"height":"180","graficos":"2","param":{
                                eixo_vertical_automatico: grafico.eixo_vertical_automatico,
                                eixo_vertical_maximo: grafico.eixo_vertical_maximo,
                                eixo_vertical_minimo: grafico.eixo_vertical_minimo,
                                eixo_vertical_divisoes: grafico.eixo_vertical_divisoes,
                                eixo_vertical_divisoes: grafico.eixo_vertical_divisoes,
                            },"indicador":grafico.indicador}})],1)],1):_vm._e()],1)}):_vm._e(),(pagina.graficos.length == 3)?_vm._l((pagina.graficos),function(grafico,indexGrafico){return _c('div',{key:indexGrafico,staticClass:"mt-1"},[(grafico.trend)?_c('b-row',{staticClass:"bold"},[_c('b-col',[_c('grafico-trend-component',{attrs:{"trend":grafico.trend,"title":grafico.titulo,"height":"110","graficos":"3","param":{
                                eixo_vertical_automatico: grafico.eixo_vertical_automatico,
                                eixo_vertical_maximo: grafico.eixo_vertical_maximo,
                                eixo_vertical_minimo: grafico.eixo_vertical_minimo,
                                eixo_vertical_divisoes: grafico.eixo_vertical_divisoes,
                                eixo_vertical_divisoes: grafico.eixo_vertical_divisoes,
                            },"indicador":grafico.indicador}})],1)],1):_vm._e()],1)}):_vm._e()],2)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }