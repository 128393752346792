<template>
  <div class="home pt-3">
    <b-breadcrumb>
      <b-breadcrumb-item active class="align-middle">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
        {{ $t("breadcrumb.familias") }}
      </b-breadcrumb-item>
      <b-button
        variant="secondary"
        @click="showModal()"
        class="ml-auto"
        size="sm"
        v-if="user.permissao == 'acesso_total' || user.permissao == 'relatorio'"
      >
        <b-icon-plus />{{ $t("botoes.novaFamilia") }}
      </b-button>
    </b-breadcrumb>

    <SelecaoPeriodo :mostrarTurno="true" :mostrarVisualizacao="false" />

    <b-container fluid class="mt-3">
      <b-row align-h="start" v-if="!carregando">
        <div class="p-3" v-for="familia in familias" :key="familia.id">
          <router-link
            :to="'/familia/' + familia.id"
            style="text-decoration: none; color: inherit"
          >
            <b-card
              class="text-center px-3 pb-3"
              no-body
              @mouseover="isHovering = true"
              @mouseout="isHovering = false"
            >
              <b-row align-h="end" class="pv-0">
                <b-dropdown
                  size="sm"
                  variant="link"
                  no-caret
                  right
                  class="pv-0"
                  v-if="
                    user.permissao == 'acesso_total' ||
                    user.permissao == 'relatorio'
                  "
                >
                  <template #button-content>
                    <b-icon icon="three-dots"></b-icon>
                  </template>
                  <b-dropdown-item @click="showModalEditarFamilia(familia)">{{
                    $t("botoes.editar")
                  }}</b-dropdown-item>
                </b-dropdown>
              </b-row>
              <b-card-text class="title-card mt-2">
                {{ familia.descricao }}
              </b-card-text>
              <div
                class="lab__card"
                :style="
                  'background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)),url(' +
                  url +
                  '/' +
                  familia.imagem +
                  ') no-repeat center center /100%'
                "
              ></div>

              <container-analytics :familia_id="familia.id" v-if="familia.id" />
            </b-card>
          </router-link>
        </div>
      </b-row>
    </b-container>
    <modal-familia :familia="familia" @listaMudou="carregarLista()" />
    <modal-carregando-geral />
  </div>
</template>
<script>
import ModalFamilia from "@/components/modal/Familia.vue";
import ModalCarregandoGeral from "@/components/modal/CarregandoGeral.vue";
import SelecaoPeriodo from "@/components/SelecaoPeriodo.vue";
import ContainerAnalytics from "@/components/ContainerAnalytics.vue";
export default {
  components: {
    ModalFamilia,
    ModalCarregandoGeral,
    SelecaoPeriodo,
    ContainerAnalytics,
  },
  computed: {
    filter() {
      return (
        "?tipoPeriodo=" +
        this.$store.state.tipoPeriodo +
        "&ano=" +
        this.$store.state.ano +
        "&mes=" +
        this.$store.state.mes +
        "&semana=" +
        this.$store.state.semana +
        "&data=" +
        this.$store.state.data +
        "&turno=" +
        this.$store.state.turno
      );
    },
    user() {
      return this.$store.state.auth.user.user;
    },
    turno: {
      get() {
        return this.$store.state.turno;
      },
      set(value) {
        this.$store.commit("setTurno", value);
      },
    },
    tipoPeriodo: {
      get() {
        return this.$store.state.tipoPeriodo;
      },
      set(value) {
        this.$store.commit("setTipoPeriodo", value);
      },
    },
    mes: {
      get() {
        return this.$store.state.mes;
      },
      set(value) {
        this.$store.commit("setMes", value);
      },
    },
    semana: {
      get() {
        return this.$store.state.semana;
      },
      set(value) {
        this.$store.commit("setSemana", value);
      },
    },
    data: {
      get() {
        return this.$store.state.data;
      },
      set(value) {
        this.$store.commit("setData", value);
      },
    },
    ano: {
      get() {
        return this.$store.state.ano;
      },
      set(value) {
        this.$store.commit("setAno", value);
      },
    },
  },
  data() {
    return {
      isHovering: false,
      familia: {
        id: null,
        descricao: null,
      },
      url: null,
      familias: { data: [] },
      modalShow: false,
      carregando: false,
    };
  },
  methods: {
    showModalEditarFamilia(familia) {
      this.familia = familia;
      this.$bvModal.show("modal-familia");
    },
    showModal() {
      this.familia = {
        id: null,
        descricao: null,
      };
      this.$bvModal.show("modal-familia");
    },
    carregarLista() {
      if (this.carregando == false) {
        this.carregando = true;
        this.$bvModal.show("modal-carregando-geral");
        this.$http
          .get("api/familia")
          .then((response) => {
            this.familias = response.data;
            this.carregando = false;
            this.$bvModal.hide("modal-carregando-geral");
          })
          .catch((errors) => {
            console.log(errors);
            this.$bvModal.hide("modal-carregando-geral");
          });
      }
    },
  },
  mounted() {
    if (this.ano && this.semana) {
      this.carregarLista();
    }
    this.url = process.env.VUE_APP_ROOT_API;
  },
  watch: {
    tipoPeriodo: function () {
      this.carregarLista();
    },
    turno: function () {
      this.carregarLista();
    },
    data: function () {
      this.carregarLista();
    },
    mes: function () {
      this.carregarLista();
    },
    semana: function () {
      this.carregarLista();
    },
    ano: function () {
      this.carregarLista();
    },
  },
};
</script>

<style scoped>
.lab__card {
  height: 150px;
  width: 200px;
  padding: 0px;
  margin: 0px;
  object-fit: contain;
  transition: transform 0.45s;
  color: white;
}

.title-card {
  font-weight: bold;
  font-size: 16px;
}

.box-score-blue {
  background-color: #a9cbff;
  color: #1f284d;
  border-radius: 0.5rem;
  padding: 0.2rem;
  font-weight: bold;
}

.box-score-red {
  background-color: #ffb6b6;
  color: #732f2f;
  border-radius: 0.5rem;
  padding: 0.2rem;
  font-weight: bold;
}

.box-score-green {
  background-color: #a4d095;
  color: #2a4422;
  border-radius: 0.5rem;
  padding: 0.2rem;
  font-weight: bold;
}

.box-score-grey {
  background-color: #ececec;
  color: #464646;
  border-radius: 0.5rem;
  padding: 0.2rem;
  font-weight: bold;
}

.valor-atual {
  font-size: 22px;
}

.semana-atual {
  font-size: 10px;
  font-weight: 100;
  opacity: 0.7;
}

.valor-anterior {
  font-size: 12px;
}

.semana-anterior {
  font-size: 10px;
  font-weight: 100;
  opacity: 0.7;
}

.container-analytics {
  font-size: small;
  color: rgb(116, 116, 116);
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.container-analytics .analytics-red {
  padding: 0.2rem;
  background-color: rgb(255, 224, 224);
  color: rgb(255, 105, 105);
  border-radius: 0.5rem;
}

.container-analytics .analytics-blue {
  padding: 0.2rem;
  background-color: rgb(225, 232, 255);
  color: rgb(108, 118, 255);
  border-radius: 0.5rem;
}

.container-analytics .analytics-green {
  padding: 0.2rem;
  background-color: rgb(215, 255, 215);
  color: rgb(72, 172, 72);
  border-radius: 0.5rem;
}

.container-analytics .row-hoje {
  border: 1px solid #1f1f1f;
  border-radius: 0.5rem;
}

.container-analytics .row-ontem {
  font-size: x-small;
}

.container-analytics .row-ontem .col-ontem {
  padding: 0.2rem;
  border-radius: 0.5rem;
}

.col-semana {
  padding: 0px;
  font-size: x-small;
}
</style>
