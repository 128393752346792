<template>
  <div class="relatorio mt-4">

    <b-breadcrumb>
      <b-breadcrumb-item :to="'/familias'">
        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
        {{ $t('breadcrumb.familias') }}
      </b-breadcrumb-item>
      <b-breadcrumb-item :to="'/familia/' + relatorio.componente.familia.id">{{ relatorio.componente.familia.descricao }}
      </b-breadcrumb-item>
      <b-breadcrumb-item :to="'/componente/' + relatorio.componente.id">{{ relatorio.componente.descricao }}
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ relatorio.descricao }}</b-breadcrumb-item>
    </b-breadcrumb>

    <b-container fluid>
      <b-row align-v="center" align-h="between">
      <b-button :to="'/componente/' + relatorio.componente.id + '/' + relatorio.id">{{ $t('botoes.voltar') }}</b-button>
      <b-dropdown class="mx-1" right text="Relatório">
        <b-dropdown-item @click="showModalRelatorio()">{{ $t('botoes.editar') }}</b-dropdown-item>
        <b-dropdown-item @click="showModalPagina()">{{ $t('botoes.nova-pagina') }}</b-dropdown-item>        
      </b-dropdown>
    </b-row>

    </b-container>

    <b-overlay :show="carregando" rounded="sm">
      <div v-for="(pagina, index) in relatorio.paginas" :key="pagina.id" class="mt-3">
        <b-card no-body class="card-pagina-relatorio">
          <b-card-header header-tag="nav">
            <b-row align-v="center" align-h="between">
              <b-col cols="auto"><span>{{$t('paginacao.pagina')}} {{ index + 1 }}</span>
              </b-col>
              <b-col cols="auto">
                <b-nav>
                  <b-nav-item @click="showMsgConfirmation(pagina.id)">
                    <b-icon-trash />
                  </b-nav-item>
                  <b-nav-item @click="showModalPagina(pagina)">
                    <b-icon-image />
                  </b-nav-item>
                  <b-nav-item @click="showModalElemento(pagina)">
                    <b-icon-geo-fill />
                  </b-nav-item>
                </b-nav>
              </b-col>
            </b-row>
          </b-card-header>

          <center>
            <div :class="index == 0 ? 'pagina1' : 'pagina'" :id="'pagina' + pagina.id" :ref="'pagina' + pagina.id"
              :style="background(pagina.imagem)">
              <v-stage :config="index == 0 ? stage1Size : stageSize" class="kanva" :ref="'stage' + pagina.id">
                <v-layer>
                  <v-line v-for="line in avaliableLinhas(pagina.id)" :key="line.id" :config="{
                    stroke: 'black',
                    strokeWidth: 1,
                    points: line.points,
                  }" />
                </v-layer>
              </v-stage>

              <elemento-componente v-for="elemento in pagina.elementos" :key="'caixa' + elemento.id"
                :elemento="elemento" tipo="criacao" @moveu="mover($event, pagina.id, elemento.connection)"
                @paroudemover="atualizarCaixa($event)" @dubloclique="showModalElemento(pagina, elemento)"
                @ctrlClick="atualizarCaixaConnection($event)">
              </elemento-componente>

              <ponto-componente v-for="ponto in pagina.elementos" :key="'ponto' + ponto.id" :ponto="ponto"
                tipo="criacao" @moveu="mover($event, pagina.id, ponto.connection)"
                @paroudemover="atualizarCaixa($event)">
              </ponto-componente>
            </div>
          </center>
        </b-card>
      </div>
      <b-card class="my-3">
        <b-button block @click="showModalPagina()" variant="outline-secondary"><b-icon icon="plus-square" ></b-icon>
        {{ $t('botoes.nova-pagina') }}</b-button>
        
      </b-card>

    </b-overlay>
    <pagina-modal v-if="relatorio" :relatorio="relatorio" :pagina="pagina_selecionada"
      @listaMudou="carregarRelatorio()" />
    <elemento-modal v-if="relatorio.controle_padrao" :pagina="pagina_selecionada" :padrao="relatorio.controle_padrao" :conversor="relatorio.conversor"
      :elemento="elemento_selecionado" @listaMudou="carregarRelatorio()" />
    <modal-relatorio :relatorio="relatorio" @listaMudou="carregarRelatorio()" />
    <modal-carregando-geral />
  </div>
</template>


<script>
import PaginaModal from "@/components/modal/Pagina.vue";
import ElementoModal from "@/components/modal/Elemento.vue";
import ElementoComponente from "@/components/Elemento.vue";
import PontoComponente from "@/components/Ponto.vue";
import ModalRelatorio from "@/components/modal/Relatorio.vue";
import ModalCarregandoGeral from "@/components/modal/CarregandoGeral.vue";

import PlainDraggable from "plain-draggable";

export default {
  name: "Relatorio",
  components: {
    PaginaModal,
    ElementoComponente,
    PontoComponente,
    ElementoModal,
    ModalRelatorio,
    ModalCarregandoGeral,
  },
  data() {
    return {
      url: null,
      windowTop: 0,
      carregando: false,
      relatorio: {
        paginas: [],
        componente: {
          id: null,
        },
        controle_padrao: null,
        id: null,
      },
      connections: [],
      stageSize: {
        width: 1035,
        height: 652,
      },
      stage1Size: {
        width: 1035,
        height: 507,
      },
      line: [],
      caixa: [],
      ponto: [],
      pagina_selecionada: {
        id: null,
        relatorio_id: null,
      },
      elemento_selecionado: null,
    };
  },
  methods: {
    background(imagem) {
      if (imagem) {
        return {
          "background-image": "url(" + this.url + "/" + imagem + ")",
        };
      } else {
        return "";
      }
    },
    avaliableLinhas(p_id) {
      return this.connections.filter((c) => c.pagina == p_id);
    },
    showModalRelatorio() {
      this.$bvModal.show("modal-relatorio");
    },
    showModalPagina(p) {
      this.pagina_selecionada = p;

      this.$bvModal.show("modal-pagina");
    },
    showModalElemento(p, el) {
      this.pagina_selecionada = p;
      this.elemento_selecionado = el;

      this.$bvModal.show("modal-elemento");
    },
    carregarRelatorio() {
      this.carregando = true;
      this.$http
        .get("api/relatorio/" + this.$route.params.id)
        .then((response) => {
          this.relatorio = response.data;

          setTimeout(() => {
            this.carregar();
          }, 300);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    carregar() {
      this.carregando = false;

      for (const key in this.line) {
        this.line[key].line.remove();
      }

      this.line = [];
      this.caixa = [];
      this.ponto = [];
      this.connections = [];

      for (const keyPagina in this.relatorio.paginas) {
        var elementos = this.relatorio.paginas[keyPagina].elementos;
        var pagina = this.relatorio.paginas[keyPagina].id;

        for (const key in elementos) {
          this.caixa.push({
            key: elementos[key].id,
            caixa: new PlainDraggable(
              document.getElementById("caixacriacao" + elementos[key].id),
              {
                left:
                  elementos[key].posicaocaixal +
                  document
                    .getElementById("pagina" + pagina)
                    .getBoundingClientRect().x ?? 0,
                top:
                  elementos[key].posicaocaixat +
                  this.windowTop +
                  document
                    .getElementById("pagina" + pagina)
                    .getBoundingClientRect().y ?? 0,
                onDragEnd: function () { },
              }
            ),
          });

          this.ponto.push({
            key: elementos[key].id,
            ponto: new PlainDraggable(
              document.getElementById("pontocriacao" + elementos[key].id),
              {
                left:
                  elementos[key].posicaopontol +
                  document
                    .getElementById("pagina" + pagina)
                    .getBoundingClientRect().x ?? 0,
                top:
                  elementos[key].posicaopontot +
                  this.windowTop +
                  document
                    .getElementById("pagina" + pagina)
                    .getBoundingClientRect().y ?? 0,
                onMove: function () { },
              }
            ),
          });

          var pontoRec = document
            .getElementById("pontocriacao" + elementos[key].id)
            .getBoundingClientRect();

          var caixaRec = document
            .getElementById("caixacriacao" + elementos[key].id)
            .getBoundingClientRect();

          var pontox = parseFloat(
            document
              .getElementById("pontocriacao" + elementos[key].id)
              .getBoundingClientRect().left -
            document.getElementById("pagina" + pagina).getBoundingClientRect()
              .left
          );
          var pontoy = parseFloat(
            document
              .getElementById("pontocriacao" + elementos[key].id)
              .getBoundingClientRect().top -
            document.getElementById("pagina" + pagina).getBoundingClientRect()
              .top
          );
          var caixax = parseFloat(
            document
              .getElementById("caixacriacao" + elementos[key].id)
              .getBoundingClientRect().left -
            document.getElementById("pagina" + pagina).getBoundingClientRect()
              .left
          );
          var caixay = parseFloat(
            document
              .getElementById("caixacriacao" + elementos[key].id)
              .getBoundingClientRect().top -
            document.getElementById("pagina" + pagina).getBoundingClientRect()
              .top
          );

          var conX = 0;
          var conY = 0;
          switch (elementos[key].connection) {
            case 1:
              conX = caixaRec.width / 2;
              conY = caixaRec.height / 2;
              break;
            case 2:
              conX = 0;
              conY = 0;
              break;
            case 3:
              conX = caixaRec.width / 2;
              conY = 0;
              break;
            case 4:
              conX = caixaRec.width;
              conY = 0;
              break;
            case 5:
              conX = caixaRec.width;
              conY = caixaRec.height / 2;
              break;
            case 6:
              conX = caixaRec.width;
              conY = caixaRec.height;
              break;
            case 7:
              conX = caixaRec.width / 2;
              conY = caixaRec.height;
              break;
            case 8:
              conX = 0;
              conY = caixaRec.height;
              break;
            case 9:
              conX = 0;
              conY = caixaRec.height / 2;
              break;
            default:
              conX = caixaRec.width / 2;
              conY = caixaRec.height / 2;
          }

          this.connections.push({
            id: "linha" + elementos[key].id,
            key: elementos[key].id,
            pagina: pagina,
            points: [
              pontox + pontoRec.width / 2,
              pontoy + pontoRec.height / 2,
              caixax + conX,
              caixay + conY,
            ],
          });
        }
      }
    },
    mover($el, $pg, $con) {
      var pontoRec = document
        .getElementById("pontocriacao" + $el)
        .getBoundingClientRect();
      var caixaRec = document
        .getElementById("caixacriacao" + $el)
        .getBoundingClientRect();

      var pontox = parseFloat(
        document.getElementById("pontocriacao" + $el).getBoundingClientRect()
          .left -
        document.getElementById("pagina" + $pg).getBoundingClientRect().left
      );
      var pontoy = parseFloat(
        document.getElementById("pontocriacao" + $el).getBoundingClientRect()
          .top -
        document.getElementById("pagina" + $pg).getBoundingClientRect().top
      );
      var caixax = parseFloat(
        document.getElementById("caixacriacao" + $el).getBoundingClientRect()
          .left -
        document.getElementById("pagina" + $pg).getBoundingClientRect().left
      );
      var caixay = parseFloat(
        document.getElementById("caixacriacao" + $el).getBoundingClientRect()
          .top -
        document.getElementById("pagina" + $pg).getBoundingClientRect().top
      );

      var conX = 0;
      var conY = 0;
      switch ($con) {
        case 1:
          conX = caixaRec.width / 2;
          conY = caixaRec.height / 2;
          break;
        case 2:
          conX = 0;
          conY = 0;
          break;
        case 3:
          conX = caixaRec.width / 2;
          conY = 0;
          break;
        case 4:
          conX = caixaRec.width;
          conY = 0;
          break;
        case 5:
          conX = caixaRec.width;
          conY = caixaRec.height / 2;
          break;
        case 6:
          conX = caixaRec.width;
          conY = caixaRec.height;
          break;
        case 7:
          conX = caixaRec.width / 2;
          conY = caixaRec.height;
          break;
        case 8:
          conX = 0;
          conY = caixaRec.height;
          break;
        case 9:
          conX = 0;
          conY = caixaRec.height / 2;
          break;
        default:
          conX = caixaRec.width / 2;
          conY = caixaRec.height / 2;
      }

      this.connections.find((linha) => linha.key === $el).points = [
        pontox + pontoRec.width / 2,
        pontoy + pontoRec.height / 2,
        caixax + conX,
        caixay + conY,
      ];
    },
    atualizarCaixaConnection($el) {
      var newConnection = $el.connection + 1;

      if (newConnection > 9) {
        newConnection = 1;
      }

      this.relatorio.paginas
        .find((pagina) => pagina.id === $el.pagina)
        .elementos.find((elemento) => elemento.id === $el.elemento).connection =
        newConnection;

      let formData = new FormData();
      formData.append("connection", newConnection);
      formData.append("_method", "patch");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post("api/elemento/" + $el.elemento, formData, config)
        .then((response) => {
          this.mover($el.elemento, $el.pagina, newConnection);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    atualizarCaixa($el) {
      var caixa = this.caixa.find((caixa) => caixa.key === $el.ponto).caixa;
      var ponto = this.ponto.find((ponto) => ponto.key === $el.ponto).ponto;

      let formData = new FormData();
      formData.append(
        "posicaocaixal",
        caixa.left -
        document.getElementById("pagina" + $el.pagina).getBoundingClientRect()
          .left
      );
      formData.append(
        "posicaocaixat",
        caixa.top -
        this.windowTop -
        document.getElementById("pagina" + $el.pagina).getBoundingClientRect()
          .top
      );

      formData.append(
        "posicaopontol",
        ponto.left -
        document.getElementById("pagina" + $el.pagina).getBoundingClientRect()
          .left
      );
      formData.append(
        "posicaopontot",
        ponto.top -
        this.windowTop -
        document.getElementById("pagina" + $el.pagina).getBoundingClientRect()
          .top
      );

      formData.append("_method", "patch");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post("api/elemento/" + $el.ponto, formData, config)
        .then((response) => {
          //console.log(response.data);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    onScroll(e) {
      this.windowTop =
        window.top.scrollY; /* or: e.target.documentElement.scrollTop */
    },
    showMsgConfirmation(pagina) {
      this.$bvModal
        .msgBoxConfirm(this.$t('confirmacao.tem-certeza-que-deseja-remover-esse-pagina-com-todos-seus-componentes'), {
          title: this.$t('title.confirme-por-favor'),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t('confirmacao.sim'),
          cancelTitle: this.$t('confirmacao.nao'),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.deletar(pagina);
          } else {
            return false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deletar(pagina) {   

      this.$bvModal.show("modal-carregando-geral");

      let formData = new FormData();
      formData.append("_method", "delete");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.url = "api/pagina/" + pagina;

      this.$http
        .post(this.url, formData, config)
        .then((response) => {
          this.carregarRelatorio();
          location.reload();          
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
  },
  mounted() {
    this.carregarRelatorio();
    window.addEventListener("scroll", this.onScroll);
    this.url = process.env.VUE_APP_ROOT_API;
  },
  destroyed() {
    for (const key in this.line) {
      this.line[key].line.remove();
    }
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style scoped>
.pagina {
  margin: 3rem;
  width: 1035px;
  height: 652px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-size: cover;
}

.pagina1 {
  margin: 3rem;
  width: 1035px;
  height: 507px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-size: cover;
}

.kanva {
  position: absolute;
  padding: 0px;
  margin: 0px;
}

.card-pagina-relatorio{ 
  background-color:rgb(245, 245, 245)
}
</style>
