<template>
  <section id="skills" class="skills">
    <div class="container" data-aos="fade-up">

      <div class="row">
        <div class="col-lg-6 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
          <img src="@/assets/img/criacao-relatorio.gif" class="img-fluid img-gif" alt="">
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
          <h3>{{ $t('skills.tittle') }}</h3>
          <p class="fst-italic">
            {{ $t('skills.paragrafo-1') }}<br><br>
            {{ $t('skills.paragrafo-2') }} <br><br>
            {{ $t('skills.paragrafo-3') }} <br><br>
            {{ $t('skills.paragrafo-4') }} <br><br>
            {{ $t('skills.paragrafo-5') }}
          </p>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>