<template>
    <div class="home pt-3">
        <b-breadcrumb>
            <b-breadcrumb-item active class="align-middle">
                <b-icon icon="people-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
                {{ $t("breadcrumb.usuarios")}}
            </b-breadcrumb-item>
            <b-button variant="secondary" @click="showModalNovoUsuario()" class="ml-auto" size="sm">
                <b-icon-plus />{{ $t('botoes.novo-usuario') }}
            </b-button>
        </b-breadcrumb>


        <b-card class="mt-3" no-body>
            <b-table :items="usuarios" :fields="fields" responsive="sm" head-variant="light" sort-icon-left striped :current-page="currentPage"
              :per-page="perPage"
                hover show-empty class="mb-0" :emptyText="$t('global.table-empty')">
                <template #cell(Ações)="row" class="text-right">
                    <b-button size="sm" @click="showModalUsuario(row.item)" variant="primary" class="mr-1">
                        <b-icon icon="pencil-fill"></b-icon> {{ $t('botoes.editar') }}
                    </b-button>
                </template>
            </b-table>
        </b-card>
        <b-row align-h="center" class="my-3">
              <b-col sm="5" md="2" class="my-1">
                <b-form-group>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col sm="7" md="2" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="usuarios.length"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
        <modal-carregando-geral />
        <modal-usuario @listaMudou="carregarUsuarios()" :usuario="usuario" />
    </div>
</template>

<script>
import ModalCarregandoGeral from "@/components/modal/CarregandoGeral.vue";
import ModalUsuario from "@/components/modal/Usuario.vue";
export default {
    name: "Usuarios",
    components: {
        ModalCarregandoGeral,
        ModalUsuario
    },
    data() {
        return {
            usuario: {
                id: null,
                nome: null,
                email: null,
            },
            usuarios: [],
            fields: [
                { key: "name", sortable: true },
                { key: "email", sortable: true },
                { key: "departamento.descricao", label: this.$t('label.departamento'), sortable: true, },
                { key: "Ações", label: this.$t('table.title.acoes'), sortable: false, class: 'text-right' },
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 15,
            pageOptions: [
                { value: 5, text: this.$t('paginacao.5-itens-por-pagina') },
                { value: 10, text: this.$t('paginacao.10-itens-por-pagina') },
                { value: 15, text: this.$t('paginacao.15-itens-por-pagina') },
                { value: 100, text: this.$t('paginacao.100-itens-por-pagina') },
            ],
        };
    },
    methods: {
        showModalNovoUsuario() {
            this.usuario = {
                id: null,
                nome: null,
                email: null,
            };
            this.$bvModal.show("modal-usuario");
        },
        showModalUsuario(item) {

            this.usuario = {
                id: item.id,
                nome: item.name,
                email: item.email,
                departamento: item.departamento_id,
            };
            this.$bvModal.show("modal-usuario");
        },
        carregarUsuarios() {
            this.$bvModal.show("modal-carregando-geral");
            this.$http
                .get("api/user")
                .then((response) => {
                    this.usuarios = response.data
                    this.$bvModal.hide("modal-carregando-geral");
                })
                .catch((errors) => {
                    console.log(errors);
                    this.$bvModal.hide("modal-carregando-geral");
                });
        },
    },
    mounted() {
        this.carregarUsuarios()
    }

};
</script>

<style>
</style>