<template>
    <b-modal id="modal-filtrar-elemento" size="lg" :title="$t('title.automarb-filtrar-elemento')">
        <template #modal-header>
            <!-- Emulate built in modal header close button action -->
            <h5>{{ $t('title.automarb-filtrar-elemento') }}</h5>
            <p class="h4 btn"><b-icon icon="x" @click="close()"></b-icon></p>
        </template>
        <b-form v-if="show">
            <b-card bg-variant="light">
                <b-form-group :label="$t('label.buscar')" label-for="filter-input" label-cols-sm="2" label-align-sm="right"
                    label-size="sm" class="mb-3">
                    <b-input-group size="sm">
                        <b-form-input id="filter-input" v-model="filter" type="search"
                            placeholder=""></b-form-input>

                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">{{$t('text.limpar')}}</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-table :items="elementos" :fields="fields" sticky-header select-mode="single" selectable bordered
                    hover small center class="table-elementos text-center" head-variant="light"
                    @row-selected="onRowSelected" :filter="filter" :filter-included-fields="filterOn">
                    <!-- Example scoped slot for select state illustrative purposes -->
                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true">&check;</span>
                            <span class="sr-only">{{$t('text.selecionado')}}</span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true">&nbsp;</span>
                            <span class="sr-only">Not selected</span>
                        </template>
                    </template>
                </b-table>
            </b-card>
        </b-form>

        <b-alert show variant="danger" v-if="menssagem">
            <ul v-if="menssagem">
                <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
            </ul>
        </b-alert>

        <template #modal-footer>
            <div class="w-100">
                <b-row align-v="center">
                    <b-col cols="12" md="8">
                        <b-row align-v="center" align-h="center">
                            <b-col>
                                <p class="h1 ml-3">
                                    <b-icon icon="exclamation-octagon" variant="primary"></b-icon>
                                </p>
                            </b-col>
                            <b-col cols="12" md="10">
                                <p class="pt-2">
                                    <span class="text-primary"> {{ $t('modal.importante') }}</span><br />
                                    {{ $t('modal.preenchaTodosOsDadosCorretamente') }}
                                </p>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="6" md="4">
                        <b-button variant="primary" class="float-right" v-if="carregando" disabled>
                            <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                            {{ $t('botoes.carregando') }} </b-button>

                        <b-button v-else type="submit" variant="primary" class="float-right" @click="salvar()">
                            {{ $t('botoes.filtrar') }} </b-button>
                    </b-col>
                </b-row>
            </div>
        </template>
    </b-modal>

</template>

<script>
export default {
    props: {
        relatorio: null,
    },
    data() {
        return {
            filter: null,
            filterOn: [],
            show: true,
            menssagem: null,
            carregando: null,
            form: {
                elemento: null,
            },
            optionsElemento: [],
            elemento_selecionado: null,
            elementos: [],
            output: null,
            fields: ["selected", "id", "descricao"],
            selected: [],
        };
    },
    methods: {
        onRowSelected(items) {
            this.selected = items;
        },

        makeToast(variant = null) {
            this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
                title: this.$t('toast.sucesso'),
                variant: variant,
                solid: true,
            });
        },
        close() {
            this.$bvModal.hide("modal-filtrar-elemento");
        },
        salvar() {
            this.menssagem = null;
            this.carregando = true;

            if(this.selected.length > 0){
                this.$emit("elemento_id", this.selected[0].id);
            }else{
                this.$emit("elemento_id", 0);
            }   
            
            this.selected = [];

            this.carregando = false;
            this.close();
        },
        carregarElementos() {
            this.carregando = true;
            this.menssagem = null;
            this.elementos = null;

            this.$http
                .get("api/relatorio/elementos/" + this.relatorio)
                .then((response) => {
                    this.elementos = response.data;
                    this.carregando = false;
                })
                .catch((errors) => {
                    this.menssagem = errors.response;
                    this.carregando = false;
                });
        },
    },
    mounted() {
        this.carregarElementos();
    },
    watch: {
        relatorio: function () {
            this.carregarElementos();
        }
    },
};


</script>

<style></style>