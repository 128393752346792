<template>
    <div id="chart">
        <apexchart type="bar" height="180" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
    props: {
        analysis_type: null
    },
    data() {
        return {
            series: [{
                data: [400, 430, 448, 470]
            }],
            chartOptions: {
                title: {
                    text: "Characteristcs",
                    align: "center",
                    margin: 5,
                    floating: true,
                    style: {
                        fontSize: "12px",
                        fontWeight: "bold",
                    },
                },
                chart: {
                    toolbar: {
                        show: false,
                    },
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: ['Acoplamento', 'Superfície', 'Linha de Corte', 'Furo'],
                }
            },
        };
    },
    mounted() {
        this.updateGrafico();
    },
    methods: {
        updateGrafico() {
            var arrayDados = [this.analysis_type.acoplamento, this.analysis_type.superficie, this.analysis_type.corte, this.analysis_type.furo]
            this.series[0].data = arrayDados;
        },
    },
    watch: {
        analysis_type: {
            deep: true,
            immediate: true,
            handler() {
                this.updateGrafico();
            }
        },
        semanas: function () {
            this.updateGrafico();
        },
        verde: function () {
            this.updateGrafico();
        },
        amarelo: function () {
            this.updateGrafico();
        },
    },


}
</script>

<style>
</style>