<template>
  <table class="table table-bordered table-condensed">
    <tr>
      <td style="background-color: #0099ff"></td>
      <td>Dentro da especificação</td>
    </tr>
    <tr>
      <td style="background-color: #800000"></td>
      <td>Fora da especificação à esqueda</td>
    </tr>
    <tr>
      <td style="background-color: #800000"></td>
      <td>Fora da especificação à direita</td>
    </tr>
    <tr>
      <td style="background-color: #cc0000"></td>
      <td>LIE</td>
    </tr>
    <tr>
      <td style="background-color: #cc0000"></td>
      <td>LSE</td>
    </tr>
    <tr>
      <td style="background-color: #000000"></td>
      <td>Média</td>
    </tr>    
  </table>
</template>

<script>
export default {};
</script>

<style scoped>
.table-condensed {
  margin-top: 0;
  padding-top: 0;
  font-size: 11px !important;
  text-align: center;
  font-weight: bold;
}

tr td,
th {
  height: 5px !important;
  padding: 5px !important;
}
</style>