<template>
  <div class="bg-gradient-success">
    <!-- Header -->
    <div class="header py-7 py-lg-8 pt-lg-9 pt-5">
      <b-container>
        <div class="header-body text-center mb-7">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <h1 class="text-white"></h1>
              <p class="text-lead text-white"></p>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-3 container-login">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <b-row class="justify-content-center">
                <img src="../assets/logo.png" alt="Logo" height="40" />
              </b-row>

              <div class="text-center text-muted mb-4">
                <small>{{ $t('reset.redefinir-senha') }}</small>
              </div>

              <b-form role="form" @submit="onSubmit">

                <b-form-group id="fieldset-1" :label="$t('login.entre-com-seu-e-mail')" label-for="input-email"
                  valid-feedback="Thank you!">
                  <b-form-input id="input-email" v-model="form.email" type="email" required></b-form-input>
                </b-form-group>

                <b-form-group id="fieldset-1" :label="$t('login.entre-com-nova-senha')" label-for="input-senha"
                  valid-feedback="Thank you!">
                  <VuePassword v-model="form.password" id="input-senha" disableStrength type="password" required />
                </b-form-group>

                <b-form-group id="fieldset-1" :label="$t('login.confirme-a-senha')" label-for="input-senha"
                  valid-feedback="Thank you!">
                  <VuePassword v-model="form.password_confirmation" id="input-senha-confirmation" disableStrength
                    type="password" required />
                </b-form-group>

                <div class="text-center">
                  <b-button block variant="primary" v-if="carregando" disabled class="my-4">
                    <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                    {{ $t('botoes.carregando') }}
                  </b-button>
                  <b-button block v-else type="primary" variant="primary" native-type="submit" class="my-4">{{
                    $t('botoes.nova-senha')
                  }}
                  </b-button>
                </div>
              </b-form>

              <b-alert show variant="success" v-if="menssagemSucesso">
                {{ menssagemSucesso }}
              </b-alert>

              <b-alert show variant="danger" v-if="menssagemArray">
                <ul v-if="menssagemArray">
                  <li v-for="(e, key) in menssagemArray" :key="key">{{ e[0] }}</li>
                </ul>
              </b-alert>

              <b-alert show variant="danger" v-if="menssagem">
                {{ menssagem }}
              </b-alert>
            </b-card-body>
          </b-card>
          <b-row class="mt-3">
            <b-col cols="6" class="text-light">

              <router-link to="/login" class="text-light"><small><b-icon icon="chevron-left"
                    aria-hidden="true"></b-icon> {{ $t('login.voltar-login') }}</small>
              </router-link>

            </b-col>
            <b-col cols="6">
              <b-row align-v="center" align-h="center">
                <b-col cols="auto">
                  <b-icon icon="globe" style="color:white; padding-left: 1rem;"></b-icon>
                </b-col>
                <b-col>
                  <b-form-select size="sm" :options="linguagens" v-model="$i18n.locale"></b-form-select>
                </b-col>

              </b-row>
            </b-col>

          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

import VuePassword from 'vue-password';

export default {
  components: {
    VuePassword,
  },
  data() {
    return {
      terms: null,
      form: {
        token: null,
        password: null,
        password_confirmation: null,
        email: null,
      },
      planos: [
        { value: 1, text: "Basic" },
        { value: 2, text: "Standard" },
        { value: 3, text: "Enterprise" },
      ],
      menssagem: null,
      menssagemSucesso: null,
      menssagemArray: null,
      carregando: null,
    };
  },
  computed: {
    linguagens() {
      return [
        { value: "pt-BR", text: this.$t('idiomas.portugues-bra') },
        { value: "en", text: this.$t('idiomas.ingles-eua-gbr') },
        { value: "it", text: this.$t('idiomas.italiano-ita') },
        { value: "es", text: this.$t('idiomas.espanhol-esp-arg') },
      ]
    },
    mensagemErro() {
      return this.$t('login.usuario-ou-senha-invalido')
    },
    mensagemCamposVazios() {
      return this.$t('login.todos-os-campos-devem-ser-preechidos')
    }
  },
  methods: {
    onSubmit(event) {
      this.menssagem = null
      this.menssagemArray = null
      this.menssagemSucesso = null,
        event.preventDefault();

      let formData = new FormData();
      formData.append("email", this.form.email);
      formData.append("password", this.form.password);
      formData.append("password_confirmation", this.form.password_confirmation);
      formData.append("token", this.$attrs.token);

      this.carregando = true
      let config = {
        headers: {
          Accept: "application/json",
        },
      };

      this.$http
        .post('api/reset-password', formData, config)
        .then((response) => {
          this.menssagemSucesso = response.data.message
          this.carregando = false;
        })
        .catch((errors) => {

          if (errors.response.status == 500) {
            this.menssagem = errors.response.data.message;
          } else {
            this.menssagemArray = errors.response.data.errors;
          }

          this.carregando = false;
        });
    }
  }
};
</script>
<style scoped>
.bg-gradient-success {
  background: rgb(31, 23, 77);
  background: linear-gradient(90deg,
      rgba(31, 23, 77, 1) 0%,
      rgba(9, 68, 121, 1) 35%,
      rgba(0, 212, 255, 1) 100%);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
  min-width: 100vh;
}

.container-login {
  margin-top: 50px;
}

ul {
  list-style-type: none;
}
</style>
