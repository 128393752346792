<template>
  <b-modal id="modal-linha" size="lg" :title="$t('title.linha')">
    <template #modal-header>
      <!-- Emulate built in modal header close button action -->
      <h5>{{ $t("title.linha") }}</h5>
      <div class="btn">
        <b-icon icon="x" @click="close()"></b-icon>
      </div>
    </template>
    <b-form v-if="show">
      <b-form-group :label="$t('label.descricao')">
        <b-form-input v-model="form.descricao" type="text" required>
        </b-form-input>
      </b-form-group>
      <b-form-group :label="$t('label.diretorio_programa_winfas')">
        <b-form-input v-model="form.diretorio_programa_winfas" type="text">
        </b-form-input>
      </b-form-group>
      <b-form-group :label="$t('label.host_sql_winfas')">
        <b-form-input v-model="form.host_sql_winfas" type="text">
        </b-form-input>
      </b-form-group>
      <b-form-group :label="$t('label.usuario_sql_winfas')">
        <b-form-input v-model="form.usuario_sql_winfas" type="text">
        </b-form-input>
      </b-form-group>
      <b-form-group :label="$t('label.senha_sql_winfas')">
        <b-form-input v-model="form.senha_sql_winfas" type="text">
        </b-form-input>
      </b-form-group>
    </b-form>

    <b-alert show variant="danger" v-if="menssagem">
      <ul v-if="menssagem">
        <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
      </ul>
    </b-alert>

    <template #modal-footer>
      <div class="w-100">
        <b-row align-v="center">
          <b-col cols="12" md="4"> </b-col>
          <b-col cols="6" md="8">
            <b-button
              variant="primary"
              class="float-right"
              v-if="carregando"
              disabled
            >
              <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
              {{ $t("botoes.carregando") }}
            </b-button>

            <b-button
              v-else
              type="submit"
              variant="primary"
              class="float-right"
              @click="salvar()"
            >
              {{ $t("botoes.salvar") }}
            </b-button>

            <b-button
              v-if="linha.id"
              type="submit"
              variant="danger"
              class="float-right mr-2"
              @click="
                showMsgConfirmation(
                  'Tem certeza que deseja remover esse linha?'
                )
              "
            >
              {{ $t("botoes.deletar") }}
            </b-button>
            <b-button
              v-if="linha.id"
              type="submit"
              variant="warning"
              class="float-right mr-2"
              @click="testarConecao()"
            >
              {{ $t("botoes.testar-conexao") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    linha: {
      type: Object,
      default: function () {
        return {
          id: null,
          descricao: null,
        };
      },
    },
  },
  data() {
    return {
      form: {
        descricao: "",
        diretorio_programa_winfas: "",
        host_sql_winfas: "",
        usuario_sql_winfas: "",
        senha_sql_winfas: "",
      },
      linhas: [],
      show: true,
      menssagem: null,
      carregando: null,
      url: "api/user",
    };
  },
  methods: {
    makeToastModular(
      variant = null,
      message = this.$t("toast.operacao-realizada-com-sucesso"),
      title
    ) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t("toast.operacao-realizada-com-sucesso"), {
        title: this.$t("toast.sucesso"),
        variant: variant,
        solid: true,
      });
    },
    close() {
      this.$bvModal.hide("modal-linha");
    },
    limpar() {
      this.form.descricao = "";
      this.form.diretorio_programa_winfas = "";
      this.form.host_sql_winfas = "";
      this.form.usuario_sql_winfas = "";
      this.form.senha_sql_winfas = "";
      this.menssagem = "";
    },
    salvar() {
      this.menssagem = null;
      this.carregando = true;
      let formData = new FormData();
      formData.append("descricao", this.form.descricao);

      formData.append(
        "diretorio_programa_winfas",
        this.form.diretorio_programa_winfas
      );
      formData.append("host_sql_winfas", this.form.host_sql_winfas);
      formData.append("usuario_sql_winfas", this.form.usuario_sql_winfas);
      formData.append("senha_sql_winfas", this.form.senha_sql_winfas);

      if (this.linha.id) {
        formData.append("_method", "patch");
      }

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post(this.url, formData, config)
        .then((response) => {
          this.makeToast("success");
          this.$bvModal.hide("modal-linha");
          this.carregando = false;
          this.$emit("listaMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    deletar() {
      let formData = new FormData();
      formData.append("_method", "delete");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post(this.url, formData, config)
        .then((response) => {
          this.makeToast("success");
          this.$bvModal.hide("modal-linha");
          this.carregando = false;
          this.$emit("listaMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    showMsgConfirmation(msg) {
      this.$bvModal
        .msgBoxConfirm(msg, {
          title: this.$t("title.confirme-por-favor"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("confirmacao.sim"),
          cancelTitle: this.$t("confirmacao.nao"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.deletar();
          } else {
            return false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    carregarLinhas() {
      this.$bvModal.show("modal-carregando-geral");
      this.$http
        .get("api/linha")
        .then((response) => {
          this.linhas = [];
          response.data.forEach((element) => {
            this.linhas.push({
              value: element.id,
              text: element.descricao,
            });
          });
          this.$bvModal.hide("modal-carregando-geral");
        })
        .catch((errors) => {
          console.log(errors);
          this.$bvModal.hide("modal-carregando-geral");
        });
    },
    testarConecao() {
      this.$bvModal.show("modal-carregando-geral");
      this.$http
        .get("api/verificarConexaoComBanco/" + this.linha.id)
        .then((response) => {
          console.log(response);

          if(response.data.status == 200){
            this.makeToastModular("success", response.data.msg, 'Sucesso');
            this.$bvModal.hide("modal-carregando-geral");
          }else{
            this.makeToastModular("danger", response.data.msg, 'Falha');
            this.$bvModal.hide("modal-carregando-geral");
          }
          
        })
        .catch((errors) => {
          this.$bvModal.hide("modal-carregando-geral");
        });
    },
  },
  mounted() {
    this.carregarLinhas();
  },
  watch: {
    linha: function (linha) {
      if (this.linha.id) {
        this.form.descricao = linha.descricao;
        this.form.diretorio_programa_winfas = linha.diretorio_programa_winfas ?? '';
        this.form.usuario_sql_winfas = linha.usuario_sql_winfas ?? '';
        this.form.host_sql_winfas = linha.host_sql_winfas ?? '';
        this.form.senha_sql_winfas = linha.senha_sql_winfas ?? '';
        this.url = "api/linha/" + this.linha.id;
      } else {
        this.url = "api/linha";
        this.limpar();
      }
    },
  },
};
</script>

<style></style>
