<template>
    <div>
        <b-row class="border-top mt-3 pt-3">
            <b-col>                
                <b-form v-if="show">
                    <b-form-group :label="$t('label.descricao')">
                        <b-skeleton type="input" v-if="carregando"></b-skeleton>

                        <b-form-input v-if="!carregando" v-model="form.descricao" type="text"
                            :placeholder="$t('placeholder.entre-com-o-nome')" required>
                        </b-form-input>
                    </b-form-group>

                    <!-- <b-form-group :label="$t('label.plano')">
                        <b-skeleton type="input" v-if="carregando"></b-skeleton>

                        <b-form-select v-if="!carregando" :options="planos" v-model="form.plano_id" required></b-form-select>
                    </b-form-group>   -->                 
                </b-form>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-button variant="primary" class="float-left" v-if="carregando" disabled>
                    <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                    {{ $t('botoes.carregando') }} </b-button>

                <b-button v-else type="submit" variant="primary" class="float-left" @click="salvar()">
                    {{ $t('botoes.salvar-alteracoes') }} </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: true,
            menssagem: null,
            carregando: null,
            form: {
                id: null,
                descricao: null,
                plano_id: null,
                
            },            
            planos: [
                { value: 1, text: "Basic" },
                { value: 2, text: "Standard" },
                { value: 3, text: "Enterprise" },
            ],
        };
    },
    methods: {
        makeToast(variant = null) {
            this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
                title: this.$t('toast.sucesso'),
                variant: variant,
                solid: true,
            });
        },
        carregarOrganizacao() {
            this.$bvModal.show("modal-carregando-geral");
            this.$http
                .get("api/organizacao")
                .then((response) => {
                    this.form.id = response.data.id;
                    this.form.descricao = response.data.descricao;
                    this.form.plano_id = response.data.plano_id;                    
                    this.$bvModal.hide("modal-carregando-geral");
                })
                .catch((errors) => {
                    console.log(errors);
                    this.$bvModal.hide("modal-carregando-geral");
                });
        },
        salvar() {
            this.menssagem = null;
            this.carregando = true;

            this.$http
                .patch('api/organizacao/' + this.form.id, this.form)
                .then((response) => {
                    this.makeToast("success");
                    this.carregando = false;
                })
                .catch((errors) => {
                    this.menssagem = errors.response.data.errors;
                    this.carregando = false;
                });
        },
    },
    mounted() {        
        this.carregarOrganizacao();
    },

}
</script>

<style>

</style>