<template>
  <b-modal
    id="modal-controle-individual"
    size="lg"
    :title="$t('title.automarb-controle-individual')"
  >
    <template #modal-header>
      <!-- Emulate built in modal header close button action -->
      <h5>{{ $t("title.automarb-controle-individual") }}</h5>
      <p class="h4 btn"><b-icon icon="x" @click="close()"></b-icon></p>
    </template>
    <b-form v-if="show">
      <b-card
        bg-variant="light"
        :header="$t('header.controles-da-semana') + ' ' + $store.state.semana"
      >
        <b-table
          :items="optionsControles"
          :fields="fields"
          sticky-header
          select-mode="single"
          selectable
          bordered
          hover
          small
          center
          class="table-controles text-center"
          head-variant="light"
          @row-selected="onRowSelected"
        >
          <!-- Example scoped slot for select state illustrative purposes -->
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
      </b-card>
    </b-form>

    <b-alert show variant="danger" v-if="menssagem">
      <ul v-if="menssagem">
        <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
      </ul>
    </b-alert>

    <template #modal-footer>
      <div class="w-100">
        <b-row align-v="center">
          <b-col cols="12" md="8">
            <b-row align-v="center" align-h="center">
              <b-col
                ><p class="h1 ml-3">
                  <b-icon
                    icon="exclamation-octagon"
                    variant="primary"
                  ></b-icon></p
              ></b-col>
              <b-col cols="12" md="10">
                <p class="pt-2">
                  <span class="text-primary"> {{ $t("modal.importante") }}</span
                  ><br />
                  {{ $t("modal.preenchaTodosOsDadosCorretamente") }}
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" md="4">
            <b-button
              variant="primary"
              class="float-right"
              v-if="carregando"
              disabled
            >
              <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
              {{ $t("botoes.carregando") }}
            </b-button>

            <b-button
              v-else
              type="submit"
              variant="primary"
              class="float-right"
              @click="salvar()"
            >
              {{ $t("botoes.salvar") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    controles:[],
    relatorio: null,
  },
  computed: {
  },
  data() {
    return {
      show: true,
      menssagem: null,
      carregando: null,
      form: {
        controle: null,
      },
      optionsElemento: [],
      elemento_selecionado: null,
      optionsControles: [],
      output: null,
      fields: ["selected", "cod", "data"],
      selected: [],
    };
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },

    makeToast(variant = null) {
      this.$bvToast.toast(this.$t("toast.operacao-realizada-com-sucesso"), {
        title: this.$t("toast.sucesso"),
        variant: variant,
        solid: true,
      });
    },
    close() {
      this.$bvModal.hide("modal-controle-individual");
    },
    salvar() {
      this.menssagem = null;
      this.carregando = true;

      this.$emit("controle_id", this.selected[0].cod);

      this.carregando = false;
      this.close();
    },
    carregarControles() {
      this.carregando = true;

      this.optionsControles = [];

      this.controles.forEach((controle) => {
        const d = new Date(controle.data).toLocaleDateString("pt-br");
        const h = new Date(controle.data).toLocaleTimeString("pt-br");
        this.optionsControles.push({
          ativo: controle.ativo ? true : false,
          cod: controle.id,
          data: d + " " + h,
        });
      });

      this.carregando = false;
    },
  },
  mounted() {
    this.carregarControles();
  },
  watch: {
    controles: function () {
      this.carregarControles();
    },
  },
};
</script>

<style scoped>
.table-controles {
  background-color: white;
}
.table-controles tr td {
  padding: 0 !important;
  margin: 0 !important;
}
.form-control {
  border-radius: 0px !important;
}
</style>
