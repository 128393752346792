<template>
    <b-modal id="modal-upload-imagem" size="md" :title="$t('title.alterar-imagem')" ok-only centered>
        <p class="my-1">{{ $t('modal.selecione-uma-imagem') }}</p>
        <b-form-file v-model="imagem" accept="image/*"
            :placeholder="$t('placeholder.escolha-uma-imagem-ou-solte-o-aqui')"
            :drop-placeholder="$t('drop-placeholder.solte-a-imagem-aqui')"></b-form-file>
        <template #modal-footer>
            <div class="w-100">
                <b-row align-v="center">
                    <b-col>
                        <b-button variant="primary" v-if="carregando" block disabled>
                            <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                            {{ $t('botoes.carregando') }} </b-button>

                        <b-button v-else type="submit" variant="primary" block @click="salvar()">
                            {{ $t('botoes.alterar') }} </b-button>
                    </b-col>
                </b-row>
            </div>
        </template>
    </b-modal>
</template>

<script>
export default {
    props: {
        urlSetImagem: null,
    },
    data() {
        return {
            imagem: null,
            carregando: false
        };
    },
    methods: {
        salvar() {
            this.menssagem = null;
            this.carregando = true;
            let formData = new FormData();

            if (this.imagem) {
                formData.append("imagem", this.imagem);
            }

            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                },
            };

            this.$http
                .post(this.urlSetImagem, formData, config)
                .then((response) => {
                    this.$bvModal.hide("modal-upload-imagem");
                    this.carregando = false;
                    this.$emit("imagemMudou", response.data);
                })
                .catch((errors) => {
                    this.$bvModal.hide("modal-upload-imagem");
                    this.menssagem = errors.response.data.errors;
                    this.carregando = false;
                });
        },

    }
};
</script>

<style>

</style>