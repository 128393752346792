<template>
    <div>
        <b-row>
            <b-col>
                <b-form v-if="show">
                    <b-form-group :label="$t('label.alterar-senha')">
                        <b-form-input v-model="form.password" type="password"
                            :placeholder="$t('placeholder.entre-com-a-senha')" required>
                        </b-form-input>
                    </b-form-group>

                    <b-form-group :label="$t('label.confirme-a-senha')">
                        <b-form-input v-model="form.password_confirmation" type="password"
                            :placeholder="$t('label.confirme-a-senha')" required>
                        </b-form-input>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-button variant="primary" class="float-left" v-if="carregando" disabled>
                    <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                    {{ $t('botoes.carregando') }} </b-button>

                <b-button v-else type="submit" variant="primary" class="float-left" @click="salvar()">
                    {{ $t('botoes.salvar-alteracoes') }} </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: true,
            menssagem: null,
            carregando: null,
            form: {
                id: null,
                password: null,
                password_confirmation: null,
            }
        };
    },
    methods: {
        makeToast(variant = null) {
            this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
                title: this.$t('toast.sucesso'),
                variant: variant,
                solid: true,
            });
        },
        carregarMeusDados() {
            this.$bvModal.show("modal-carregando-geral");
            this.$http
                .get("api/me")
                .then((response) => {
                    this.form.id = response.data.id;
                    this.$bvModal.hide("modal-carregando-geral");
                })
                .catch((errors) => {
                    console.log(errors);
                    this.$bvModal.hide("modal-carregando-geral");
                });
        },
        salvar() {
            this.menssagem = null;
            this.carregando = true;

            this.$http
                .patch('api/user/' + this.form.id, this.form)
                .then((response) => {
                    this.makeToast("success");
                    this.carregando = false;
                })
                .catch((errors) => {
                    this.menssagem = errors.response.data.errors;
                    this.carregando = false;
                });
        },
    },
    mounted() {
        this.carregarMeusDados();
    },

}
</script>

<style>

</style>