<template>
  <div>
    <div class="container-analytics" v-if="carregando">
      <b-row>
        <b-col class="m-0 p-0">
          <b-skeleton animation="fade"></b-skeleton>
        </b-col>
        <b-col class="m-0 p-0 px-1">
          <b-skeleton animation="fade"></b-skeleton>
        </b-col>
        <b-col class="m-0 p-0">
          <b-skeleton animation="fade"></b-skeleton>
        </b-col>
      </b-row>
    </div>

    <div class="container-analytics" v-if="familia.fechamento_hoje">
      <b-row>
        <b-col class="m-0 p-0">
          <div
            :class="
              verificaStatusValor(
                familia.fechamento_hoje.cg.pc_verde,
                familia.fechamento_ontem.cg.pc_verde
              )
            "
          >
            <b-row class="indicador">
              <b-col class="mr-0 pr-0">Cg</b-col>
              <b-col cols="auto"
                ><b-icon-arrow-up
                  v-if="
                    familia.fechamento_hoje.cg.pc_verde >
                    familia.fechamento_ontem.cg.pc_verde
                  " />
                <b-icon-arrow-right
                  v-else-if="
                    familia.fechamento_hoje.cg.pc_verde ==
                    familia.fechamento_ontem.cg.pc_verde
                  " />
                <b-icon-arrow-down v-else
              /></b-col>
            </b-row>
            <b-row class="valor-atual justify-content-md-end pr-1">
              <b-col cols="auto"
                >{{ familia.fechamento_hoje.cg.pc_verde | umaCasa }}%</b-col
              >
            </b-row>
            <b-row class="semana-atual justify-content-md-end pr-1">
              <b-col cols="auto"
                >{{ familia.fechamento_hoje.periodo }}
                {{ familia.fechamento_hoje.descricao }}</b-col
              >
            </b-row>
            <b-row class="valor-anterior justify-content-md-end pr-1 mt-2">
              <b-col cols="auto"
                >{{ familia.fechamento_ontem.cg.pc_verde | umaCasa }}%</b-col
              >
            </b-row>
            <b-row class="semana-anterior justify-content-md-end pr-1">
              <b-col cols="auto"
                >{{ familia.fechamento_ontem.periodo }}
                {{ familia.fechamento_ontem.descricao }}</b-col
              >
            </b-row>
          </div>
        </b-col>
        <b-col class="m-0 p-0 px-1">
          <div
            :class="
              verificaStatusValor(
                familia.fechamento_hoje.cp.pc_verde,
                familia.fechamento_ontem.cp.pc_verde
              )
            "
          >
            <b-row class="indicador">
              <b-col class="mr-0 pr-0">{{
                indicadorCp(familia.fechamento_indicador)
              }}</b-col>
              <b-col cols="auto"
                ><b-icon-arrow-up
                  v-if="
                    familia.fechamento_hoje.cp.pc_verde >
                    familia.fechamento_ontem.cp.pc_verde
                  " />
                <b-icon-arrow-right
                  v-else-if="
                    familia.fechamento_hoje.cp.pc_verde ==
                    familia.fechamento_ontem.cp.pc_verde
                  " />
                <b-icon-arrow-down v-else
              /></b-col>
            </b-row>
            <b-row class="valor-atual justify-content-md-end pr-1">
              <b-col cols="auto"
                >{{ familia.fechamento_hoje.cp.pc_verde | umaCasa }}%</b-col
              >
            </b-row>
            <b-row class="semana-atual justify-content-md-end pr-1">
              <b-col cols="auto"
                >{{ familia.fechamento_hoje.periodo }}
                {{ familia.fechamento_hoje.descricao }}</b-col
              >
            </b-row>
            <b-row class="valor-anterior justify-content-md-end pr-1 mt-2">
              <b-col cols="auto"
                >{{ familia.fechamento_ontem.cp.pc_verde | umaCasa }}%</b-col
              >
            </b-row>
            <b-row class="semana-anterior justify-content-md-end pr-1">
              <b-col cols="auto"
                >{{ familia.fechamento_ontem.periodo }}
                {{ familia.fechamento_ontem.descricao }}</b-col
              >
            </b-row>
          </div>
        </b-col>
        <b-col class="m-0 p-0">
          <div
            :class="
              verificaStatusValor(
                familia.fechamento_hoje.cpk.pc_verde,
                familia.fechamento_ontem.cpk.pc_verde
              )
            "
          >
            <b-row class="indicador">
              <b-col class="mr-0 pr-0">{{
                indicadorCpk(familia.fechamento_indicador)
              }}</b-col>
              <b-col cols="auto"
                ><b-icon-arrow-up
                  v-if="
                    familia.fechamento_hoje.cpk.pc_verde >
                    familia.fechamento_ontem.cpk.pc_verde
                  " />
                <b-icon-arrow-right
                  v-else-if="
                    familia.fechamento_hoje.cpk.pc_verde ==
                    familia.fechamento_ontem.cpk.pc_verde
                  " />
                <b-icon-arrow-down v-else
              /></b-col>
            </b-row>
            <b-row class="valor-atual justify-content-md-end pr-1">
              <b-col cols="auto"
                >{{ familia.fechamento_hoje.cpk.pc_verde | umaCasa }}%</b-col
              >
            </b-row>
            <b-row class="semana-atual justify-content-md-end pr-1">
              <b-col cols="auto"
                >{{ familia.fechamento_hoje.periodo }}
                {{ familia.fechamento_hoje.descricao }}</b-col
              >
            </b-row>
            <b-row class="valor-anterior justify-content-md-end pr-1 mt-2">
              <b-col cols="auto"
                >{{ familia.fechamento_ontem.cpk.pc_verde | umaCasa }}%</b-col
              >
            </b-row>
            <b-row class="semana-anterior justify-content-md-end pr-1">
              <b-col cols="auto"
                >{{ familia.fechamento_ontem.periodo }}
                {{ familia.fechamento_ontem.descricao }}</b-col
              >
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>

    <div
      class="container-analytics"
      v-if="!carregando && !familia.fechamento_hoje"
    >
      <b-row>
        <b-col class="m-0 p-0">
          <div class="box-score-grey">
            <b-row class="indicador">
              <b-col> &nbsp;</b-col>
              <b-col cols="auto"> &nbsp;</b-col>
            </b-row>
            <b-row class="valor-atual justify-content-md-end pr-1">
              <b-col cols="auto"> &nbsp;</b-col>
            </b-row>
            <b-row class="semana-atual justify-content-md-end pr-1">
              <b-col cols="auto"> &nbsp;</b-col>
            </b-row>
            <b-row class="valor-anterior justify-content-md-end pr-1 mt-2">
              <b-col cols="auto"> &nbsp;</b-col>
            </b-row>
            <b-row class="semana-anterior justify-content-md-end pr-1">
              <b-col cols="auto"> &nbsp;</b-col>
            </b-row>
          </div>
        </b-col>
        <b-col class="m-0 p-0 px-1">
          <div class="box-score-grey">
            <b-row class="indicador">
              <b-col> &nbsp;</b-col>
              <b-col cols="auto"> &nbsp;</b-col>
            </b-row>
            <b-row class="valor-atual justify-content-md-end pr-1">
              <b-col cols="auto"> &nbsp;</b-col>
            </b-row>
            <b-row class="semana-atual justify-content-md-end pr-1">
              <b-col cols="auto"> &nbsp;</b-col>
            </b-row>
            <b-row class="valor-anterior justify-content-md-end pr-1 mt-2">
              <b-col cols="auto"> &nbsp;</b-col>
            </b-row>
            <b-row class="semana-anterior justify-content-md-end pr-1">
              <b-col cols="auto"> &nbsp;</b-col>
            </b-row>
          </div>
        </b-col>
        <b-col class="m-0 p-0">
          <div class="box-score-grey">
            <b-row class="indicador">
              <b-col> &nbsp;</b-col>
              <b-col cols="auto"> &nbsp;</b-col>
            </b-row>
            <b-row class="valor-atual justify-content-md-end pr-1">
              <b-col cols="auto"> &nbsp;</b-col>
            </b-row>
            <b-row class="semana-atual justify-content-md-end pr-1">
              <b-col cols="auto"> &nbsp;</b-col>
            </b-row>
            <b-row class="valor-anterior justify-content-md-end pr-1 mt-2">
              <b-col cols="auto"> &nbsp;</b-col>
            </b-row>
            <b-row class="semana-anterior justify-content-md-end pr-1">
              <b-col cols="auto"> &nbsp;</b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    familia_id: null,
  },
  data() {
    return {
      carregando: false,
      familia: {
        fechamento_indicador: null,
        fechamento_hoje: null,
        fechamento_ontem: null,
      },
    };
  },
  computed: {
    filter() {
      return (
        "?tipoPeriodo=" +
        this.$store.state.tipoPeriodo +
        "&ano=" +
        this.$store.state.ano +
        "&mes=" +
        this.$store.state.mes +
        "&semana=" +
        this.$store.state.semana +
        "&data=" +
        this.$store.state.data +
        "&turno=" +
        this.$store.state.turno
      );
    },
    user() {
      return this.$store.state.auth.user.user;
    },
    turno: {
      get() {
        return this.$store.state.turno;
      },
      set(value) {
        this.$store.commit("setTurno", value);
      },
    },
    tipoPeriodo: {
      get() {
        return this.$store.state.tipoPeriodo;
      },
      set(value) {
        this.$store.commit("setTipoPeriodo", value);
      },
    },
    mes: {
      get() {
        return this.$store.state.mes;
      },
      set(value) {
        this.$store.commit("setMes", value);
      },
    },
    semana: {
      get() {
        return this.$store.state.semana;
      },
      set(value) {
        this.$store.commit("setSemana", value);
      },
    },
    data: {
      get() {
        return this.$store.state.data;
      },
      set(value) {
        this.$store.commit("setData", value);
      },
    },
    ano: {
      get() {
        return this.$store.state.ano;
      },
      set(value) {
        this.$store.commit("setAno", value);
      },
    },
  },
  methods: {
    indicadorCp(indicador) {
      return indicador == "cp" ? "CP" : "PP";
    },
    indicadorCpk(indicador) {
      return indicador == "cp" ? "CPK" : "PPK";
    },
    verificaStatusValor($atual, $anterior) {
      if ($atual > $anterior) {
        return "box-score-green";
      } else if ($atual == $anterior) {
        return "box-score-blue";
      } else {
        return "box-score-red";
      }
    },
    carregarFechamentoFamilia() {
      this.carregando = true;
      this.$http
        .get("api/familia/" + this.familia_id + "/fechamento" + this.filter)
        .then((response) => {
          this.familia = response.data;
          this.carregando = false;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
  mounted() {
    this.carregarFechamentoFamilia();
  },
};
</script>
<style scoped>
.lab__card {
  height: 150px;
  width: 200px;
  padding: 0px;
  margin: 0px;
  object-fit: contain;
  transition: transform 0.45s;
  color: white;
}

.title-card {
  font-weight: bold;
  font-size: 16px;
}

.box-score-blue {
  background-color: #a9cbff;
  color: #1f284d;
  border-radius: 0.5rem;
  padding: 0.2rem;
  font-weight: bold;
}

.box-score-red {
  background-color: #ffb6b6;
  color: #732f2f;
  border-radius: 0.5rem;
  padding: 0.2rem;
  font-weight: bold;
}

.box-score-green {
  background-color: #a4d095;
  color: #2a4422;
  border-radius: 0.5rem;
  padding: 0.2rem;
  font-weight: bold;
}

.box-score-grey {
  background-color: #ececec;
  color: #464646;
  border-radius: 0.5rem;
  padding: 0.2rem;
  font-weight: bold;
}

.valor-atual {
  font-size: 22px;
}

.semana-atual {
  font-size: 10px;
  font-weight: 100;
  opacity: 0.7;
}

.valor-anterior {
  font-size: 12px;
}

.semana-anterior {
  font-size: 10px;
  font-weight: 100;
  opacity: 0.7;
}

.container-analytics {
  font-size: small;
  color: rgb(116, 116, 116);
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.container-analytics .analytics-red {
  padding: 0.2rem;
  background-color: rgb(255, 224, 224);
  color: rgb(255, 105, 105);
  border-radius: 0.5rem;
}

.container-analytics .analytics-blue {
  padding: 0.2rem;
  background-color: rgb(225, 232, 255);
  color: rgb(108, 118, 255);
  border-radius: 0.5rem;
}

.container-analytics .analytics-green {
  padding: 0.2rem;
  background-color: rgb(215, 255, 215);
  color: rgb(72, 172, 72);
  border-radius: 0.5rem;
}

.container-analytics .row-hoje {
  border: 1px solid #1f1f1f;
  border-radius: 0.5rem;
}

.container-analytics .row-ontem {
  font-size: x-small;
}

.container-analytics .row-ontem .col-ontem {
  padding: 0.2rem;
  border-radius: 0.5rem;
}

.col-semana {
  padding: 0px;
  font-size: x-small;
}
</style>
