<template>
    <div v-if="trend">
        <b-card no-body class="p-0 m-0">
            <template #header>
                <b-card-title class="text-center p-0 m-0 title">{{ title }}</b-card-title>
            </template>

            <b-col v-if="graficos == 1" class="mt-3">
                <b-row align-v="stretch">
                    <b-col class="">
                        <b-card style="height:100%;">
                            <b-row align-h="center">
                                {{ trend.caracteristica.ponto }}
                                {{ trend.caracteristica.ax.toUpperCase() }}
                            </b-row>
                            <b-row align-h="center">
                                Especificado: {{
                                    trend.caracteristica.specifieds[0] |
                                        duasCasas
                                
                                
                                }}
                            </b-row>
                            <b-row align-h="center">
                                {{ trend.caracteristica.desvios.length }} Controle(s)
                            </b-row>
                            <b-row align-h="center">
                                Tamanho amostral
                            </b-row>
                        </b-card>
                    </b-col>
                    <b-col class="">
                        <b-card>
                            <b-row class="text-center">
                                <b-col>{{ indicadorCp }}: {{ trend.caracteristica.cp | duasCasas }}
                                </b-col>
                                <b-col>{{ indicadorCpk }}: {{ trend.caracteristica.cpk | duasCasas }}
                                </b-col>
                            </b-row>
                            <b-row class="text-center">
                                <b-col>Media: {{
                                    trend.caracteristica.med[0] |
                                        duasCasas
                                
                                
                                }}
                                </b-col>
                                <b-col>Range: {{
                                    trend.caracteristica.range[0] |
                                        duasCasas
                                
                                
                                }}
                                </b-col>
                            </b-row>
                            <b-row class="text-center">
                                <b-col> <span class="red">LIE:</span> {{
                                    trend.caracteristica.lie[0] | duasCasas
                                }}</b-col>
                                <b-col> <span class="red">LSE:</span> {{
                                    trend.caracteristica.lse[0] | duasCasas
                                }}</b-col>
                            </b-row>
                            <b-row class="text-center">
                                <b-col> <span class="blue">LIC:</span> {{
                                    trend.caracteristica.lic[0] | duasCasas
                                }}</b-col>
                                <b-col> <span class="blue">LSC:</span> {{
                                    trend.caracteristica.lsc[0] | duasCasas
                                }}</b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>

            </b-col>

            <b-row align-v="center">
                <b-col :cols="graficos != 1 ? 8 : 12" class="m-0 p-0">
                    <div id="chart-line2">
                        <apexchart type="line" :height="height" :options="chartOptions" :series="series"></apexchart>
                    </div>
                </b-col>
                <b-col cols="4" v-if="graficos == 2" class="bold">
                    <b-row>
                        <b-col>
                            <b-card no-body class="m-1 p-2">
                                <b-row align-h="center">
                                    {{ trend.caracteristica.ponto }}
                                    {{ trend.caracteristica.ax.toUpperCase() }}
                                </b-row>
                                <b-row align-h="center">
                                    Especificado: {{
                                        trend.caracteristica.specifieds[0] |
                                            duasCasas
                                    
                                    
                                    }}
                                </b-row>
                                <b-row align-h="center">
                                    {{ trend.caracteristica.desvios.length }} Controle(s)
                                </b-row>
                                <b-row align-h="center">
                                    Tamanho amostral
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-card no-body class="m-1 p-2">
                                <b-row class="text-center">
                                    <b-col>{{ indicadorCp }}: {{ trend.caracteristica.cp | duasCasas }}
                                    </b-col>
                                    <b-col>{{ indicadorCpk }}: {{ trend.caracteristica.cpk | duasCasas }}
                                    </b-col>
                                </b-row>
                                <b-row class="text-center">
                                    <b-col>Media: {{
                                        trend.caracteristica.med[0] |
                                            duasCasas
                                    
                                    
                                    }}
                                    </b-col>
                                    <b-col>Range: {{
                                        trend.caracteristica.range[0] |
                                            duasCasas
                                    
                                    
                                    }}
                                    </b-col>
                                </b-row>
                                <b-row class="text-center">
                                    <b-col> <span class="red">LIE:</span> {{
                                        trend.caracteristica.lie[0] | duasCasas
                                    }}</b-col>
                                    <b-col> <span class="red">LSE:</span> {{
                                        trend.caracteristica.lse[0] | duasCasas
                                    }}</b-col>
                                </b-row>
                                <b-row class="text-center">
                                    <b-col> <span class="blue">LIC:</span> {{
                                        trend.caracteristica.lic[0] | duasCasas
                                    }}</b-col>
                                    <b-col> <span class="blue">LSC:</span> {{
                                        trend.caracteristica.lsc[0] | duasCasas
                                    }}</b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col cols="4" v-if="graficos == 3" class="bold">
                    <b-row align-v="stretch">
                        <b-col class="m-0 p-0 box-info" cols="auto">
                            <b-card no-body class="m-0 p-1" style="height:100%;">
                                <b-row align-h="center">
                                    {{ trend.caracteristica.ponto }}
                                    {{ trend.caracteristica.ax.toUpperCase() }}

                                </b-row>
                                <b-row align-h="center">
                                    Especificado: {{ trend.caracteristica.specifieds[0] | duasCasas }}
                                </b-row>
                                <b-row align-h="center">
                                    {{ trend.caracteristica.desvios.length }} Controle(s)
                                </b-row>
                                <b-row align-h="center">
                                    Tamanho amostral
                                </b-row>
                            </b-card>
                        </b-col>
                        <b-col class="pl-1 pr-4">
                            <b-card no-body class="m-0 p-1">
                                <b-row class="text-center">
                                    <b-col>{{ indicadorCp }}: {{ trend.caracteristica.cp | duasCasas }}
                                    </b-col>
                                    <b-col>{{ indicadorCpk }}: {{ trend.caracteristica.cpk | duasCasas }}
                                    </b-col>
                                </b-row>
                                <b-row class="text-center">
                                    <b-col>Media: {{
                                        trend.caracteristica.med[0] |
                                            duasCasas
                                    
                                    
                                    }}
                                    </b-col>
                                    <b-col>Range: {{
                                        trend.caracteristica.range[0] |
                                            duasCasas
                                    
                                    
                                    }}
                                    </b-col>
                                </b-row>
                                <b-row class="text-center">
                                    <b-col> <span class="red">LIE:</span> {{
                                        trend.caracteristica.lie[0] | duasCasas
                                    }}</b-col>
                                    <b-col> <span class="red">LSE:</span> {{
                                        trend.caracteristica.lse[0] | duasCasas
                                    }}</b-col>
                                </b-row>
                                <b-row class="text-center">
                                    <b-col> <span class="blue">LIC:</span> {{
                                        trend.caracteristica.lic[0] | duasCasas
                                    }}</b-col>
                                    <b-col> <span class="blue">LSC:</span> {{
                                        trend.caracteristica.lsc[0] | duasCasas
                                    }}</b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>

                </b-col>

            </b-row>

        </b-card>
    </div>
</template>

<script>
export default {
    props: {
        indicador:null,
        param: null,
        trend: null,
        title: null,
        height: "",
        graficos: null,
    },
    data() {
        return {
            series: [
                {
                    name: "Control points",
                    data: []
                },
                {
                    name: "Average",
                    data: []
                },
                {
                    name: "Specified",
                    data: []
                },
                {
                    name: "LIE",
                    data: []
                },
                {
                    name: "LSE",
                    data: []
                },
                {
                    name: "LIC",
                    data: []
                },
                {
                    name: "LSC",
                    data: []
                },
            ],
            chartOptions: {
                chart: {
                    animations: {
                        enabled: false,
                    },
                    id: 'chart2',
                    type: 'line',
                    toolbar: {
                        show: false,
                    },
                },
                stroke: {
                    width: [3, 1, 2, 2, 2, 2, 2],
                    curve: 'smooth',
                    dashArray: [0, 0, 0, 0, 0, 5, 5]
                },
                colors: ['#000000', '#404040', '#009933', '#cc0000', '#cc0000', '#0066cc', '#0066cc'],
                dataLabels: {
                    enabled: false
                },
                fill: {
                    opacity: 1,
                },
                markers: {
                    size: [6, 0, 0, 0, 0, 0, 0]
                },
                legend: {
                    show: false,
                    position: "top",
                    offsetX: 0,
                    offsetY: 10,
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    offsetX: -10,
                    decimalsInFloat: 2,
                }
            },
        };
    },
    computed: {
        indicadorCp() {
            return this.indicador == "cpk" ? "CP" : "PP";
        },
        indicadorCpk() {
            return this.indicador == "cpk" ? "CPK" : "PPK";
        },
    },
    mounted() {
        if (this.trend)
            this.updateGrafico();
    },
    methods: {
        updateGrafico() {
            this.series[0].data = this.trend.caracteristica.desvios;
            this.series[1].data = this.trend.caracteristica.med;
            this.series[2].data = this.trend.caracteristica.specifieds;
            this.series[3].data = this.trend.caracteristica.lie;
            this.series[4].data = this.trend.caracteristica.lse;
            this.series[5].data = this.trend.caracteristica.lic;
            this.series[6].data = this.trend.caracteristica.lsc;
            this.chartOptions = {
                chart: {
                    animations: {
                        enabled: false,
                    },
                    id: 'chart2',
                    type: 'line',
                    toolbar: {
                        show: false,
                    },
                },
                stroke: {
                    width: [3, 1, 2, 2, 2, 2, 2],
                    curve: 'smooth',
                    dashArray: [0, 0, 0, 0, 0, 5, 5]
                },
                colors: ['#000000', '#404040', '#009933', '#cc0000', '#cc0000', '#0066cc', '#0066cc'],
                dataLabels: {
                    enabled: false
                },
                fill: {
                    opacity: 1,
                },
                markers: {
                    size: [6, 0, 0, 0, 0, 0, 0]
                },
                legend: {
                    show: false,
                    position: "top",
                    offsetX: 0,
                    offsetY: 10,
                },
                yaxis: {
                    offsetX: -10,
                    decimalsInFloat: 2,
                },
                xaxis: {
                    labels: {
                        hideOverlappingLabels: false,
                        trim: true,
                        maxHeight: 80,
                        maxWidth: 80,
                        rotate: false,
                        style: {
                            fontSize: '10px',
                        },
                        formatter: function (timestamp) {
                            const d = new Date(timestamp).toLocaleDateString("pt-br");
                            const h = new Date(timestamp).toLocaleTimeString("pt-br");
                            //return d + " " + h;
                            return d;
                        },
                    },
                    categories: this.trend.datas,
                },
            };

            if (this.param) {
                if (this.param.eixo_vertical_automatico == 0) {
                    this.chartOptions.yaxis = {
                        offsetX: -10,
                        decimalsInFloat: 2,
                        tickAmount: this.param.eixo_vertical_divisoes,
                        min: this.param.eixo_vertical_minimo,
                        max: this.param.eixo_vertical_maximo,
                    }
                }
            }
        },
    },
    watch: {
        trend: function () {
            if (this.trend)
                this.updateGrafico();
        },
    },
};
</script>
<style scoped>
.title {
    font-size: small;
}

.grafic-area {
    height: 100px;
}

.red {
    color: red;
}

.blue {
    color: blue;
}

.bold {
    font-weight: bold;
    font-size: 10px;
}

.box-info {
    min-width: 120px;
}
</style>
