<template>
  <div class="app" v-if="user">
    <Sidebar />
    <b-container fluid class="wrapper">
      <Menu />
      
      <b-alert show variant="warning" class="my-0 mt-2" dismissible v-if="user.user.prazo_trial && user.user.stripe_id == null && projectType == 'web'">
        <b>Teste grátis,</b> Faltam {{ user.user.prazo_trial }} dias para terminar!
      </b-alert>
    
      <router-view />
    </b-container>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import Sidebar from "./Sidebar.vue";
import { prototype } from "apexcharts";
export default {
  components: {
    Menu,
    Sidebar,
  },
  data() {
    return {};
  },
  computed: {
    projectType(){
      return this.$store.state.projectType
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    configuracoes() {
      this.$bvModal.show("config-geral");
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss">
:root {
  --primary: #5180ba !important;
  --primary-alt: #5180ba !important;
  --grey: #64748b !important;
  --dark: #1e293b !important;
  --dark-alt: #334155 !important;
  --light: #f1f5f9 !important;
  --sidebar-width: 300px !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Fira sans", sans-serif;
}

body {
  background: var(--light);
}

button {
  cursor: pointer;
  appearance: none;
  border: none;
  outline: none;
  background: none;
}

.app {
  display: flex;

  main {
    flex: 1 1 0;
    padding: 2rem;

    @media (max-width: 1024px) {
      padding-left: 6rem;
    }
  }
}

.wrapper {
  overflow: auto;
  height: 100vh;
}
</style>