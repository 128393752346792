<template>
    <b-modal id="modal-turno" size="lg" :title=" $t('title.turno') ">
        <template #modal-header>
            <!-- Emulate built in modal header close button action -->
            <h5>{{ $t('title.turno') }}</h5>
            <div class="btn">
                <b-icon icon="x" @click="close()"></b-icon>
            </div>
        </template>
        <b-form v-if="show">
            <b-form-group :label=" $t('label.descricao') ">
                <b-form-input v-model="form.descricao" type="text" :placeholder=" $t('placeholder.entre-com-a-descricao') " required>
                </b-form-input>
            </b-form-group>

            <b-form-group :label=" $t('label.inicio')">
                <b-form-input v-model="form.inicio" type="time"  required>
                </b-form-input>
            </b-form-group>

            <b-form-group :label=" $t('label.fim')">
                <b-form-input v-model="form.fim" type="time"  required>
                </b-form-input>
            </b-form-group>
            
        </b-form>

        <b-alert show variant="danger" v-if="menssagem">
            <ul v-if="menssagem">
                <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
            </ul>
        </b-alert>

        <template #modal-footer>
            <div class="w-100">
                <b-row align-v="center">
                    <b-col cols="12" md="8">
                        <b-row align-v="center" align-h="center">
                            <b-col>
                                <p class="h1 ml-3">
                                    <b-icon icon="exclamation-octagon" variant="primary"></b-icon>
                                </p>
                            </b-col>
                            <b-col cols="12" md="10">
                                <p class="pt-2">
                                    <span class="text-primary"> {{ $t('modal.importante') }}</span><br />
                                    {{ $t('modal.preenchaTodosOsDadosCorretamente') }} </p>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="6" md="4">
                        <b-button variant="primary" class="float-right" v-if="carregando" disabled>
                            <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
                            {{ $t('botoes.carregando') }} </b-button>

                        <b-button v-else type="submit" variant="primary" class="float-right" @click="salvar()">
                            {{ $t('botoes.salvar') }} </b-button>

                        <b-button v-if="turno.id" type="submit" variant="danger" class="float-right mr-2" @click="
                            showMsgConfirmation(
                                'Tem certeza que deseja remover esse turno?'
                            )
                        ">
                            {{ $t('botoes.deletar') }} </b-button>
                    </b-col>
                </b-row>
            </div>
        </template>
    </b-modal>
</template>

<script>
export default {
    props: {
        turno: {
            type: Object,
            default: function () {
                return {
                    id: null,                    
                    descricao: null,
                    inicio: null,
                    fim: null
                };
            },
        },
    },
    data() {
        return {
            form: {
                descricao: null,
                inicio: null,
                fim: null,
            },
            turnos: [],
            show: true,
            menssagem: null,
            carregando: null,
            url: "api/user",
        };
    },
    methods: {
        makeToast(variant = null) {
            this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
                 title: this.$t('toast.sucesso'),
                variant: variant,
                solid: true,
            });
        },
        close() {
            this.$bvModal.hide("modal-turno");
        },
        limpar() {
            this.form.descricao = null;
            this.form.inicio = null;
            this.form.fim = null;
            this.menssagem = null;
        },
        salvar() {
            this.menssagem = null;
            this.carregando = true;
            let formData = new FormData();
            formData.append("descricao", this.form.descricao);
            formData.append("inicio", this.form.inicio);            
            formData.append("fim", this.form.fim);

            if (this.turno.id) {
                formData.append("_method", "patch");                
            }

            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                },
            };

            this.$http
                .post(this.url, formData, config)
                .then((response) => {
                    this.makeToast("success");
                    this.$bvModal.hide("modal-turno");
                    this.carregando = false;
                    this.$emit("listaMudou");
                })
                .catch((errors) => {
                    this.menssagem = errors.response.data.errors;
                    this.carregando = false;
                });
        },
        deletar() {
            let formData = new FormData();
            formData.append("_method", "delete");

            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                },
            };

            this.$http
                .post(this.url, formData, config)
                .then((response) => {
                    this.makeToast("success");
                    this.$bvModal.hide("modal-turno");
                    this.carregando = false;
                    this.$emit("listaMudou");
                })
                .catch((errors) => {
                    this.menssagem = errors.response.data.errors;
                    this.carregando = false;
                });
        },
        showMsgConfirmation(msg) {
            this.$bvModal
                .msgBoxConfirm(msg, {
                    title: this.$t('title.confirme-por-favor'),
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: this.$t('confirmacao.sim'),
                    cancelTitle: this.$t('confirmacao.nao'),
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value == true) {
                        this.deletar();
                    } else {
                        return false;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        carregarTurnos() {
            this.$bvModal.show("modal-carregando-geral");
            this.$http
                .get("api/turno")
                .then((response) => {
                    this.turnos = []
                    response.data.forEach((element) => {
                        this.turnos.push({
                            value: element.id,
                            text: element.descricao,
                        });
                    });
                    this.$bvModal.hide("modal-carregando-geral");
                })
                .catch((errors) => {
                    console.log(errors);
                    this.$bvModal.hide("modal-carregando-geral");
                });
        },
    },
    mounted() {
        this.carregarTurnos();
    },
    watch: {
        turno: function (turno) {
            if (this.turno.id) {
                this.form.descricao = turno.descricao;
                this.form.inicio = turno.inicio;
                this.form.fim = turno.fim;
                this.url = "api/turno/" + this.turno.id;
            } else {
                this.url = "api/turno";
                this.limpar();
            }
        }
    },
};
</script>

<style >
</style>