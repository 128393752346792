<template>
  <div>
    <b-card  no-body v-if="!risk">
    <div id="chart-root">
      <apexchart
        type="bar"
        height="120"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
    </b-card>
    <div id="chart-root" v-else>
      <apexchart
        type="bar"
        :height="height"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    risk:false,
    height:null,
    analysis: null,    
  },
  data() {
    return {
      categories: this.analysis.categorias,
      colors: this.analysis.cores,
      series: [
        {
          name: "Root",
          data: this.analysis.valores,
        },
      ],
      chartOptions: {
        title: {
          text: "Root Cause",
          align: "center",
          margin: 5,
          floating: true,
          style: {
            fontSize: "12px",
            fontWeight: "bold",
          },
        },
        grid: {
          padding: {
            left: 0,
            right: 15,
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          height: 80,
          type: "bar",
          width: "100%",
          parentHeightOffset: 0,
        },
        plotOptions: {
          bar: {
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "11px",
            colors: ["#1C1C1C"],
          },
        },
        legend: {
          show: false,
        },
        noData: {
          align: "center",
          text: "No data available at the moment",
          verticalAlign: "middle",
        },
        colors: this.analysis.cores,
        xaxis: {
          labels: {
            show: true,
            style: {
              fontSize: this.risk ? '12px': "7px",
            },
          },
          categories: this.analysis.categorias,
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
      },
    };
  },
  mounted() {
    this.updateGrafico();
  },
  methods: {    
    updateGrafico() {    
      var arrayDados = this.analysis.valores     
      this.series[0].data = arrayDados;   
      this.chartOptions = {       
        title: {          
          text: "Root Cause",
          align: "center",
          margin: 5,
          floating: true,
          style: {
            fontSize: "12px",
            fontWeight: "bold",
          },
        },
        grid: {
          padding: {
            left: 0,
            right: 15,
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
          height: this.height ? this.height : 118,
          type: "bar",
          width: "100%",
          parentHeightOffset: 0,
        },
        plotOptions: {
          bar: {
            distributed: true,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "11px",
            colors: ["#1C1C1C"],
          },
        },
        legend: {
          show: false,
        },
        noData: {
          align: "center",
          text: "No data available at the moment",
          verticalAlign: "middle",
        },
        colors: this.analysis.cores,
        xaxis: {
          labels: {
            show: true,
            style: {
              fontSize: this.risk ? '12px': "7px",
            },
          },
          categories: this.analysis.categorias,
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
      
      };  
    },
  },
  watch: {  
    analysis:{
      deep: true,
      immediate: true,
      handler() {
        this.updateGrafico();
      }
    },    
    semanas: function () {
      this.updateGrafico();
    },
    verde: function () {
      this.updateGrafico();
    },
    amarelo: function () {
      this.updateGrafico();
    },
  },
};
</script>
