<template>
  <b-card no-body>
    <b-row class="text-center">
      <b-col>
        <b-row class="text-center p-1 header" align-v="center">
          <b-col lg="3"
            ><b-img
              center
              height="30"
              :src="resultado.relatorio.componente.imagem | urlImagem"
          /></b-col>
          <b-col lg="2"><span class="sequencia">1</span></b-col>
          <b-col
            >{{ resultado.relatorio.componente.descricao }} -
            {{ resultado.relatorio.componente.desenho }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card v-if="resultado" :loading="resultado" no-body>
          <table width="100%" class="comBordaComplexa">
            <tr>
              <td></td>
              <td style="background-color: #e8e8e8">Pts</td>
              <td style="background-color: #e8e8e8">%</td>
              <td style="background-color: #e8e8e8">Pts</td>
              <td style="background-color: #e8e8e8">%</td>
              <td style="background-color: #e8e8e8">Pts</td>
              <td style="background-color: #e8e8e8">%</td>
              <td style="background-color: #e8e8e8">{{ $t('table.title.total') }}</td>
            </tr>
            <tr>
              <td style="background-color: #e8e8e8">CG</td>
              <td style="background-color: #00FF05">
                {{ resultado.fechamento.geral_cg_verde | digitos(3) }}
              </td>
              <td style="background-color: #00FF05">
                {{ resultado.fechamento.geral_pc_cg_verde | duasCasas }}%
              </td>
              <td style="background-color: yellow">
                {{ resultado.fechamento.geral_cg_amarelo | digitos(3) }}
              </td>
              <td style="background-color: yellow">
                {{ resultado.fechamento.geral_pc_cg_amarelo | duasCasas }}%
              </td>
              <td style="background-color: red; color: white">
                {{ resultado.fechamento.geral_cg_vermelho | digitos(3) }}
              </td>
              <td style="background-color: red; color: white">
                {{ resultado.fechamento.geral_pc_cg_vermelho | duasCasas }}%
              </td>
              <td style="background-color: white">
                {{ resultado.fechamento.geral_total_cg | digitos(3) }}
              </td>
            </tr>
            <tr>
              <td style="background-color: #e8e8e8"> {{indicadorCp}}</td>
              <td style="background-color: #00FF05">
                {{ resultado.fechamento.geral_cp_verde | digitos(3) }}
              </td>
              <td style="background-color: #00FF05">
                {{ resultado.fechamento.geral_pc_cp_verde | duasCasas }}%
              </td>
              <td style="background-color: yellow">
                {{ resultado.fechamento.geral_cp_amarelo | digitos(3) }}
              </td>
              <td style="background-color: yellow">
                {{ resultado.fechamento.geral_pc_cp_amarelo | duasCasas }}%
              </td>
              <td style="background-color: red; color: white">
                {{ resultado.fechamento.geral_cp_vermelho | digitos(3) }}
              </td>
              <td style="background-color: red; color: white">
                {{ resultado.fechamento.geral_pc_cp_vermelho | duasCasas }}%
              </td>
              <td style="background-color: white">
                {{ resultado.fechamento.geral_total_cp | digitos(3) }}
              </td>
            </tr>
            <tr>
              <td style="background-color: #e8e8e8"> {{indicadorCp}}K</td>
              <td style="background-color: #00FF05">
                {{ resultado.fechamento.geral_cpk_verde | digitos(3) }}
              </td>
              <td style="background-color: #00FF05">
                {{ resultado.fechamento.geral_pc_cpk_verde | duasCasas }}%
              </td>
              <td style="background-color: yellow">
                {{ resultado.fechamento.geral_cpk_amarelo | digitos(3) }}
              </td>
              <td style="background-color: yellow">
                {{ resultado.fechamento.geral_pc_cpk_amarelo | duasCasas }}%
              </td>
              <td style="background-color: red; color: white">
                {{ resultado.fechamento.geral_cpk_vermelho | digitos(3) }}
              </td>
              <td style="background-color: red; color: white">
                {{ resultado.fechamento.geral_pc_cpk_vermelho | duasCasas }}%
              </td>
              <td style="background-color: white">
                {{ resultado.fechamento.geral_total_cpk | digitos(3) }}
              </td>
            </tr>
          </table>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="text-center datas">
      <b-col>
        <b-row class="text-center">
          <b-col>{{ $t('workflow.data-loop-de-melhoria') }}</b-col>
          <b-col>
            {{
              moment(resultado.relatorio.data_loop_atual).format("DD/MM/YYYY")
            }}
          </b-col></b-row
        >
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import moment from "moment";
export default {
  name: "BoxIndicadoresWorkflow",
  props: {
    resultado: null,
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
  },
  computed: {
    indicadorCp() {
      return this.resultado.relatorio.componente.indicador == "cp" ? "CP" : "PP";
    },
  },
};
</script>

<style scoped>
table.comBordaComplexa {
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  border-collapse: collapse; /* CSS2 */
  background: #fffff0;
}

table.comBordaComplexa td {
  border: 2px solid white;
}

table.comBordaComplexa th {
  border: 1px solid blue;
  background: #f0fff0;
}
.header {
  font-weight: bold;
  font-size: 12px;
}
.datas {
  font-weight: bold;
  font-size: 12px;
  margin-top: 5px;
}
.data_top_foto {
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  width: 100%;
  background-color: #e8e8e8;
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 5px;
  margin-bottom: 5px;
  border: 1px solid #b5b5b5;
}
.sequencia {
  width: 20px;
  height: 20px;
  background-color: #dadada;
  border-radius: 50%;

  border: 1px solid #b5b5b5;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}
</style>