<template>
    <router-link class="back-to-top d-flex align-items-center justify-content-center" v-if="scrollPosition > 100"
        data-aos="fade-up" data-aos-delay="200" to="#hero">
        <b-icon icon="arrow-up-short"></b-icon></router-link>
</template>

<script>
export default {
    name: 'BackToTop',
    data() {
        return {
            scrollPosition: 0,
        };
    },
    methods: {
        handleScroll(event) {
            this.scrollPosition = window.scrollY;
        }
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
}
</script>


<style>

</style>