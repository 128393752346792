<template>
    <b-modal id="modal-trend-grafico-configuracao" size="md" centered :title="$t('title.configurar-grafico')" hide-footer>
        <template #modal-header>
            <h5 class="m-0 p-0">{{ $t("title.configurar-grafico") }}</h5>
            <p class="h4 btn m-0 p-0"><b-icon icon="x" @click="close()"></b-icon></p>
        </template>
        <b-form v-if="grafico">
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="Eixo Vertical" active>
                        <b-card-text>
                            <b-form-checkbox id="checkbox-1" v-model="grafico.eixo_vertical_automatico"
                                name="checkbox-1" :value="1" :unchecked-value="0">
                                Automático
                            </b-form-checkbox>
                        </b-card-text>

                        <b-form-group label="Divisões">
                            <b-form-select :options="divisoes" v-model="grafico.eixo_vertical_divisoes"
                                :disabled="grafico.eixo_vertical_automatico == 1">
                            </b-form-select>
                        </b-form-group>

                        <b-form-group label="Máximo">
                            <b-form-input v-model="grafico.eixo_vertical_maximo"
                                :disabled="grafico.eixo_vertical_automatico == 1">
                            </b-form-input>
                        </b-form-group>

                        <b-form-group label="Mínimo">
                            <b-form-input v-model="grafico.eixo_vertical_minimo"
                                :disabled="grafico.eixo_vertical_automatico == 1">
                            </b-form-input>
                        </b-form-group>


                    </b-tab>
                    <b-tab title="Eixo Horizontal">
                        <b-card-text><b-form-group label="Formato da Data">
                                <b-form-select :options="formatos" v-model="grafico.eixo_horizontal_formato">
                                </b-form-select>
                            </b-form-group></b-card-text>
                    </b-tab>
                    <b-tab title="Indicador">
                        <b-card-text>
                            <b-form-group :label="$t('label.indicador')"
                                :description="$t('descricao.selecione-o-tipo-de-indicador-que-sera-usado-para-os-calculos')">
                                <b-form-select :options="indicadores" v-model="grafico.indicador"
                                    required></b-form-select>
                            </b-form-group>

                            <b-form-group label="Mínimo">
                                <b-form-select :options="tamanho_subgrupo" v-model="grafico.tamanho_amostra"
                                    :disabled="grafico.indicador == 'ppk'"></b-form-select>
                            </b-form-group>

                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-form>
    </b-modal>
</template>

<script>
export default {
    props: {
        grafico: null,
    },
    data() {
        return {
            divisoes: [2, 3, 4, 5, 6, 7, 8, 9, 10],
            formatos: [
                'dd/mm/yyyy HH:mm',
                'dd/mm/yy HH:mm:ss',
                'dd/mm/yy HH:mm',
                'dd/mm HH:mm',
                'dd/mm/yy',
                'dd/mm',
                'dd',
            ],
            indicadores: [
                { value: "cpk", text: "Cp & Cpk" },
                { value: "ppk", text: "Pp & Ppk" },
            ],
            tamanho_subgrupo: [
                { value: 2, text: 2 },
                { value: 3, text: 3 },
                { value: 4, text: 4 },
                { value: 5, text: 5 },
            ],
        };
    },
    methods: {
        close() {
            this.$bvModal.hide("modal-trend-grafico-configuracao");
        },
    },
};
</script>

<style>

</style>