<template>
  <b-modal id="modal-familia" size="lg" :title=" $t('title.automarb-familia') ">
    <template #modal-header>
      <!-- Emulate built in modal header close button action -->
      <h5>{{ $t('title.automarb-familia') }}</h5>
      <p class="h4 btn"><b-icon icon="x" @click="close()"></b-icon></p>
    </template>
    <b-form v-if="show">
      <b-form-group
        id="input-group-1"
        :label=" $t('labe.nome') "
        label-for="input-1"
        :description=" $t('descricao.digite-um-nome-para-criar-e-nomear-uma-familia-de-pecas-conjuntos-componentes-ect')"
      >
        <b-form-input
          id="input-1"
          v-model="form.nome"
          type="text"
          :placeholder=" $t('placeholder.entre-com-o-nome') "
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" :label=" $t('label.imagem') " label-for="input-2">
        <b-form-file
          accept="image/*"
          v-model="form.imagem"
          :placeholder=" $t('placeholder.escolha-uma-imagem-ou-solte-o-aqui') "
          :drop-placeholder=" $t('drop-placeholder.solte-a-imagem-aqui') "
        ></b-form-file>
      </b-form-group>
    </b-form>

    <b-alert show variant="danger" v-if="menssagem">
      <ul v-if="menssagem">
        <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
      </ul>
    </b-alert>

    <template #modal-footer>
      <div class="w-100">
        <b-row align-v="center">
          <b-col cols="12" md="8">
            <b-row align-v="center" align-h="center">
              <b-col
                ><p class="h1 ml-3">
                  <b-icon
                    icon="exclamation-octagon"
                    variant="primary"
                  ></b-icon></p
              ></b-col>
              <b-col cols="12" md="10">
                <p class="pt-2">
                  <span class="text-primary"> {{ $t('modal.importante') }}</span><br />
                  {{ $t('modal.preenchaTodosOsDadosCorretamente') }} </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" md="4">
            <b-button
              variant="primary"
              class="float-right"
              v-if="carregando"
              disabled
            >
             <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
              {{ $t('botoes.carregando') }} </b-button>

            <b-button
              v-else
              type="submit"
              variant="primary"
              class="float-right"
              @click="salvar()"
            >
              {{ $t('botoes.salvar') }} </b-button>

            <b-button
              v-if="familia.id && (user.permissao =='acesso_total')"
              type="submit"
              variant="danger"
              class="float-right mr-2"
              @click="
                showMsgConfirmation(
                  'Tem certeza que deseja remover esse familia com todos seus componentes?'
                )
              "
            >
              {{ $t('botoes.deletar') }} </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    familia: {
      type: Object,
      default: function () {
        return {
          id: null,
          descricao: null,
        };
      },
    },
  },
  data() {
    return {
      form: {
        nome: null,
        imagem: null,
      },
      show: true,
      menssagem: null,
      carregando: null,
      url: "api/familia",
    };
  },
  methods: {
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
         title: this.$t('toast.sucesso'),
        variant: variant,
        solid: true,
      });
    },
    close() {
      //this.limpar();
      this.$bvModal.hide("modal-familia");
    },
    limpar() {
      this.form.nome = "";
      this.form.imagem = null;
      this.menssagem = null;
    },
    salvar() {
      this.menssagem = null;
      this.carregando = true;
      let formData = new FormData();
      formData.append("descricao", this.form.nome);

      if (this.form.imagem) {
        formData.append("imagem", this.form.imagem);
      }

      if (this.familia.id) {
        formData.append("_method", "patch");
      }

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post(this.url, formData, config)
        .then((response) => {
          this.makeToast("success");
          this.$bvModal.hide("modal-familia");
          this.carregando = false;
          this.$emit("listaMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    deletar() {
      let formData = new FormData();
      formData.append("_method", "delete");

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post(this.url, formData, config)
        .then((response) => {
          this.makeToast("success");
          this.$bvModal.hide("modal-familia");
          this.carregando = false;
          this.$emit("listaMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    showMsgConfirmation(msg) {
      this.$bvModal
        .msgBoxConfirm(msg, {
          title: this.$t('title.confirme-por-favor'),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t('confirmacao.sim'),
          cancelTitle: this.$t('confirmacao.nao'),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.deletar();
          } else {
            return false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    user() {
            return this.$store.state.auth.user.user;
        },
  },    
  watch: {
    familia: function (familia) {
      if (this.familia.id) {
        this.form.nome = familia.descricao;
        this.url = "api/familia/" + this.familia.id;
      } else{        
        this.url = "api/familia"
        this.limpar()
      }
    },
  },
};
</script>

<style >
</style>