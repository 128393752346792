<template>
  <div class="home pt-3">
    <b-breadcrumb>
      <b-breadcrumb-item active class="align-middle">
        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
        {{ $t("breadcrumb.analise-linhas") }}
      </b-breadcrumb-item>
      <div class="ml-auto" size="sm">{{
        $t('fechamento-linhas.os-dados-sao-atualizados-automaticamente-a-cada-5-minutos') }}</div>

    </b-breadcrumb>

    <SelecaoPeriodo :mostrarTurno="true" :mostrarVisualizacao="true" />

    <b-container fluid>
      <b-row align-h="start" v-if="!carregando">
        <div class="p-3" v-for="linha in linhas" :key="linha.id">
          <!-- <router-link :to="'/linha/' + linha.id" style="text-decoration: none; color: inherit"> -->
          <b-card class="text-center px-3 pb-3" no-body @mouseover="isHovering = true" @mouseout="isHovering = false">

            <b-card-text class="title-card mt-3">
              {{ linha.descricao }}
            </b-card-text>

            <div class="container-analytics" v-if="linha.turnoAtual">
              <b-row v-if="visualizacao == 1">
                <b-col class="m-0 p-0">
                  <div class="box-score-green">
                    <b-row class="indicador">
                      <b-col class="mr-0 pr-0">Aprovadas &nbsp;</b-col>
                      <b-col cols="auto"><b-icon-arrow-up v-if="linha.turnoAtual.porcentagemAprovadas >
                        linha.turnoAnterior.porcentagemAprovadas
                      " />
                        <b-icon-arrow-right v-else-if="linha.turnoAtual.porcentagemAprovadas ==
                          linha.turnoAnterior.porcentagemAprovadas
                        " />
                        <b-icon-arrow-down v-else /></b-col>
                    </b-row>
                    <b-row class="valor-atual justify-content-md-end pr-1">
                      <b-col cols="auto">{{ linha.turnoAtual.porcentagemAprovadas | umaCasa }}%</b-col>
                    </b-row>
                    <b-row class="semana-atual justify-content-md-end pr-1">
                      <b-col cols="auto">{{ linha.turnoAtual.periodo }} {{ linha.turnoAtual.descricao }}</b-col>
                    </b-row>
                    <b-row class="valor-anterior justify-content-md-end pr-1 mt-2">
                      <b-col cols="auto">{{ linha.turnoAnterior.porcentagemAprovadas | umaCasa }}%</b-col>
                    </b-row>
                    <b-row class="semana-anterior justify-content-md-end pr-1">
                      <b-col cols="auto">{{ linha.turnoAnterior.periodo }} {{ linha.turnoAnterior.descricao }}</b-col>
                    </b-row>
                  </div>
                </b-col>
                <b-col class="m-0 p-0 pl-1">
                  <div class="box-score-red">
                    <b-row class="indicador">
                      <b-col class="mr-0 pr-0">Reprovadas</b-col>
                      <b-col cols="auto"><b-icon-arrow-up v-if="linha.turnoAtual.porcentagemReprovadas >
                        linha.turnoAnterior.porcentagemReprovadas
                      " />
                        <b-icon-arrow-right v-else-if="linha.turnoAtual.porcentagemReprovadas ==
                          linha.turnoAnterior.porcentagemReprovadas
                        " />
                        <b-icon-arrow-down v-else /></b-col>
                    </b-row>
                    <b-row class="valor-atual justify-content-md-end pr-1">
                      <b-col cols="auto">{{ linha.turnoAtual.porcentagemReprovadas | umaCasa }}%</b-col>
                    </b-row>
                    <b-row class="semana-atual justify-content-md-end pr-1">
                      <b-col cols="auto">{{ linha.turnoAtual.periodo }} {{ linha.turnoAtual.descricao }}</b-col>
                    </b-row>
                    <b-row class="valor-anterior justify-content-md-end pr-1 mt-2">
                      <b-col cols="auto">{{ linha.turnoAnterior.porcentagemReprovadas | umaCasa }}%</b-col>
                    </b-row>
                    <b-row class="semana-anterior justify-content-md-end pr-1">
                      <b-col cols="auto">{{ linha.turnoAnterior.periodo }} {{ linha.turnoAnterior.descricao }}</b-col>
                    </b-row>
                  </div>
                </b-col>

              </b-row>
              <b-row v-if="visualizacao == 2">
                <b-col class="m-0 p-0">
                  <div class="box-score-green">
                    <b-row class="indicador">
                      <b-col class="mr-0 pr-0">Aprovadas &nbsp;</b-col>
                      <b-col cols="auto"><b-icon-arrow-up v-if="linha.turnoAtual.aprovadas >
                        linha.turnoAnterior.aprovadas
                      " />
                        <b-icon-arrow-right v-else-if="linha.turnoAtual.aprovadas ==
                          linha.turnoAnterior.aprovadas
                        " />
                        <b-icon-arrow-down v-else /></b-col>
                    </b-row>
                    <b-row class="valor-atual justify-content-md-end pr-1">
                      <b-col cols="auto">{{ linha.turnoAtual.aprovadas }}/{{ linha.turnoAtual.total }}</b-col>
                    </b-row>
                    <b-row class="semana-atual justify-content-md-end pr-1">
                      <b-col cols="auto">{{ linha.turnoAtual.periodo }} {{ linha.turnoAtual.descricao }}</b-col>
                    </b-row>
                    <b-row class="valor-anterior justify-content-md-end pr-1 mt-2">
                      <b-col cols="auto">{{ linha.turnoAnterior.aprovadas }}/{{ linha.turnoAnterior.total }}</b-col>
                    </b-row>
                    <b-row class="semana-anterior justify-content-md-end pr-1">
                      <b-col cols="auto">{{ linha.turnoAnterior.periodo }} {{ linha.turnoAnterior.descricao }}</b-col>
                    </b-row>
                  </div>
                </b-col>
                <b-col class="m-0 p-0 pl-1">
                  <div class="box-score-red">
                    <b-row class="indicador">
                      <b-col class="mr-0 pr-0">Reprovadas</b-col>
                      <b-col cols="auto"><b-icon-arrow-up v-if="linha.turnoAtual.reprovadas >
                        linha.turnoAnterior.reprovadas
                      " />
                        <b-icon-arrow-right v-else-if="linha.turnoAtual.reprovadas ==
                          linha.turnoAnterior.reprovadas
                        " />
                        <b-icon-arrow-down v-else /></b-col>
                    </b-row>
                    <b-row class="valor-atual justify-content-md-end pr-1">
                      <b-col cols="auto">{{ linha.turnoAtual.reprovadas }}/{{ linha.turnoAtual.total }}</b-col>
                    </b-row>
                    <b-row class="semana-atual justify-content-md-end pr-1">
                      <b-col cols="auto">{{ linha.turnoAtual.periodo }} {{ linha.turnoAtual.descricao }}</b-col>
                    </b-row>
                    <b-row class="valor-anterior justify-content-md-end pr-1 mt-2">
                      <b-col cols="auto">{{ linha.turnoAnterior.reprovadas }}/{{ linha.turnoAnterior.total }}</b-col>
                    </b-row>
                    <b-row class="semana-anterior justify-content-md-end pr-1">
                      <b-col cols="auto">{{ linha.turnoAnterior.periodo }} {{ linha.turnoAnterior.descricao }}</b-col>
                    </b-row>
                  </div>
                </b-col>

              </b-row>
            </div>

            <div class="container-analytics" v-else>
              <b-row>
                <b-col class="m-0 p-0">
                  <div class="box-score-grey">
                    <b-row class="indicador">
                      <b-col> &nbsp;</b-col>
                      <b-col cols="auto"> &nbsp;</b-col>
                    </b-row>
                    <b-row class="valor-atual justify-content-md-end pr-1">
                      <b-col cols="auto"> &nbsp;</b-col>
                    </b-row>
                    <b-row class="semana-atual justify-content-md-end pr-1">
                      <b-col cols="auto"> &nbsp;</b-col>
                    </b-row>
                    <b-row class="valor-anterior justify-content-md-end pr-1 mt-2">
                      <b-col cols="auto"> &nbsp;</b-col>
                    </b-row>
                    <b-row class="semana-anterior justify-content-md-end pr-1">
                      <b-col cols="auto"> &nbsp;</b-col>
                    </b-row>
                  </div>
                </b-col>
                <b-col class="m-0 p-0 px-1">
                  <div class="box-score-grey">
                    <b-row class="indicador">
                      <b-col> &nbsp;</b-col>
                      <b-col cols="auto"> &nbsp;</b-col>
                    </b-row>
                    <b-row class="valor-atual justify-content-md-end pr-1">
                      <b-col cols="auto"> &nbsp;</b-col>
                    </b-row>
                    <b-row class="semana-atual justify-content-md-end pr-1">
                      <b-col cols="auto"> &nbsp;</b-col>
                    </b-row>
                    <b-row class="valor-anterior justify-content-md-end pr-1 mt-2">
                      <b-col cols="auto"> &nbsp;</b-col>
                    </b-row>
                    <b-row class="semana-anterior justify-content-md-end pr-1">
                      <b-col cols="auto"> &nbsp;</b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </div>

          </b-card>
          <!-- </router-link> -->
        </div>
      </b-row>
    </b-container>
    <modal-linha :linha="linha" @listaMudou="carregarLista()" />
    <modal-carregando-geral />
  </div>
</template>
<script>
import ModalLinha from "@/components/modal/Linha.vue";
import ModalCarregandoGeral from "@/components/modal/CarregandoGeral.vue";
import SelecaoPeriodo from "@/components/SelecaoPeriodo.vue";
export default {
  components: {
    ModalLinha,
    ModalCarregandoGeral,
    SelecaoPeriodo
  },
  computed: {
    filter(){
      return '?tipoPeriodo=' + this.$store.state.tipoPeriodo +
       '&ano=' + this.$store.state.ano + 
       '&mes=' + this.$store.state.mes + 
       '&semana=' + this.$store.state.semana + 
       '&data=' + this.$store.state.data + 
       '&turno=' + this.$store.state.turno;
    },
    user() {
      return this.$store.state.auth.user.user;
    },
    turno: {
      get() {
        return this.$store.state.turno;
      },
      set(value) {
        this.$store.commit("setTurno", value);
      },
    },
    tipoPeriodo: {
      get() {
        return this.$store.state.tipoPeriodo;
      },
      set(value) {
        this.$store.commit("setTipoPeriodo", value);
      },
    },
    mes: {
      get() {
        return this.$store.state.mes;
      },
      set(value) {
        this.$store.commit("setMes", value);
      },
    },
    semana: {
      get() {
        return this.$store.state.semana;
      },
      set(value) {
        this.$store.commit("setSemana", value);
      },
    },
    visualizacao: {
      get() {
        return this.$store.state.visualizacao;
      },
      set(value) {
        this.$store.commit("setVisualizacao", value);
      },
    },
    data: {
      get() {
        return this.$store.state.data;
      },
      set(value) {
        this.$store.commit("setData", value);
      },
    },
    ano: {
      get() {
        return this.$store.state.ano;
      },
      set(value) {
        this.$store.commit("setAno", value);
      },
    }
  },
  data() {
    return {
      interval: null,
      isHovering: false,
      linha: {
        id: null,
        descricao: null,
      },
      url: null,
      linhas: { data: [] },
      modalShow: false,
      carregando: false,
    };
  },
  methods: {
    indicadorCp(indicador) {
      return indicador == "cp" ? "CP" : "PP";
    },
    indicadorCpk(indicador) {
      return indicador == "cp" ? "CPK" : "PPK";
    },
    verificaStatusValor($atual, $anterior) {
      if ($atual > $anterior) {
        return 'box-score-green'
      } else if ($atual == $anterior) {
        return 'box-score-blue'
      } else {
        return 'box-score-red'
      }
    },
    showModalEditarLinha(linha) {
      this.linha = linha;
      this.$bvModal.show("modal-linha");
    },
    showModal() {
      this.linha = {
        id: null,
        descricao: null,
      };
      this.$bvModal.show("modal-linha");
    },



    carregarLista() {
     
      this.carregando = true;
      this.$bvModal.show("modal-carregando-geral");
      this.$http
        .get("api/fechamentoLinhaTurno" + this.filter)
        .then((response) => {
          this.linhas = response.data;
          this.carregando = false;
          this.$bvModal.hide("modal-carregando-geral");
        })
        .catch((errors) => {
          console.log(errors);
          this.$bvModal.hide("modal-carregando-geral");
        });
    },
    atualizarDados() {
      this.interval = setInterval(() => {
        this.carregarLista()
      }, 1000 * 60 * 5);
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  mounted() {
    if (this.data) {
      this.carregarLista();
      this.atualizarDados();
    }
    this.url = process.env.VUE_APP_ROOT_API;
  },
  watch: {
    tipoPeriodo: function () {
      this.carregarLista();
    },
    turno: function () {
      this.carregarLista();
    },
    data: function () {
      this.carregarLista();
    },
    mes: function () {
      this.carregarLista();
    },
    semana: function () {
      this.carregarLista();
    },
    ano: function () {
      this.carregarLista();
    },
  },
};
</script>

<style scoped>
.lab__card {
  height: 150px;
  width: 200px;
  padding: 0px;
  margin: 0px;
  object-fit: contain;
  transition: transform 0.45s;
  color: white;
}

.title-card {
  font-weight: bold;
  font-size: 16px;
}

.box-score-blue {
  background-color: #a9cbff;
  color: #1f284d;
  border-radius: 0.5rem;
  padding: 0.2rem;
  font-weight: bold;
}

.box-score-red {
  background-color: #ffb6b6;
  color: #ff0202;
  border-radius: 0.5rem;
  padding: 0.2rem;
  font-weight: bold;
}

.box-score-green {
  background-color: #A4D095;
  color: #2a4422;
  border-radius: 0.5rem;
  padding: 0.2rem;
  font-weight: bold;
}

.box-score-grey {
  background-color: #ececec;
  color: #464646;
  border-radius: 0.5rem;
  padding: 0.2rem;
  font-weight: bold;
}

.valor-atual {
  font-size: 22px;
}

.semana-atual {
  font-size: 11px;
  font-weight: bold;
  opacity: 0.7;
}

.valor-anterior {
  font-size: 12px;
}

.semana-anterior {
  font-size: 11px;
  font-weight: bold;
  opacity: 0.7;
}

.container-analytics {
  font-size: small;
  color: rgb(116, 116, 116);
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.container-analytics .analytics-red {
  padding: 0.2rem;
  background-color: rgb(255, 224, 224);
  color: rgb(255, 105, 105);
  border-radius: 0.5rem;
}

.container-analytics .analytics-blue {
  padding: 0.2rem;
  background-color: rgb(225, 232, 255);
  color: rgb(108, 118, 255);
  border-radius: 0.5rem;
}

.container-analytics .analytics-green {
  padding: 0.2rem;
  background-color: rgb(215, 255, 215);
  color: rgb(72, 172, 72);
  border-radius: 0.5rem;
}

.container-analytics .row-hoje {
  border: 1px solid #1f1f1f;
  border-radius: 0.5rem;
}

.container-analytics .row-ontem {
  font-size: x-small;
}

.container-analytics .row-ontem .col-ontem {
  padding: 0.2rem;
  border-radius: 0.5rem;
}

.col-semana {
  padding: 0px;
  font-size: x-small;
}
</style>
