<template>
  <b-modal id="modal-video" size="lg" centered hide-footer >
    <template #modal-header>
      <p class="h4 btn m-0 p-0"><b-icon icon="x" @click="close()"></b-icon></p>
    </template>

    <div ref="modalVideo">

    <video-player
      class="video-player-box"
      ref="videoPlayer"
      :options="playerOptions"
      :playsinline="true"
      customEventName="customstatechangedeventname"
      @play="onPlayerPlay($event)"
      @pause="onPlayerPause($event)"       
      @statechanged="playerStateChanged($event)"
      @ready="playerReadied"
      
    >
    </video-player>
  </div>
  </b-modal>
</template>

<script>
// require styles
import "video.js/dist/video-js.css";

import { videoPlayer } from "vue-video-player";

export default {
  components: {
    videoPlayer,
  },
  props: {},
  data() {
    return {         
      playerOptions: {
        // videojs options
        muted: false,
        language: "pt-BR",        
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        width: '750px',
        sources: [
          {
            type: "video/mp4",
            src: require("@/assets/video/automarb_apresentation.mp4"),
          },
        ],
        //poster: "/static/images/author.jpg",
      },
    };
  },
  methods: {
    close() {
      this.$bvModal.hide("modal-video");
    },
    // listen event
    onPlayerPlay(player) {
      // console.log('player play!', player)
    },
    onPlayerPause(player) {
      // console.log('player pause!', player)
    },
    // ...player event

    // or listen state event
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },

    // player is ready
    playerReadied(player) {
      //console.log("the player is readied", player);
      // you can use it to do something...
      // player.[methods]
    },
    atualizarLargura() {      
      this.playerOptions.width = this.$refs.modalVideo.offsetWidth;
    }
  },
  mounted() {
    this.atualizarLargura();   
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  watch: {},
};
</script>

<style ></style>