<template>
    <div>
        <b-row align-h="end" class="small">

            <b-col cols="auto">
                <label>{{ $t('label.tipo-periodo') }}</label>
                <b-form-select :options="tiposPeriodo" v-model="$store.state.tipoPeriodo" size="sm"></b-form-select>
            </b-col>

            <b-col cols="auto" v-if="$store.state.tipoPeriodo == 1 || $store.state.tipoPeriodo == 2" >
                <label>{{ $t('label.data') }}</label>
                <b-form-datepicker v-model="$store.state.data" locale="pt-BR" size="sm"></b-form-datepicker>
            </b-col>

            <b-col cols="auto" v-if="$store.state.tipoPeriodo == 3 || $store.state.tipoPeriodo == 4">
                <label>{{ $t('label.ano') }}</label>
                <b-form-select class="form-control" v-model="$store.state.ano"
                    :options="$store.state.anos" size="sm"></b-form-select>
            </b-col>

            <b-col cols="auto" v-if="$store.state.tipoPeriodo == 3">
                <label>{{ $t('label.semana') }}</label>
                <select class="form-control small" v-model="$store.state.semana" size="sm">
                    <option v-for="n in semanas" :key="n" :value="n" >
                        {{ n }}
                    </option>
                </select>
            </b-col>

            <b-col cols="auto" v-if="$store.state.tipoPeriodo == 4">
                <label>{{ $t('label.mes') }}</label>
                <select class="form-control" v-model="$store.state.mes" size="sm">
                    <option v-for="n in 12" :key="n" :value="n">
                        {{ n }}
                    </option>
                </select>
            </b-col>

            <b-col cols="auto" class="pr-3"  v-if="$store.state.tipoPeriodo == 1" >
                <label>{{ $t('label.turno') }}</label>
                <b-form-select :options="turnos" v-model="$store.state.turno" size="sm"></b-form-select>
            </b-col>

            <b-col cols="auto" class="pr-3" v-if="mostrarVisualizacao">
                <label>{{ $t('label.visualizacao') }}</label>
                <b-form-select :options="visualizacoes" v-model="$store.state.visualizacao" size="sm"></b-form-select>
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    props: {
        mostrarTurno: false,
        mostrarVisualizacao: false
    },
    data() {
        return {
            turnos: [],
            tiposPeriodo: [
                { value: 1, text: 'Turno' },
                { value: 2, text: 'Dia' },
                { value: 3, text: 'Semana' },
                { value: 4, text: 'Mês' },
            ],
            visualizacoes: [
                { value: 1, text: "Porcentagem" },
                { value: 2, text: "Quantidade" },
            ]
        }
    },
    computed: {

        turno: {
            get() {
                return this.$store.state.turno;
            },
            set(value) {
                this.$store.commit("setTurno", value);
            },
        },
        data: {
            get() {
                return this.$store.state.data;
            },
            set(value) {
                this.$store.commit("setData", value);
            },
        },
        semana: {
            get() {
                return this.$store.state.semana;
            },
            set(value) {
                this.$store.commit("setSemana", value);
            },
        },
        semanas: {
            get() {
                return this.$store.state.semanas;
            },
            set(value) {
                this.$store.commit("setSemanas", value);
            },
        },
        mes: {
            get() {
                return this.$store.state.mes;
            },
            set(value) {
                this.$store.commit("setMes", value);
            },
        },
    },
    methods: {
        carregarTurnos() {
            this.$http
                .get("api/turno")
                .then((response) => {
                    this.turnos = [];                   
                    response.data.forEach((element) => {
                        this.turnos.push({
                            value: element.id,
                            text: element.descricao,
                        });
                    });
                    this.$store.state.turnos = this.turnos;
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
    },
    mounted() {
        this.carregarTurnos()

    },
}
</script>