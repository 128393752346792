<template>
  <div>
    <div class="pagina border" ref="pagina_workflow0">
      <b-row align-v="center" class="mt-3 relatorio-cabecalho mb-2">
        <b-col cols="4">
          <b-row>
            <b-col>
              <b-row>
                <span class="data_top_foto"
                  >Ano {{ this.$store.state.ano }} Semana
                  {{ this.$store.state.semana }}</span
                >
              </b-row>
              <b-card>               
                <b-img
                  center
                  height="160"
                  :src="componente.imagem | newImagem"
                />
              </b-card>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="8">
          <b-row>
            <b-col>
              <b-card v-if="resultado" :loading="resultado">
                <table width="100%" class="comBordaComplexa">
                  <tr>
                    <td colspan="8">
                      {{ componente.descricao }} -
                      {{ componente.desenho }}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style="background-color: #e8e8e8">Pts</td>
                    <td style="background-color: #e8e8e8">%</td>
                    <td style="background-color: #e8e8e8">Pts</td>
                    <td style="background-color: #e8e8e8">%</td>
                    <td style="background-color: #e8e8e8">Pts</td>
                    <td style="background-color: #e8e8e8">%</td>
                    <td style="background-color: #e8e8e8">Total Pts</td>
                  </tr>
                  <tr>
                    <td style="background-color: #e8e8e8">CG</td>
                    <td style="background-color: #00FF05">
                      {{ resultado.tabela.fechamento.geral_cg_verde | digitos(3) }}
                    </td>
                    <td style="background-color: #00FF05">
                      {{ resultado.tabela.fechamento.geral_pc_cg_verde | duasCasas }}%
                    </td>
                    <td style="background-color: yellow">
                      {{ resultado.tabela.fechamento.geral_cg_amarelo | digitos(3) }}
                    </td>
                    <td style="background-color: yellow">
                      {{
                        resultado.tabela.fechamento.geral_pc_cg_amarelo | duasCasas
                      }}%
                    </td>
                    <td style="background-color: red; color: white">
                      {{ resultado.tabela.fechamento.geral_cg_vermelho | digitos(3) }}
                    </td>
                    <td style="background-color: red; color: white">
                      {{
                        resultado.tabela.fechamento.geral_pc_cg_vermelho | duasCasas
                      }}%
                    </td>
                    <td style="background-color: white">
                      {{ resultado.tabela.fechamento.geral_total_cg | digitos(3) }}
                    </td>
                  </tr>
                  <tr>
                    <td style="background-color: #e8e8e8"> {{indicadorCp}}</td>
                    <td style="background-color: #00FF05">
                      {{ resultado.tabela.fechamento.geral_cp_verde | digitos(3) }}
                    </td>
                    <td style="background-color: #00FF05">
                      {{ resultado.tabela.fechamento.geral_pc_cp_verde | duasCasas }}%
                    </td>
                    <td style="background-color: yellow">
                      {{ resultado.tabela.fechamento.geral_cp_amarelo | digitos(3) }}
                    </td>
                    <td style="background-color: yellow">
                      {{
                        resultado.tabela.fechamento.geral_pc_cp_amarelo | duasCasas
                      }}%
                    </td>
                    <td style="background-color: red; color: white">
                      {{ resultado.tabela.fechamento.geral_cp_vermelho | digitos(3) }}
                    </td>
                    <td style="background-color: red; color: white">
                      {{
                        resultado.tabela.fechamento.geral_pc_cp_vermelho | duasCasas
                      }}%
                    </td>
                    <td style="background-color: white">
                      {{ resultado.tabela.fechamento.geral_total_cp | digitos(3) }}
                    </td>
                  </tr>
                  <tr>
                    <td style="background-color: #e8e8e8"> {{indicadorCp}}K</td>
                    <td style="background-color: #00FF05">
                      {{ resultado.tabela.fechamento.geral_cpk_verde | digitos(3) }}
                    </td>
                    <td style="background-color: #00FF05">
                      {{ resultado.tabela.fechamento.geral_pc_cpk_verde | duasCasas }}%
                    </td>
                    <td style="background-color: yellow">
                      {{ resultado.tabela.fechamento.geral_cpk_amarelo | digitos(3) }}
                    </td>
                    <td style="background-color: yellow">
                      {{
                        resultado.tabela.fechamento.geral_pc_cpk_amarelo | duasCasas
                      }}%
                    </td>
                    <td style="background-color: red; color: white">
                      {{ resultado.tabela.fechamento.geral_cpk_vermelho | digitos(3) }}
                    </td>
                    <td style="background-color: red; color: white">
                      {{
                        resultado.tabela.fechamento.geral_pc_cpk_vermelho | duasCasas
                      }}%
                    </td>
                    <td style="background-color: white">
                      {{ resultado.tabela.fechamento.geral_total_cpk | digitos(3) }}
                    </td>
                  </tr>
                </table>
              </b-card>
            </b-col>
          </b-row>

          <b-row class="text-center datas">
            <b-col>
              <b-row class="text-center">
                <b-col>Data Loop Atual</b-col>
              </b-row>
              <b-row
                ><b-col>
                  <b-form-datepicker
                    v-model="form.data_loop_atual"
                    placeholder="-"
                    class="text-center"
                    size="sm"
                    locale="pt-BR"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  ></b-form-datepicker> </b-col
              ></b-row>
            </b-col>
            <b-col>
              <b-row class="text-center"
                ><b-col> Data Próximo Loop de Melhoria </b-col></b-row
              >
              <b-row
                ><b-col>
                  <b-form-datepicker
                    v-model="form.data_melhoria"
                    placeholder="-"
                    class="text-center"
                    size="sm"
                    locale="pt-BR"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  ></b-form-datepicker> </b-col
              ></b-row>
            </b-col>
            <b-col>
              <b-row><b-col> Data Amostra Benestare </b-col></b-row>
              <b-row
                ><b-col>
                  <b-form-datepicker
                    v-model="form.data_amostra"
                    placeholder="-"
                    class="text-center"
                    size="sm"
                    locale="pt-BR"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  ></b-form-datepicker> </b-col
              ></b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-2" align-h="start" style="height:55%;">
        <b-col
          lg="4"
          class="mb-2"
          v-for="workflow in primeira_pagina"
          :key="workflow.relatorio.id"
        >
          <BoxIndicadoresWorkflow :resultado="workflow" />
        </b-col>
      </b-row>
      <b-row align-v="end" class="align-self-end paginacao">
        <b-col class="text-center ">1/{{1 + paginas.length}}</b-col>
      </b-row>
    </div>

    <div class="pagina border mt-3 " v-for="(pagina, index) in paginas" :key="pagina" :ref="'pagina_workflow'+(index+1)">
      <div align-v="stretch" style="height:98%;">

        <b-row class="pt-3" align-h="start" align-v="start" >
          <b-col
            lg="4"
            class="mb-3"
            v-for="workflow in pagina"
            :key="workflow.relatorio.id"
          >
            <BoxIndicadoresWorkflow :resultado="workflow" />
          </b-col>
        </b-row>
        </div>
        <b-row align-v="end" class="align-self-end paginacao">
          <b-col class="text-center ">{{index + 2}}/{{paginas.length + 1}}</b-col>
        </b-row>
      
      
    </div>
  </div>
</template>

<script>
import BoxIndicadoresWorkflow from "@/components/BoxIndicadoresWorkflow.vue";
export default {
  name: "Workflow",
  props: {
    componente:null,
    resultado: {
      workflow: [],
    },
  },
  components: {
    BoxIndicadoresWorkflow,
  },
  data() {
    return {
      form: {
        data_loop_atual: null,
        data_melhoria: null,
        data_amostra: null,
      },
      primeira_pagina: [],
      paginas: [],
      carregando: false,
      menssagem: null,
    };
  },
  computed: {
    indicadorCp() {
      return this.componente.indicador == 'cp' ? 'CP' : 'PP';
    },
    user() {
      return this.$store.state.auth.user.user;
    },
  },
  methods: {
    separarPaginas() {
      
      var contarpaginas = 0;      
      var paginasArray = [];
      var cont = 0;
      
      this.resultado.workflow.forEach((element, index) => {
        if (index < 6) {
          this.primeira_pagina.push(element);
        }

        if (index > 5) {
          paginasArray.push(element);
          contarpaginas++;
          cont++;
          if (contarpaginas == 9) {
            contarpaginas = 0;
            this.paginas.push(paginasArray);
            paginasArray = [];
          } else {
            if (cont == this.resultado.workflow.length - 6) {
              this.paginas.push(paginasArray);
            }
          }
        }
      });
    },
    atualizarData() {
      let config = {
        headers: {
          Accept: "application/json",
        },
      };

      this.$http
        .post(
          "api/relatorio/workflow/atualizardatas/" + this.resultado.id,
          this.form,
          config
        )
        .then((response) => {
          this.$emit("listaMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    carregarDatas() {
      this.form.data_loop_atual = this.resultado.data_loop_atual;
      this.form.data_melhoria = this.resultado.data_melhoria;
      this.form.data_amostra = this.resultado.data_amostra;
    },
  },
  mounted() {
    if(this.resultado.workflow){
      this.separarPaginas();
      this.carregarDatas();
    }    
  },
  watch: {},
};
</script>

<style scoped>
table.comBordaComplexa {
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  border-collapse: collapse; /* CSS2 */
  background: #fffff0;  
}

table.comBordaComplexa td {
  border: 2px solid white;
}

table.comBordaComplexa th {
  border: 1px solid blue;
  background: #f0fff0;
}
.datas {
  font-weight: bold;
  font-size: 12px;
  margin-top: 5px;
}
.data_top_foto {
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  width: 100%;
  background-color: #e8e8e8;
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 5px;
  margin-bottom: 5px;
  border: 1px solid #b5b5b5;
}

.lab__card {
  width: 100%;
  height: 160px;
  border-radius: 9px;
  padding: 20px;
  object-fit: contain;
  transition: transform 0.45s;
  color: white;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
}

.relatorio-cabecalho {
  width: 1050px;
}

.pagina {
  width: 1035px;
  height: 607px;
  padding: 8px;
}

.paginacao{
  font-size: 10px;
 
}
</style>