<template>
  <b-modal
    id="modal-criterio"
    size="md"
    :title=" $t('title.definicao-do-criterio-analisys') "
    centered
  >
    <template #modal-header>
      <!-- Emulate built in modal header close button action -->
      <h5>{{ $t('title.definicao-do-criterio-analisys') }}</h5>
      <p class="h4 btn"><b-icon icon="x" @click="close()"></b-icon></p>
    </template>
    <b-form v-if="show">
      <b-form-group :label=" $t('label.indicador') ">
        <b-form-select
          :options="indicadores"
          v-model="relatorio.boleado_indicador"
        ></b-form-select>
      </b-form-group>
      <b-form-group :label=" $t('labe.criterio') ">
        <b-form-select
          :options="criterios"
          v-model="relatorio.boleado_criterio"
        ></b-form-select>
      </b-form-group>
    </b-form>

    <b-alert show variant="danger" v-if="menssagem">
      <ul v-if="menssagem">
        <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
      </ul>
    </b-alert>

    <template #modal-footer>
      <div class="w-100">
        
            <b-button
              variant="primary"
              class="float-right"
              v-if="carregando"
              disabled
            >
             <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
              {{ $t('botoes.carregando') }} </b-button>

            <b-button
              v-else
              type="submit"
              variant="primary"
              class="float-right"
              @click="salvar()"
            >
              {{ $t('botoes.salvar') }} </b-button>            
          
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {   
    indicador:null, 
    relatorio: {
      type: Object,
      default: function () {
        return {
          id: null,     
          boleado_criterio:null,
          boleado_indicador:null              
        };
      },
    },
  },
  data() {
    return {
      indicadores: [
        { value: null, text: this.$t('opcoes.indicadores.selecione-uma-opcao') },
        { value: "conformidade", text: this.$t('opcoes.indicadores.conformidade') },
        { value: "cp", text: this.indicador =="cp" ? "CP": "PP" },
        { value: "cpk", text: this.indicador =="cp" ? "CPK": "PPK" },
      ],
      criterios: [
        { value: null, text: this.$t('opcoes.indicadores.selecione-uma-opcao') },
        { value: "vermelho", text: this.$t('opcoes.criterios.vermelho') },
        { value: "amarelo", text: this.$t('opcoes.criterios.amarelo') },
        { value: "vermelho_amarelo", text: this.$t('opcoes.criterios.vermelho-e-amarelo') },
      ],
      show: true,
      menssagem: null,
      carregando: null,
      url: "api/relatorio",
    };
  },
  methods: {
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
         title: this.$t('toast.sucesso'),
        variant: variant,
        solid: true,
      });
    },
    close() {
      //this.limpar();
      this.$bvModal.hide("modal-criterio");
    },
    salvar() {
      this.menssagem = null;
      this.carregando = true;
      let formData = new FormData();
      
      formData.append("boleado_indicador", this.relatorio.boleado_indicador);
      formData.append("boleado_criterio", this.relatorio.boleado_criterio);
          
      formData.append("_method", "patch");
      this.url = "api/relatorio/" + this.relatorio.id;     

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      };

      this.$http
        .post(this.url, formData, config)
        .then((response) => {
          this.makeToast("success");
          this.$bvModal.hide("modal-criterio");
          this.carregando = false;
          this.$emit("listaMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },    
  },  
};
</script>

<style >
</style>