<template>
  <b-modal id="modal-work-flow" size="xl" title="AutoMarb - WorkFLow">
    <template #modal-header>
      <!-- Emulate built in modal header close button action -->
      <h5>AutoMarb - WorkFLow</h5>
      <p class="h4 btn"><b-icon icon="x" @click="close()"></b-icon></p>
    </template>

    <b-form v-if="show">
      <b-row>
        <b-col sm="6">
          <b-card
            bg-variant="light"
            header="Item"
            class="container-caracteristicas"
          >
            <b-form-group id="input-group-1">
              <b-form-select
                v-model="componente_selecionado"
                :options="componentes"
                :select-size="10"
                size="sm"
              >
              </b-form-select>
            </b-form-group>

            <b-row align-v="center">
              <b-col>
                <b-img
                  v-if="imagem"
                  center
                  height="115"
                  :src="imagem | urlImagem"
                />
                <img v-else src="../../assets/sem-foto.png" height="120" />
              </b-col>
              <b-col
                ><b-form-group id="input-group-2" :label=" $t('label.relatorio') ">
                  <b-form-select
                    v-model="relatorio_selecionado"
                    :options="relatorios"
                    :select-size="5"
                    size="sm"
                  >
                  </b-form-select> </b-form-group
              ></b-col>
            </b-row>

            <b-button size="sm" block v-on:click="addItem()">
              <b-icon icon="plus" aria-hidden="true"></b-icon>{{ $t('botoes.adicionar') }}</b-button
            >
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card
            bg-variant="light"
            header="Itens Selecionados"
            class="container-caracteristicas"
          >
            <b-form-group id="input-group-1">
              <b-form-select
                v-model="item_selecionado"
                :options="itens"
                :select-size="10"
                size="sm"
              >
              </b-form-select>
            </b-form-group>

            <b-row align-v="center">
              <b-col>
                <b-img
                  v-if="imagem_relatorio"
                  center
                  height="115"
                  :src="imagem_relatorio | urlImagem"
                />
                <img v-else src="../../assets/sem-foto.png" height="120" />
              </b-col>
              <b-col
                ><b-button size="sm" block v-on:click="removeItem()">
                  <b-icon icon="trash" aria-hidden="true"></b-icon> {{ $t('botoes.remover-item-selecionado') }}</b-button
                >
                <b-button size="sm" block v-on:click="removeItens()">
                  <b-icon icon="trash" aria-hidden="true"></b-icon> {{ $t('botoes.remover-todos-os-itens') }}</b-button
                ></b-col
              >
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>

    <b-alert show variant="danger" v-if="menssagem" class="mt-3">
      <ul v-if="menssagem">
        <li v-for="(e, key) in menssagem" :key="key">{{ e[0] }}</li>
      </ul>
    </b-alert>

    <template #modal-footer>
      <div class="w-100">
        <b-row align-v="center">
          <b-col cols="12" md="8">
            <b-row align-v="center" align-h="center">
              <b-col
                ><p class="h1 ml-3">
                  <b-icon
                    icon="exclamation-octagon"
                    variant="primary"
                  ></b-icon></p
              ></b-col>
              <b-col cols="12" md="10">
                <p class="pt-2">
                  <span class="text-primary"> {{ $t('modal.importante') }}</span><br />
                  {{ $t('modal.preenchaTodosOsDadosCorretamente') }} </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" md="4">
            <b-button
              variant="primary"
              class="float-right"
              v-if="carregando"
              disabled
            >
             <b-icon icon="arrow-clockwise" animation="spin-pulse"></b-icon>
              {{ $t('botoes.carregando') }} </b-button>

            <b-button
              v-else
              type="submit"
              variant="primary"
              class="float-right"
              @click="salvarWorkflow()"
            >
              {{ $t('botoes.salvar') }} </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    relatorio_id: null,
    workflow: [],
  },
  data() {
    return {
      show: true,
      menssagem: null,
      carregando: null,
      componentes: [],
      componente_selecionado: null,
      relatorios: [],
      relatorio_selecionado: null,
      imagem: null,
      itens: [],
      item_selecionado: null,
      imagem_relatorio: null,
    };
  },
  methods: {
    salvarWorkflow() {
      this.menssagem = null;
      this.carregando = true;

      let config = {
        headers: {
          Accept: "application/json",
        },
      };

      this.$http
        .post("api/relatorio/workflow/" + this.relatorio_id, this.itens, config)
        .then((response) => {
          this.makeToast("success");
          this.$bvModal.hide("modal-work-flow");
          this.carregando = false;
          this.$emit("listaMudou");
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.errors;
          this.carregando = false;
        });
    },
    makeToast(variant = null) {
      this.$bvToast.toast(this.$t('toast.operacao-realizada-com-sucesso'), {
         title: this.$t('toast.sucesso'),
        variant: variant,
        solid: true,
      });
    },
    close() {
      this.$bvModal.hide("modal-work-flow");
    },
    addItem() {
      if (this.componente_selecionado != null) {
        this.carregarRelatorio();
      }
    },
    removeItem() {
      if (this.item_selecionado != null) {
        var index = this.itens
          .map(function (item) {
            return item.value;
          })
          .indexOf(this.item_selecionado);
        if (index > -1) {
          this.itens.splice(index, 1);
        }
      }
      this.item_selecionado = null;
      this.imagem_relatorio = null;
    },
    removeItens() {
      this.itens = [];
      this.item_selecionado = null;
      this.imagem_relatorio = null;
    },
    carregarComponentes() {
      this.carregando = true;
      this.menssagem = null;

      this.$http
        .get("api/componente")
        .then((response) => {
          response.data.forEach((item) => {
            this.componentes.push({
              value: item.id,
              text:
                item.familia.descricao +
                " - " +
                item.descricao +
                " - " +
                item.desenho,
            });
          });

          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.erro;
          this.carregando = false;
        });
    },

    carregarComponente() {
      this.carregando = true;
      this.menssagem = null;
      this.relatorios = [];

      this.$http
        .get("api/componente/" + this.componente_selecionado)
        .then((response) => {
          response.data.relatorios.forEach((item) => {
            this.relatorios.push({
              value: item.id,
              text: item.descricao,
            });
          });

          this.imagem = response.data.imagem;

          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.erro;
          this.carregando = false;
        });
    },

    carregarRelatorio() {
      this.carregando = true;
      this.menssagem = null;

      this.$http
        .get("api/relatorio/simples/" + this.relatorio_selecionado)
        .then((response) => {
          var c = {
            value: this.relatorio_selecionado,
            text:
              response.data.componente.familia.descricao +
              " - " +
              response.data.componente.descricao +
              " - " +
              response.data.componente.desenho +
              " - " +
              response.data.descricao,
          };
          this.itens.push(c);

          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.erro;
          this.carregando = false;
        });
    },

    carregarDadosRelatorio() {
      this.carregando = true;
      this.menssagem = null;

      this.$http
        .get("api/relatorio/simples/" + this.item_selecionado)
        .then((response) => {
          this.imagem_relatorio = response.data.componente.imagem;

          this.carregando = false;
        })
        .catch((errors) => {
          this.menssagem = errors.response.data.erro;
          this.carregando = false;
        });
    },

    carregarWorkflow() {
      this.itens = [];
      this.workflow.forEach((item) => {
        var c = {
          value: item.relatorio.id,
          text:
            item.relatorio.componente.familia.descricao +
            " - " +
            item.relatorio.componente.descricao +
            " - " +
            item.relatorio.componente.desenho +
            " - " +
            item.relatorio.descricao,
        };
        this.itens.push(c);
      });
    },
  },
  computed: {},
  mounted() {
    this.carregarComponentes();
    this.carregarWorkflow();
  },
  watch: {
    componente_selecionado: function () {
      this.carregarComponente();
    },
    item_selecionado: function (item) {
      if (item) this.carregarDadosRelatorio();
    },
    workflow: function () {
      this.carregarWorkflow();
    },
  },
};
</script>

<style scoped>
.table-wrapper {
  max-height: 200px !important;
  overflow: auto;
}
.container-caracteristicas {
  height: 100%;
}
.text-radio {
  font-size: 14px;
}
</style>