<template>
  <div>
    <div id="chart-risk">
      <apexchart
        type="bar"
        :height="height ? height :'100'"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height:null,
    risks: [],
    cores: [],
    categorias: [],
  },
  data() {
    return {
      series: [
        {
          name: "Risk",
          data: [2, 3, 1],
        },
      ],
      chartOptions: {
        title: {
          text: "Risk Assessment - Deviation",
          align: "center",
          margin: 5,
          floating: true,
          style: {
            fontSize: "12px",
            fontWeight: "bold",
          },
        },
        grid: {
          padding: {
            left: 0,
            right: 15,
          },
        }, 
        chart: {
          toolbar: {
            show: false,
          },
          height: 80,
          type: "bar",
          width: "100%",
          parentHeightOffset: 0,
        },
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "11px",
            colors: ["#1C1C1C"],
          },
        },
        legend: {
          show: false,
        },
        noData: {
          align: "center",
          text: "No data available at the moment",
          verticalAlign: "middle",
        },
        colors: ["#D6DCE4", "#9BC2E6", "#E3DE00"],
        xaxis: {
          labels: {
            show: false,
          },
          categories: ["To 0,5", "To 1,0", "To 1,5"],
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
      },
    };
  },
  mounted() {
    this.updateGrafico();
  },
  methods: {
    updateGrafico() {
      this.series[0].data = this.risks;
      this.chartOptions = {
        colors: this.cores,
        xaxis: {
          type: "category",
          categories: this.categorias,
        },
      };
    },
  },
  watch: {
    semanas: function () {
      this.updateGrafico();
    },
    verde: function () {
      this.updateGrafico();
    },
    amarelo: function () {
      this.updateGrafico();
    },
  },
};
</script>
